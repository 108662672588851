import React, { useEffect, useRef } from 'react';

function LineLink({ midImg, title, mainTitle, link, endImg }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = containerRef.current;
      const elementRect = element.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (elementRect.top < viewportHeight && elementRect.bottom > 0) {
        // Element is visible on the screen
        element.style.transform = 'translateX(0)';
        element.style.opacity = 1;
      } else {
        // Element is not visible on the screen
        element.style.transform = 'translateX(-100px)';
        element.style.opacity = 0;
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a href={link} style={{ textDecoration: 'none' }}>
      <div
        ref={containerRef}
        style={{
          transform: 'translateX(-100px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          opacity: 0,
          transition: 'transform 1s ease, opacity 1s ease',
          backgroundColor: '#EEEDEB',
          backgroundPosition: '97% 0%',
          height: 200,
          overflow: 'hidden',
          marginBottom: 2,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div style={{ marginLeft: '10%', color: 'white' }}>
          <p style={{ fontSize: 17, textShadow: '-2px 1px 3px rgba(0, 0, 0, 1)' }}>{mainTitle}</p>
          <p style={{ fontSize: 37, marginTop: -10, textShadow: '-2px 1px 3px rgba(0, 0, 0, 1)' }}>{title}</p>
        </div>
        <div style={{ display: 'flex', position: 'absolute',justifyContent: 'center', width: '100%', zIndex: -10}}>
          <img src={midImg} style={{ height: '100%', objectFit: 'cover', zIndex: -9 }}/>
        </div>
        <img src={endImg} style={{ height: endImg=='https://amperino.com/imagesv2/Development/DevelopmentPageImages/KilMain2.webp' ? '90%' : '100%', objectFit: 'cover', marginLeft: 'auto' , marginRight: 10}}/>
      </div>
    </a>
  );
}

export default LineLink;
