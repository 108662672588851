import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Company from '../pages/Company';
import TheCar from '../pages/TheCar';
import Development from '../pages/Development';
import Error from '../pages/Error';
import Home from '../pages/Home';
import Technology from '../pages/Technology';
import Battery from '../pages/Technology/Battery';
import Composite from '../pages/Technology/Composite';
import DriveTechnology from '../pages/Technology/DriveTechnology';
import DigitalVehiclePlatform from '../pages/Technology/DigitalVehiclePlatform';
import SkateboardChassi from '../pages/Technology/SkateboardChassi';
import Cad from '../pages/Development/Cad';
import Cae from '../pages/Development/Cae';
import Clay from '../pages/Development/Clay';
import Scan from '../pages/Development/Scan';
import Sketch from '../pages/Development/Sketch';
import Vr from '../pages/Development/Vr';
import Prototip from '../pages/Development/Prototip';

const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/car" element={<TheCar />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/technology/battery" element={<Battery />} />
        <Route path="/technology/composite" element={<Composite />} />
        <Route path="/technology/drive-technology" element={<DriveTechnology />} />
        <Route path="/technology/digital-vehicle-platform" element={<DigitalVehiclePlatform />} />
        <Route path="/technology/skateboard-chassis" element={<SkateboardChassi />} />
        <Route path="/development/cad" element={<Cad />} />
        <Route path="/development/cae" element={<Cae />} />
        <Route path="/development/clay" element={<Clay />} />
        <Route path="/development/prototype" element={<Prototip />} />
        <Route path="/development/scan" element={<Scan />} />
        <Route path="/development/sketch" element={<Sketch />} />
        <Route path="/development/vr" element={<Vr />} />
        <Route path="/development" element={<Development />} />
        <Route path="/company" element={<Company />} />
        <Route path="/" exact element={<Home />}  />
        <Route path="*" element={<Error />} />
    </Routes>
  );
};

export default AppRoutes;
