import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import ReactPlayer from 'react-player/youtube';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function Clay() {
    
    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});

    const imageInformationReverse = {
        width: '80%', 
        marginLeft:'10%', 
        textAlign: 'center',
        marginTop: 20,
        display: width > 1000 ? 'flex' : 'table', 
        paddingBottom: 50,
        flexDirection: width > 1000 ? 'row': null,
        alignItems: 'center'
    }
    const inlineDiv = {
        width: '70%',
        backgroundColor: 'white',  
        padding: 50,         
        textAlign: 'justify',
        marginTop: width < 1000 ? -5 : null
    }

    const imgInformation = {
        backgroundColor: 'white', 
        textAlign: 'center', 
        width: '80%',
        marginLeft: '10%', 
        display: 'flex', 
        position: 'relative', 
        marginTop: width > 1000 ? -200: -50
    }

  return (
    <div>
        <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="clay.header" />}
                    headerInfo={<FormattedMessage id="clay.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/KilMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/KilMain2.webp'
                />
            <div style={{width: width > 499 ? width-17 : width, paddingTop: 10}}>
                <img src='https://amperino.com/imagesv2/Development/Clay/MainImgv2.webp' style={{width: width > 499 ? width-17 : width, height: width/2}} />
                <div style={imgInformation}>
                    <div style={{minHeight: 200, width: width < 800 ? '98%' : '50%', marginLeft: width < 800 ? '1%' : '25%', marginTop: 50, paddingBottom: 50}}>
                        <h2><FormattedMessage id="clay.subHeader" /></h2>
                        <p style={{textAlign: 'justify'}}>
                            <FormattedMessage id="clay.subHeader.info1" /> 
                        </p>    
                    </div>
                </div>
            </div>
            <div style={imageInformationReverse}>
                <div  style={{ marginLeft: width <500 ? -39 : -54 }}>
                    <ReactPlayer 
                    playing={true} loop={true} controls={true} muted={true} playbackRate={1}
                    width={width <500 ? width : width <600 ? width-17 : width <2000 ? 595 : width/3} 
                    height={width <500 ? width/1.78 : width <600 ? width/1.83 : width <2000 ? 335 : width/5-47} 
                        url='https://www.youtube.com/watch?v=Vi8dtssF39U'
                    />
                </div>
                <div style={inlineDiv}>
                    <div style={width > 1000 ? null : {width: '128%',  marginLeft: '-20%'}}>
                        <FormattedMessage id="clay.subHeader.info2" />
                    </div> 
                </div>
            </div>         
        </div>
        <Footer />
    </div>
  )
}

export default React.memo(Clay);

