import React from 'react';
import { Collapse, Space } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Panel } = Collapse;

const CareerCollapse = () => (
  <Space direction="vertical" style={{width: '100%'}}>
    <h2 style={{paddingBottom: 50, marginTop: -50}}><FormattedMessage id="career.header.info" /></h2>
    <Collapse collapsible="icon" defaultActiveKey={['1']}>
      <Panel header={<FormattedMessage id="career.header1" />} style={{backgroundColor: 'white', borderRadius: 6, fontWeight: 'bold'}} key="0">
        <p style={{fontWeight: 'normal', textAlign: 'justify'}}><FormattedMessage id="career.info1" /></p>
      </Panel>
    </Collapse>
    <Collapse collapsible="icon" defaultActiveKey={['1']}>
      <Panel header={<FormattedMessage id="career.header2" />} style={{backgroundColor: 'white', borderRadius: 6, fontWeight: 'bold'}} key="0">
        <p style={{fontWeight: 'normal', textAlign: 'justify'}}><FormattedMessage id="career.info2.1" />
        <br /><br />
        <FormattedMessage id="career.info2.2" /></p>
      </Panel>
    </Collapse>
    <Collapse collapsible="icon" defaultActiveKey={['1']}>
      <Panel header={<FormattedMessage id="career.header3" />} style={{backgroundColor: 'white', borderRadius: 6, fontWeight: 'bold'}} key="0">
        <p style={{fontWeight: 'normal', textAlign: 'justify'}}><FormattedMessage id="career.info3" /></p>
      </Panel>
    </Collapse>
    <Collapse collapsible="icon" defaultActiveKey={['1']}>
      <Panel header={<FormattedMessage id="career.header4" />} style={{backgroundColor: 'white', borderRadius: 6, fontWeight: 'bold'}} key="0">
        <p style={{fontWeight: 'normal'}}>
        <FormattedMessage id="career.info4" /></p>
      </Panel>
    </Collapse>
    <Collapse collapsible="icon" defaultActiveKey={['1']}>
      <Panel header={<FormattedMessage id="career.header5" />} style={{backgroundColor: 'white', borderRadius: 6, fontWeight: 'bold'}} key="0">
        <p style={{fontWeight: 'normal', textAlign: 'justify'}}><FormattedMessage id="career.info5" /></p>
      </Panel>
    </Collapse>
  </Space>
);

export default CareerCollapse;