import React, { useEffect, useRef, useState } from "react";
import "../css/Video.css";

function AccVideo({ src, index }) {
  const [playing, setPlaying] = useState(false);
  const indexVideo = localStorage.getItem('indexVideo');

  const videoRef = useRef(null);
  const handleVideoPress = () => {
    localStorage.setItem('indexVideo', index)
    if (playing && indexVideo == index) {
      setPlaying(false);
      videoRef.current.pause();
    } else {
      videoRef.current.play();
      setPlaying((play) => !play);
    }
  };
  useEffect(() => {
    if (playing && indexVideo == index) {
      videoRef.current.play();
    }
    else {
      videoRef.current.pause();
    }
  }, [indexVideo]);

  return (
    <div className="video__container">
      <video
        className="video__player"
        onClick={handleVideoPress}
        loop
        ref={videoRef}
        src={src}
        //controls
      />
    </div>
  );
}

export default AccVideo;

