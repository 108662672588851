import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import BurgerMenu from './BurgerMenu';
import useWindowDimensions from './window';
import { useLanguage } from '../context/LanguageContext'; // useLanguage hook'unu import edin

function Header() {
  const location = useLocation();
  const [localPath, setLocalPath] = useState("/");
  const { lang, setLang } = useLanguage(); // Custom hook kullanarak dil bilgisini ve setter'ı alın
  const langRef = useRef(document.documentElement);
  const { width } = useWindowDimensions();

  useEffect(() => {
    localStorage.setItem("lang", lang);
    langRef.current.lang = lang;
  }, [lang]);

  useEffect(() => {
    setLocalPath(location.pathname);
  }, [location.pathname]);

  const handleChange = (value) => {
    setLang(value); // Dil değişikliği için useLanguage'den gelen setLang'i kullanın
    window.location.reload(false); // Sayfayı yenileyin (Bu işlemi daha sofistike bir yöntemle değiştirmek mümkün)
  };

  return (
    <div style={{display: 'flex', position: localPath=="/" ? 'sticky' : 'relative', top: 0, zIndex: localPath=="/" ? 30 : null}}>
      <Headers style={localPath=="/" ?  {height: 120} : {height: 485}}> 
      <div style={{display: 'flex', position: 'absolute', marginTop: 10, zIndex: 30}}>
        <button onClick={() =>handleChange("tr-TR")} style={{border: 'none', backgroundColor: 'black', color: lang == "tr-TR" ? 'white' : '#767676', cursor: 'pointer'}} >TR</button>
        <div style={{color: '#767676'}}>|</div>
        <button onClick={() =>handleChange("en-US")} style={{border: 'none', backgroundColor: 'black', color: lang == "en-US" ? 'white' : '#767676', cursor: 'pointer'}} >EN</button>
      </div>
      <HeaderContent >
        <a href="/">
          <img src={'https://amperino.com/imagesv2/logooo.svg'} style={{width: 250, height: localPath=="/" ? 65.482 : 97.482}} loading="lazy"/>
        </a>                            
      </HeaderContent>
      </Headers> 
      <div style={{display: width > 600 ? 'none' : null, zIndex: 30}}>
        <BurgerMenu />    
      </div>  
    </div>
  )
}

export default Header;


const Headers = styled.header`
  width: 100%;
  background-color: #000;
  position: absolute;
`;
const HeaderContent = styled.div`
  padding: 1.5em 1em;
  margin: auto;
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
`;
