import { useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id, width, height) =>
  `https://amperino.com/GoGreen/${id}.webp`;

const unsplashPhotos = [
  { id: "1", width: 2832, height: 4240 },
  { id: "2", width: 2832, height: 1593 },
  { id: "3", width: 2832, height: 4240 },
  { id: "4", width: 2832, height: 1595 },
  { id: "5", width: 2832, height: 4240 },
  { id: "6", width: 2832, height: 4240 },
  { id: "7", width: 2832, height: 1594 },
  { id: "8", width: 2832, height: 4240 },
  { id: "9", width: 2832, height: 1598 },
  { id: "10", width: 2832, height: 4240 },
  { id: "11", width: 2832, height: 1585 },
  { id: "12", width: 2832, height: 4240 },
  { id: "13", width: 2832, height: 1591 },
  { id: "14", width: 2832, height: 4240 },
  { id: "15", width: 2832, height: 1586 },
  { id: "16", width: 2832, height: 4240 },
  { id: "17", width: 2832, height: 1593 },
  { id: "18", width: 2832, height: 2484 },
  { id: "19", width: 2832, height: 4240 },
  { id: "20", width: 2832, height: 1594 },
  { id: "21", width: 2832, height: 4240 },
  { id: "22", width: 2832, height: 1594 },
  { id: "23", width: 2832, height: 4240 },
  { id: "24", width: 2832, height: 1594 },
  { id: "25", width: 2832, height: 1884 },
];

const photos = unsplashPhotos.map((photo) => {
  const width = breakpoints[0];
  const height = (photo.height / photo.width) * width;

  return {
    src: unsplashLink(photo.id, width, height),
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const height = Math.round((photo.height / photo.width) * breakpoint);
      return {
        src: unsplashLink(photo.id, breakpoint, height),
        width: breakpoint,
        height,
      };
    }),
  };
});


export default function CompanyGallery() {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </>
  );
}
