import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import CompositeCarousel from '../../components/CompositeCarousel';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';

function Composite() {

    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="composite.header" />}
                    headerInfo={<FormattedMessage id="composite.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/KompozitMain.webp'
                    subImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/KompozitMain2.webp'
                />
                <div style={styles.imageInformation}>
                    <img src={'https://amperino.com/imagesv2/Technology/composite/keten.webp'} style={width > 1000 ? { width: '50%', padding: 50, paddingTop: 150 } : { paddingBottom: 50, paddingTop: 30 }} />
                    <div style={width > 1000 ? { marginTop: '10%' } : null}>
                        <h3><FormattedMessage id="composite.subheader1" /> </h3>
                        <FormattedMessage id="composite.subheader1.info" />
                    </div>
                </div>
            </div>
            <CompositeCarousel />
            <div style={{ paddingBottom: 50, paddingTop: 50, width: width > 499 ? width - 17 : width, marginLeft: width > 499 ? -17 : -16, display: width < 600 ? 'ruby-text' : 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <div style={{ width: width < 600 ? width - 20 : width / 3.5, height: 268, backgroundColor: '#CDC5B1', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)' }}>
                    <div style={{ textAlign: 'center', width: '90%' }}>
                        <h3><FormattedMessage id="composite.subheader2" /></h3>
                        <FormattedMessage id="composite.subheader2.info" />
                    </div>
                </div>
                <div style={{ width: width < 600 ? width - 20 : width / 3.5, height: 378, backgroundColor: '#F8F8F8', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)' }}>
                    <div style={{ textAlign: 'center', width: '90%' }}>
                        <h3><FormattedMessage id="composite.subheader3" /></h3>
                        <FormattedMessage id="composite.subheader3.info1" /><br />
                        <FormattedMessage id="composite.subheader3.info2" /><br />
                        <FormattedMessage id="composite.subheader3.info3" />
                    </div>
                </div>
                <div style={{ width: width < 600 ? width - 20 : width / 3.5, height: 268, backgroundColor: '#CDC5B1', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)' }}>
                    <div style={{ textAlign: 'center', width: '90%' }}>
                        <h3><FormattedMessage id="composite.subheader4" /></h3>
                        <FormattedMessage id="composite.subheader4.info" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default React.memo(Composite);

