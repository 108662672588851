import React, { useEffect, useState } from 'react';
import Hamburger from 'hamburger-react';
import useWindowDimensions from './window';
import BurgerLink from './BurgerLink';
import BurgerSubLink from './BurgerSubLink';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function Example() {
  const [isOpen, setOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  const location = useLocation();

  
  useEffect(() => {
    if (isOpen) {
      setOpen(false);
      document.body.classList.remove('menu-open');
    }

  }, [location.pathname])

  const handleClick = () => {
    setOpen(!isOpen);
    if (!isOpen) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
  }

  return (
    <div style={{ display: 'table', position: 'absolute', zIndex: '20', width: '100%', height: 50, marginTop: 60}}>
        <div style={{display: 'flex', marginRight: 20, zIndex: '20', justifyContent: 'end'}}>
            <Hamburger color='white' toggled={isOpen} toggle={setOpen} onToggle={handleClick} />  
        </div>
        <div style={{
          width,
          height: height-117,
          backgroundColor: 'rgba(0,0,0,1)',
          display: isOpen ? 'flex' : 'none',
          zIndex: '20',
          color: 'white',
          justifyContent: 'center'
        }}>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection:'column', marginTop: '-10%'}}>
            <BurgerLink link="/" name={<FormattedMessage id="burger.header1" />} />
            <BurgerLink link="/car" name={<FormattedMessage id="burger.header2" />} />
            <BurgerLink link="/technology" name={<FormattedMessage id="burger.header3" />} />
            <BurgerSubLink link="/technology/battery" name={<FormattedMessage id="burger.header3.subHeader1" />} />            
            <BurgerSubLink link="/technology/composite" name={<FormattedMessage id="burger.header3.subHeader2" />} />
            <BurgerSubLink link="/technology/drive-technology" name={<FormattedMessage id="burger.header3.subHeader3" />} />
            <BurgerSubLink link="/technology/digital-vehicle-platform" name={<FormattedMessage id="burger.header3.subHeader4" />} />
            <BurgerSubLink link="/technology/skateboard-chassis" name={<FormattedMessage id="burger.header3.subHeader5" />} />
            <BurgerLink link="/development" name={<FormattedMessage id="burger.header4" />} />          
            <BurgerSubLink link="/development/sketch" name={<FormattedMessage id="burger.header4.subHeader1" />} />
            <BurgerSubLink link="/development/clay" name={<FormattedMessage id="burger.header4.subHeader2" />} />
            <BurgerSubLink link="/development/scan" name={<FormattedMessage id="burger.header4.subHeader3" />} />
            <BurgerSubLink link="/development/cad" name={<FormattedMessage id="burger.header4.subHeader4" />} />
            <BurgerSubLink link="/development/vr" name={<FormattedMessage id="burger.header4.subHeader5" />} />
            <BurgerSubLink link="/development/cae" name={<FormattedMessage id="burger.header4.subHeader6" />} />
            <BurgerSubLink link="/development/prototype" name={<FormattedMessage id="burger.header4.subHeader7" />} />
            <BurgerLink link="/company" name={<FormattedMessage id="burger.header5" />} />
          </div>
            
        </div>
    </div>
  );
}

export default Example;
