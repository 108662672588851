import React from "react";
import { FormattedMessage } from "react-intl";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import useWindowDimensions from './window';

function CarOpacity() {
  const { height, width } = useWindowDimensions();

  const inlineDiv = {
    height: 450,
    width: '75%',
    marginLeft: '10%',
    marginTop: width > 1000 ? -50 : null,
    paddingBottom: 50,
    padding: 10,
    textAlign: 'center',
    background: "white",
    letterSpacing: 1
  }

  return (
    <div style={{ background: "linear-gradient(#fff, #ccc, #fff)", width: width > 499 ? width - 17 : width, marginLeft: -16 }}>
      <div style={{ width: width > 499 ? '96%' : width - 20, marginLeft: '2%' }}>
        <Fade autoplay={true} duration={4000} transitionDuration={500} >
          <div className="each-fade">
            <img src='https://amperino.com/imagesv2/The%20Car/opacity/op00.webp' style={{ width: width > 1200 ? '60%' : null, marginLeft: width > 1200 ? '20%' : null }} />
            <div style={inlineDiv}>
              <h3 style={{ marginTop: 140, fontSize: 30 }}><FormattedMessage id="opacity.discover" /></h3>
            </div>
          </div>
          <div className="each-fade">
            <img src='https://amperino.com/imagesv2/The%20Car/opacity/op01.webp' style={{ width: width > 1200 ? '60%' : null, marginLeft: width > 1200 ? '20%' : null }} />
            <div style={inlineDiv}>
              <h3 style={{ marginTop: 40 }}><FormattedMessage id="opacity.composite1" /></h3>
              <h3 style={{ marginTop: 40 }}><FormattedMessage id="opacity.composite2" /></h3>
              <FormattedMessage id="opacity.composite3" />
              <h3><FormattedMessage id="opacity.composite4" /></h3>
              <FormattedMessage id="opacity.composite5" /><br /><FormattedMessage id="opacity.composite6" />
            </div>
          </div>
          <div className="each-fade">
            <img src='https://amperino.com/imagesv2/The%20Car/opacity/op02.webp' style={{ marginTop: width < 1200 ? 60 : 182, width: width > 1200 ? '60%' : null, marginLeft: width > 1200 ? '20%' : null }} />
            <div style={inlineDiv}>
              <h3 style={{ marginTop: 40 }}><FormattedMessage id="opacity.chassis1" /></h3>
              <p style={{ width: '90%', marginLeft: '5%', marginTop: 20, letterSpacing: 1 }}>
                <FormattedMessage id="opacity.chassis2" /><br /><br />
                <FormattedMessage id="opacity.chassis3" /><br /><br />
                <FormattedMessage id="opacity.chassis4" /><br /><br />
                <FormattedMessage id="opacity.chassis5" /><br /><br />
                <FormattedMessage id="opacity.chassis6" />
              </p>
            </div>
          </div>
          <div className="each-fade">
            <img src='https://amperino.com/imagesv2/The%20Car/opacity/op03.webp' style={{ marginTop: width < 1200 ? 60 : 182, width: width > 1200 ? '60%' : null, marginLeft: width > 1200 ? '20%' : null }} />
            <div style={inlineDiv}>
              <h3 style={{ marginTop: 40 }}><FormattedMessage id="opacity.battery1" /></h3>
              <p style={{ width: '90%', marginLeft: '5%', letterSpacing: 1 }}>
                <FormattedMessage id="opacity.battery2" /><br /><br />
                <FormattedMessage id="opacity.battery3" /><br /><br />
                <FormattedMessage id="opacity.battery4" /><br /><br />
                <FormattedMessage id="opacity.battery5" /><br /><br />
                <FormattedMessage id="opacity.battery6" /><br /><br />
                <FormattedMessage id="opacity.battery7" /><br /><br />
                <FormattedMessage id="opacity.battery8" />
              </p>
            </div>
          </div>
          <div className="each-fade">
            <img src='https://amperino.com/imagesv2/The%20Car/opacity/op04.webp' style={{ marginTop: width < 1200 ? 60 : 182, width: width > 1200 ? '60%' : null, marginLeft: width > 1200 ? '20%' : null }} />
            <div style={inlineDiv}>
              <h3 style={{ marginTop: 60 }}><FormattedMessage id="opacity.technology1" /></h3>
              <FormattedMessage id="opacity.technology2" /><br />
              <FormattedMessage id="opacity.technology3" />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default CarOpacity;