import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function DriveTechnology() {

    const { height, width } = useWindowDimensions();

    const styles = getStyles({ width });

    const inlineDiv = {
        backgroundColor: 'white',
        padding: 50,
        textAlign: 'justify',
        marginTop: width < 1000 ? -5 : null
    }

    const imageInformationImg = {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '80%',
        marginLeft: '10%',
        display: 'flex',
        position: 'relative',
        marginTop: width > 1000 ? -200 : -50
    }

    const imageInformationImg2 = {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '80%',
        marginLeft: '10%',
        display: 'flex',
        position: 'relative',
        marginTop: width > 1000 ? -150 : -40
    }

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="drive.header" />}
                    headerInfo={<FormattedMessage id="drive.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/SurusTekMain.webp'
                    subImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/SurusTekMain2.webp'
                />
                <div style={{ width: width > 499 ? width - 17 : width, marginTop: 1 }}>
                    <img src='https://amperino.com/imagesv2/Technology/drivetechnologies/mainImg.webp' style={{ width }} />
                    <div style={imageInformationImg}>
                        <div style={{ minHeight: 250, width: width > 499 ? '50%' : '90%', marginLeft: width > 499 ? '25%' : '5%', marginTop: 110 }}>
                            <h2 ><FormattedMessage id="drive.subHeader1" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="drive.subHeader1.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformation}>
                    <div style={{ display: 'flex', justifyContent: 'center', minWidth: 300, }}>
                        <img
                            src='https://amperino.com/imagesv2/Technology/drivetechnologies/motorv2.webp'
                            style={{ width: width > 2200 ? width / 4 : width > 1000 ? width / 2 : null, zIndex: 21, marginLeft: width > 1000 ? '100%' : null }}
                        />
                    </div>

                    <div style={inlineDiv}>
                        <div style={width > 1000 ? { marginTop: width > 2800 ? '17%' : '6%', marginLeft: width / 5 } : { width: '120%', marginLeft: '-10%', textAlign: 'justify' }}>
                            <FormattedMessage id="drive.subHeader1.info2" />
                            <br /><br />
                            <FormattedMessage id="drive.subHeader1.info3" />
                            <br /><br />
                            <FormattedMessage id="drive.subHeader1.info4" />
                            <br /><br />
                            <FormattedMessage id="drive.subHeader1.info5" />
                            <br /><br />
                            <FormattedMessage id="drive.subHeader1.info6" />
                        </div>
                    </div>
                </div>
                <div style={{ width: width > 499 ? width - 17 : width, marginTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Technology/drivetechnologies/machine1.webp' style={{ width }} />
                    <div style={imageInformationImg2}>
                        <div style={{ minHeight: 250, width: width > 499 ? '50%' : '80%', marginLeft: width > 499 ? '25%' : '10%', marginTop: 60, paddingBottom: 50 }}>
                            <div style={width > 1000 ? { marginTop: '2%' } : { width: '120%', marginLeft: -20, textAlign: 'center' }}>
                                <h2 ><FormattedMessage id="drive.subHeader2" /></h2>
                                <p style={{ textAlign: 'justify' }}>
                                    <FormattedMessage id="drive.subHeader2.info" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default React.memo(DriveTechnology);

