import React from 'react'
import styled from 'styled-components'
import FacebookIcon from '../images/Footer/FacebookIcon.svg'
import InstagramIcon from '../images/Footer/InstagramIcon.svg'
import TwitterIcon from '../images/Footer/XIcon.svg'
import YouTubeIcon from '../images/Footer/YouTubeIcon.svg'
import LinkedinIcon from '../images/Footer/LinkedinIcon.svg'
import Map from '../images/Footer/map.svg'
import useWindowDimensions from './window'
import { FormattedMessage } from 'react-intl'


function Footer() {

  const {width, height} = useWindowDimensions()

  const MainStyle = styled.div`
    width: 100%+80;
    height: 300px;
    margin: -16px;
    display: flex-row;
    letter-spacing: 1px;
    `;

  const MobileMainStyle = styled.div`
    width: 100%+80;
    height: 501px;
    margin-left: -16px;
    margin-right: -16px;
    display: flex-row;
    letter-spacing: 1px;
    `;

  const socialLinkStyle = {display: 'contents', cursor: 'pointer'}

  return (
    <div>
    {width > 1300 ?
    <MainStyle >
        <div style={{ backgroundColor: 'rgba(237,237,237,1)', opacity: 0.811, height: 90}}>
            <div style={{fontSize: 12, flexDirection: 'row', display: 'flex', textAlign: 'left', justifyContent: 'center'}}>

                <a style={socialLinkStyle} target='_blank' href='https://www.facebook.com/AmperinoAuto/'>
                    <img src={FacebookIcon} style={{marginRight: 10}} />
                    <div style={{alignSelf: 'center', color: 'rgba(78,90,220,1)'}}>
                        Amperino
                        <br />
                        <b>Facebook</b> <FormattedMessage id="footer.follow" />
                    </div>
                </a>
                
                <a style={socialLinkStyle} target='_blank' href='https://www.instagram.com/amperinoauto/'>
                    <img src={InstagramIcon} style={{marginRight: 20, marginLeft: 50}} />
                    <div style={{alignSelf: 'center', color: 'rgba(235,132,60,1)'}}>
                        Amperino
                        <br />
                        <b>Instagram</b> <FormattedMessage id="footer.follow" />
                    </div>
                </a>

                <a style={socialLinkStyle} target='_blank' href='https://twitter.com/AmperinoAuto' >
                    <img src={TwitterIcon} style={{marginLeft: 50, backgroundColor: '#f9f9f9', height:30, padding: 10, marginTop: 24, borderRadius: 50}} />
                    <div style={{alignSelf: 'center', color: 'black', marginLeft: 20}}>
                        Amperino
                        <br />
                        <b>Twitter</b> <FormattedMessage id="footer.follow" />
                    </div>
                </a>

                <a style={socialLinkStyle} target='_blank' href='https://www.youtube.com/channel/UChhHenHCZiFz0TdRD5ukTjQ'>
                    <img src={YouTubeIcon} style={{ marginLeft: 50}} />
                    <div style={{alignSelf: 'center', color: 'rgba(225,34,28,1)'}}>
                        Amperino
                        <br />
                        <b>YouTube</b> <FormattedMessage id="footer.follow" />
                    </div>
                </a>
                <a style={socialLinkStyle} target='_blank' href='https://www.linkedin.com/company/amperinoauto/'>
                <img src={LinkedinIcon} style={{marginRight: 20, marginLeft: 50}} />
                    <div style={{alignSelf: 'center', color: 'rgba(118,186,249,1)'}}>
                        Amperino
                        <br />
                        <b>LinkedIn</b> <FormattedMessage id="footer.follow" />
                    </div>
                </a>
            </div>
        </div>
        
        <div style={{backgroundColor: 'rgba(0,0,0,0.9)', display: 'flex', color: 'white', height: '100%', alignItems:'center', justifyContent: 'center'}}>
            <a href="/">
              <img src={'https://amperino.com/imagesv2/logooo.svg'} style={{width: 250, marginRight: 50}} />
            </a>  
            <div style={{marginRight: 50, fontSize: 12}}>
                <div style={{marginBottom: 5}}>
                    <a href='/' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                        <FormattedMessage id="footer.header1" />
                    </a> 
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/car' style={{padding: 3, textDecoration: 'none', color: 'white', paddingBottom: 10}}>
                        <FormattedMessage id="footer.header1.subHeader1" />
                    </a>
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/technology' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header1.subHeader2" />
                    </a>   
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/development' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header1.subHeader3" />
                    </a>  
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header1.subHeader4" />
                    </a>    
                </div>           
            </div>

            <img src={'https://amperino.com/imagesv2/VerticalLine.svg'} style={{position: 'relative', marginTop: -40, marginLeft: -16, marginRight: 20}}/>
            <div style={{marginRight: 50, fontSize: 12, marginTop: 19}}>
                <div style={{marginBottom: 5}}>
                    <a href='/technology' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                        <FormattedMessage id="footer.header2" />
                    </a> 
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/technology/battery' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header2.subHeader1" />
                    </a>  
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/technology/composite' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header2.subHeader2" />
                    </a>  
                </div>   
                <div style={{marginBottom: 5}}>
                    <a href='/technology/drive-technology' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header2.subHeader3" />
                    </a>  
                </div>     
                <div style={{marginBottom: 5}}>
                    <a href='/technology/digital-vehicle-platform' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header2.subHeader4" />
                    </a>  
                </div>   
                <div style={{marginBottom: 5}}>
                    <a href='/technology/skateboard-chassis' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header2.subHeader5" />
                    </a>  
                </div>             
            </div>

            <img src={'https://amperino.com/imagesv2/VerticalLine.svg'} style={{position: 'relative', marginTop: -40, marginLeft: -16, marginRight: 20}}/>
            <div style={{marginRight: 50, fontSize: 12, marginTop: 58}}>
                <div style={{marginBottom: 5}}>
                    <a href='/development' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                        <FormattedMessage id="footer.header3" />
                    </a> 
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/development/sketch' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader1" />
                    </a>  
                </div> 
                <div style={{marginBottom: 5}}>
                    <a href='/development/clay' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader2" />
                    </a>  
                </div> 
                <div style={{marginBottom: 5}}>
                    <a href='/development/scan' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader3" />
                    </a>  
                </div>                 
                <div style={{marginBottom: 5}}>
                    <a href='/development/cad' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader4" />
                    </a>  
                </div>   
                <div style={{marginBottom: 5}}>
                    <a href='/development/vr' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader5" />
                    </a>  
                </div>  
                <div style={{marginBottom: 5}}>
                    <a href='/development/cae' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader6" />
                    </a>  
                </div>                 
                <div style={{marginBottom: 5}}>
                    <a href='/development/prototype' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader7" />
                    </a>  
                </div> 
                              
            </div>

            <img src={'https://amperino.com/imagesv2/VerticalLine.svg'} style={{position: 'relative', marginTop: -40, marginLeft: -16, marginRight: 20}}/>
            <div style={{marginRight: 50, fontSize: 12, marginTop: -23}}>
                <div style={{marginBottom: 5}}>
                    <a href='/company' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                        <FormattedMessage id="footer.header4" />
                    </a> 
                </div>
                <div style={{marginBottom: 5}}>
                    <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header4.subHeader1" />
                    </a>  
                </div>  
                <div style={{marginBottom: 5}}>
                    <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header4.subHeader2" />
                    </a>  
                </div>   
                <div style={{marginBottom: 5}}>
                    <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header4.subHeader3" />
                    </a>  
                </div>                
            </div>


            <div style={{display: 'flex',height: 80}}>                
                <div style={{flexDirection: 'row', display: 'flex', width: '95%',  justifyContent: 'space-around'}}>
                <a href='https://www.google.com/maps/place/Amperino+Otomotiv/@40.091859,29.505961,10z/data=!4m5!3m4!1s0x0:0xa716faa1332a309d!8m2!3d40.0918589!4d29.5059608?hl=tr-TR' style={{display: 'flex',height: 80, textDecoration: 'none', color: 'white'}} target='_blank'>
                        <img src={Map} style={{marginRight: 15, width: 53.4}}/>
                    <div style={{marginTop: 16}}>
                        <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: -30, marginRight: 20}}><FormattedMessage id="footer.center" /></p> 
                    </div>                
                </a>
                <a href='https://www.google.com/maps/place/Amperino+Otomotiv/@40.7743044,29.4777441,12z/data=!4m5!3m4!1s0x14cb2150ebec5f45:0xf22fcbbad83ac5fc!8m2!3d40.7880969!4d29.5103683' style={{display: 'flex',height: 80, textDecoration: 'none', color: 'white'}} target='_blank'>
                        <img src={Map} style={{marginRight: 15, width: 53.4}}/>
                    <div style={{marginTop: 16}}>
                    <img src='https://bilisimvadisi.com.tr/wp-content/uploads/2022/05/BV_Logo_H.svg' style={{width: 60}}/>
                        {/* <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: -30}}>Bilişim Vadisi Office</p>  */}
                    </div>                
                </a>
            </div>
            </div>

        </div>
    </MainStyle>: 
    <MobileMainStyle>
        <div style={{ backgroundColor: 'rgba(237,237,237,1)', opacity: 0.811, height: 90}}>
            <div style={{display: 'flex', width: '90%', marginLeft: '5%', justifyContent: 'space-around', paddingTop: 4}}>
                <a style={socialLinkStyle} target='_blank' href='https://www.facebook.com/AmperinoAuto/'>
                    <img src={FacebookIcon} style={{width: 62}} />
                </a>
                <a style={socialLinkStyle} target='_blank' href='https://www.instagram.com/amperinoauto/'>
                    <img src={InstagramIcon} style={{width: 51}} />
                </a>
                <a style={socialLinkStyle} target='_blank' href='https://twitter.com/AmperinoAuto' >
                    <img src={TwitterIcon} style={{backgroundColor: '#f9f9f9', height:30, padding: 10, marginTop: 14, borderRadius: 50 }} />
                </a>
                <a style={socialLinkStyle} target='_blank' href='https://www.youtube.com/channel/UChhHenHCZiFz0TdRD5ukTjQ'>
                    <img src={YouTubeIcon} style={{margin: -25, marginTop: -17}} />
                </a>
                <a style={socialLinkStyle} target='_blank' href='https://www.linkedin.com/company/amperinoauto'>
                    <img src={LinkedinIcon} style={{marginLeft: 6, marginTop: 4 }} />
                </a>
            </div>
        </div>
        
        <div style={{backgroundColor: 'rgba(0,0,0,0.9)', display: 'flex', flexDirection:'column', color: 'white', height: '100%', alignItems:'center', justifyContent: 'center'}}>
            <a href="/">
              <img src={'https://amperino.com/imagesv2/logooo.svg'} style={{width: 250, paddingBottom: 20}} />
            </a>    
            <div style={{color: 'gray', marginTop: 10}}>- - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>
            <div style={{flexDirection: 'row', display: 'flex', width: '95%',  justifyContent: 'space-around', marginBottom: 10, marginTop: 10}}>
                <div style={{fontSize: 12}}>
                    <div style={{marginBottom: 5}}>
                        <a href='/technology' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                            <FormattedMessage id="footer.header2" />
                        </a> 
                    </div>
                    <div style={{marginBottom: 5}}>
                        <a href='/technology/battery' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header2.subHeader1" />
                        </a>  
                    </div>
                    <div style={{marginBottom: 5}}>
                        <a href='/technology/composite' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header2.subHeader2" />
                        </a>  
                    </div>   
                    <div style={{marginBottom: 5}}>
                        <a href='/technology/drive-technology' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header2.subHeader3" />
                        </a>  
                    </div>     
                    <div style={{marginBottom: 5}}>
                        <a href='/technology/digital-vehicle-platform' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header2.subHeader4" />
                        </a>  
                    </div>   
                    <div style={{marginBottom: 5}}>
                        <a href='/technology/skateboard-chassis' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header2.subHeader5" />
                        </a>  
                    </div>             
                </div>           
                <div style={{fontSize: 12,}}>
                    <div style={{marginBottom: 5}}>
                        <a href='/development' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                            <FormattedMessage id="footer.header3" />
                        </a> 
                    </div>                    
                    <div style={{marginBottom: 5}}>
                        <a href='/development/sketch' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader1" />
                        </a>  
                    </div> 
                    <div style={{marginBottom: 5}}>
                        <a href='/development/clay' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader2" />
                        </a>  
                    </div> 
                    <div style={{marginBottom: 5}}>
                        <a href='/development/scan' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader3" />
                        </a>  
                    </div> 
                    <div style={{marginBottom: 5}}>
                        <a href='/development/cad' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader4" />
                        </a>  
                    </div>   
                    <div style={{marginBottom: 5}}>
                        <a href='/development/vr' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader5" />
                        </a>  
                    </div>  
                    <div style={{marginBottom: 5}}>
                        <a href='/development/cae' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader6" />
                        </a>  
                    </div> 
                    <div style={{marginBottom: 5}}>
                        <a href='/development/prototype' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                        <FormattedMessage id="footer.header3.subHeader7" />
                        </a>  
                    </div>
                                
                </div>
                <div style={{fontSize: 12}}>
                    <div style={{marginBottom: 5}}>
                        <a href='/company' style={{padding: 3, marginBottom: 4, textDecoration: 'none', color: 'white', fontSize: 14, fontWeight: 'bold'}}>
                            <FormattedMessage id="footer.header4" />
                        </a> 
                    </div>
                    <div style={{marginBottom: 5}}>
                        <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header4.subHeader1" />
                        </a>  
                    </div>  
                    <div style={{marginBottom: 5}}>
                        <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header4.subHeader2" />
                        </a>  
                    </div>   
                    <div style={{marginBottom: 5}}>
                        <a href='/company' style={{padding: 3, textDecoration: 'none', color: 'white'}}>
                            <FormattedMessage id="footer.header4.subHeader3" />
                        </a>  
                    </div>                
                </div>  
            </div>
            <div style={{color: 'gray'}}>- - - - - - - - - - - - - - - - - - - - - - - - - - - - -</div>
            <div style={{flexDirection: 'row', display: 'flex', width: '95%',  justifyContent: 'space-around'}}>
                <a href='https://www.google.com/maps/place/Amperino+Otomotiv/@40.091859,29.505961,10z/data=!4m5!3m4!1s0x0:0xa716faa1332a309d!8m2!3d40.0918589!4d29.5059608?hl=tr-TR' style={{display: 'flex',height: 80, textDecoration: 'none', color: 'white'}} target='_blank'>
                        <img src={Map} style={{marginRight: 15, width: 40}}/>
                    <div style={{marginTop: 16}}>
                        <p style={{fontSize: 14, fontWeight: 'bold', marginBottom: -30, marginLeft: -6}}><FormattedMessage id="footer.center" /></p> 
                    </div>                
                </a>
                <a href='https://www.google.com/maps/place/Amperino+Otomotiv/@40.7743044,29.4777441,12z/data=!4m5!3m4!1s0x14cb2150ebec5f45:0xf22fcbbad83ac5fc!8m2!3d40.7880969!4d29.5103683' style={{display: 'flex',height: 80, textDecoration: 'none', color: 'white'}} target='_blank'>
                        <img src={Map} style={{marginRight: 8, width: 40}}/>
                    <div style={{marginTop: 16}}>
                        <img src='https://bilisimvadisi.com.tr/wp-content/uploads/2022/05/BV_Logo_H.svg' style={{width: 60}}/>
                    </div>                
                </a>
            </div>
        </div>
    </MobileMainStyle>
    }
    </div>
  )
}

export default Footer;