import React, { useState, useEffect } from 'react';
import useWindowDimensions from './window';

const Car360 = () => {
  const [imgIndex, setImgIndex] = useState(0);
  const { width } = useWindowDimensions();
  const [startTouchX, setStartTouchX] = useState(0);
  const [images, setImages] = useState([]);
  const imagesCount = 34;

  useEffect(() => {
    const loadedImages = [];
    for (let i = 1; i <= imagesCount; i++) {
      const image = new Image();
      image.src = `https://amperino.com/imagesv2/The%20Car/360Webp/${i}.webp`;
      loadedImages.push(image);
    }
    setImages(loadedImages);

    let index = 14;
    const intervalId = setInterval(() => {
      index = (index + 1) % imagesCount;
      setImgIndex(index);
    }, 100); 

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 3000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  const desktopSensitivity = Math.max(width / imagesCount / 10, 1);
  const mobileSensitivity = desktopSensitivity * 200; // Mobil hassasiyetini ayarla

  const updateImageIndex = (movementX, isMobile = false) => {
    const sensitivity = isMobile ? mobileSensitivity : desktopSensitivity;
    const indexChange = Math.round(movementX / sensitivity);
    setImgIndex((prevIndex) => {
      let newIndex = (prevIndex - indexChange) % imagesCount;
      if (newIndex < 0) newIndex = imagesCount + newIndex;
      return newIndex;
    });
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      updateImageIndex(e.movementX);
    }
  };

  const handleTouchMove = (e) => {
    const touchX = e.touches[0].clientX;
    const movementX = touchX - startTouchX;
    updateImageIndex(movementX, true);
  };

  const handleTouchStart = (e) => {
    const touchX = e.touches[0].clientX;
    setStartTouchX(touchX); // Yeni dokunma başlangıcı için başlangıç noktasını ayarla
  };


  const handleTouchEnd = () => {
    setStartTouchX(0); // Dokunma bittiğinde başlangıç noktasını sıfırla
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startTouchX]);

  return (
    <div 
      onMouseDown={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ cursor: 'grab', userSelect: 'none' }}
    >
      <img 
        src={images[imgIndex] ? images[imgIndex].src : ''} 
        alt="Car 360 View"
        draggable={false} 
        style={{ width: '100%', pointerEvents: 'none' }}
      />
    </div>
  );
};

export default Car360;
