import React, { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const hasLocale = localStorage.getItem("lang");
    const defaultLang = hasLocale || navigator.language || "en-US";
    const [lang, setLang] = useState(defaultLang);

    useEffect(() => {
        if (lang !== "tr-TR" && lang !== "en-US") {
            setLang("tr-TR");
            localStorage.setItem("lang", "tr-TR");
        } else {
            localStorage.setItem("lang", lang);
        }
    }, [lang]);

    return (
        <LanguageContext.Provider value={{ lang, setLang }}>
            {children}
        </LanguageContext.Provider>
    );
}

// Custom hook for using language context
export function useLanguage() {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error('useLanguage must be used within a LanguageProvider');
    }
    return context;
}
