import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { SectionWrapper } from '../hoc';
import { slideIn } from '../utils/motion';
import { send, sendHover } from '../assets';
import { FormattedMessage, useIntl } from 'react-intl'

const Contact = () => {

  const intl = useIntl();
  const formRef = useRef();
  const [hover, setHover] = useState(false)
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const buttonStyle = {
    display: 'flex',
    transitionDuration: 0.2,
    justifyContent: 'center',
    alignItems: 'center',
    width: 150,
    height: 60,
    gap: '1rem',
    marginTop: 8,
    fontSize: '20px',
    fontWeight: 700,
    color: hover ? '#f0f0f0' : '#000',
    backgroundColor: hover ? '#000' : '#fff',
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    borderRadius: '0.5rem',
    border: 'none',
    cursor: 'pointer'
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (form.name == "" || form.email == "" || form.message == "") {
      setLoading(false);
      alert(intl.formatMessage({ id: 'contact.error.empty' }));
    }
    else {
      emailjs
        .send(
          'service_isx84wa',
          'template_gwytl5d',
          {
            from_name: form.name,
            to_name: 'Admin',
            from_email: form.email,
            to_email: 'form.contact@amperino.com',
            message: form.message,
            reply_to: form.email,
          },
          'TQVKwSlWyQVJD0nYg'
        )
        .then(
          () => {
            setLoading(false);
            alert(intl.formatMessage({ id: 'contact.success' }));

            setForm({
              name: '',
              email: '',
              message: '',
            });
          },
          (error) => {
            setLoading(false);
            console.log(error);
            alert(intl.formatMessage({ id: 'contact.error' }));
          }
        );
    }

  };

  return (
    <div style={{ marginTop: 20, gap: 2.5, overflow: 'hidden', width: '100%' }} >
      <motion.div
        variants={slideIn('left', 'tween', 0.2, 1)}
        style={{ backgroundColor: '#ebf3ff', padding: '2rem', borderRadius: '1rem', textAlign: 'justify' }}
      >
        <p style={{ textTransform: 'uppercase', letterSpacing: '0.1em', fontWeight: 600, fontSize: 18 }} ><FormattedMessage id="contact.header1" /></p>
        <h3 style={{ textTransform: 'uppercase', letterSpacing: '0.1em', fontWeight: 600, fontSize: 40, marginTop: -16 }} ><FormattedMessage id="contact.header2" /></h3>

        <form ref={formRef} onSubmit={handleSubmit}>
          <label style={{ display: 'flex', flexDirection: 'column' }} >
            <span style={{ marginBottom: '1rem' }} ><FormattedMessage id="contact.name" /></span>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'contact.name.placeholder' })}
              style={{ backgroundColor: '#fff', padding: '1rem', border: 'none', borderRadius: '0.5rem', outline: 'none' }}
            />
          </label>
          <label style={{ display: 'flex', flexDirection: 'column' }} >
            <span style={{ marginBottom: '1rem' }} ><FormattedMessage id="contact.email" /></span>
            <input
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'contact.email.placeholder' })}
              style={{ backgroundColor: '#fff', padding: '1rem', border: 'none', borderRadius: '0.5rem', outline: 'none' }}
            />
          </label>
          <label style={{ display: 'flex', flexDirection: 'column' }} >
            <span style={{ marginBottom: '1rem' }} ><FormattedMessage id="contact.message" /></span>
            <textarea
              rows="7"
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'contact.message.placeholder' })}
              style={{ backgroundColor: '#fff', padding: '1rem', border: 'none', borderRadius: '0.5rem', outline: 'none' }}
            />
          </label>

          <button
            type="submit"
            style={buttonStyle}
            onMouseOver={() => {
              setHover(true)
            }}
            onMouseOut={() => {
              setHover(false)
            }}
          >
            <img src={hover ? send : sendHover} alt="send" style={{ width: 40, height: 40 }} className="contact-btn" />
            <FormattedMessage id="contact.send" />
          </button>
        </form>
      </motion.div>
    </div>
  );
};
export default SectionWrapper(Contact, 'contact');
