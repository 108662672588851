import React, { useState } from 'react'
import MenuBar from '../components/MenuBar';
import GreenIcon from '../images/AmperinoPage/greenIcon.svg';
import ActivityIcon from '../images/AmperinoPage/activityIcon.svg';
import EnergyIcon from '../images/AmperinoPage/energyIcon.svg';
import SpeedIcon from '../images/AmperinoPage/speedIcon.svg';
import useWindowDimensions from '../components/window';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Slider360 from '../components/Car360';
import CarOpacity from '../components/CarOpacity';
import { FormattedMessage, FormattedDate } from "react-intl";
import { Button, Modal } from 'antd';

function TheCar() {
    
    const { height, width } = useWindowDimensions();
    const [modal2Open, setModal2Open] = useState(false);

    const HeadImage = styled.div`
    background-size: cover;
    background-image: url(https://amperino.com/imagesv2/The%20Car/MainImage.webp);
    background-position: 50% 0%;
    background-repeat: no-repeat;
    /* add tint */
    background-color: rgba(0,0,0,0.50);
    background-blend-mode: overlay;
    width: ${width}px;
    height: 950px;
    overflow: hidden;
    @media (min-width: 2000px) {
        background-size: cover;
        background-image: url(https://amperino.com/imagesv2/The%20Car/MainImage.webp);
        background-position: 50% 110%;
        background-repeat: no-repeat;
        /* add tint */
        background-color: rgba(0,0,0,0.50);
        background-blend-mode: overlay;
        width: ${width}px;
        height: ${width/2}px;
        overflow: hidden;
    }
    `;
    
    const MainContainer = styled.div`
    overflow: hidden;
    width: 100%;
    z-index: 20;
    display: flex;
    position: relative;
    margin-Top: 130px;
    `;
   
    const contentContainer = {
        backgroundColor: '#ebf3ff',
        marginLeft: -16,
        width: width > 499 ? width-16.6 : width
    }
    
    const explanationP = {
        paddingTop: 50,
        marginTop: -32,
        zIndex: 20,
        paddingBottom: width > 1200 ? 0 : 350,
        fontSize: 20,
        position: 'relative', 
        height: 467,
        backgroundColor: 'rgba(41,41,41,1)',
        textAlign: 'center', 
        justifyContent: 'center',
        color: 'white',
        letterSpacing: 1,
    }
    
    const informationStyle={
        display: 'flex',
        flexDirection: width > 1200 ? 'row' : 'column', 
        justifyContent: width > 1200 ? 'space-around' : 'center', 
        width: width > 1200 ? '70%' : '60%', 
        marginLeft: width > 1200 ? '15%': width > 800 ? '40%' : '20%'
    }
    
    const menubarStyle = {
        position: 'relative', 
        width: width > 499 ? width-18 : width, 
        marginLeft:  width > 499 ? -15 : -16
    }
    const mainImg = {
        position: 'relative', 
        width: width > 499 ? width-17 : width, 
        marginLeft: -16
    }
    
  return (
    <div>
        <div style={menubarStyle}>
            <MenuBar />
        </div>
        <div style={mainImg}>
            <MainContainer>
                <HeadImage style={{marginTop: 67}} />
            </MainContainer>
        </div>

        <div style={contentContainer}>
            <div style={explanationP}>
                <p style={{paddingTop: 50, marginBottom: 50}}><FormattedMessage id="car.explanation" /></p><br />
                <br />
                <div style={informationStyle}>
                    <div style={{display: 'flex'}}>
                        <img src={GreenIcon} style={{width: 60}} />
                        <div style={{fontSize: 20,  marginLeft: 8}}>
                            <p><FormattedMessage id="car.information1" /></p>
                            <p style={{fontWeight: 'bold', marginTop: -12, color: '#fcba03'}}><FormattedMessage id="car.information1.1" /></p>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <img src={ActivityIcon} style={{width: 60}} />  
                        <div style={{fontSize: 20,  marginLeft: 8}}>
                            <div style={localStorage.getItem("lang") == "tr-TR" ? width<800 ? {marginLeft: 35} : null : null}>
                                <p><FormattedMessage id="car.information2" /></p>
                                <p style={{fontWeight: 'bold', marginTop: -12, color: '#fcba03'}}>150 km</p>
                            </div>
                        </div>                     
                    </div>
                    <div style={{display: 'flex'}}>
                        <img src={EnergyIcon} style={{width: 60}} />  
                        <div style={{fontSize: 20,  marginLeft: 8}}>
                            <p><FormattedMessage id="car.information3" /></p>
                            <p style={{fontWeight: 'bold', marginTop: -12, color: '#fcba03'}}> <FormattedMessage id="car.information3.1" /> <br/> <FormattedMessage id="car.information3.2" /></p>
                        </div>                      
                    </div>
                    <div style={{display: 'flex'}}>
                        <img src={SpeedIcon} style={{width: 60}} />
                        <div style={{fontSize: 20,  marginLeft: 8}}>
                            <p><FormattedMessage id="car.information4" /></p>
                            <p style={{fontWeight: 'bold', marginTop: -12, color: '#fcba03'}}><p><FormattedMessage id="car.information5" /></p></p>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>  
        <Slider360 />
        <div style={{textAlign: 'center', fontWeight: 'bold', padding: 40}}>
            <p style={{ fontSize: 48, color: '#11bd9a'}}><FormattedMessage id="car.notification" /></p>
        </div>
        <CarOpacity />
        <div style={{paddingBottom: 100}}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <img src='https://amperino.com/imagesv2/The%20Car/Top.webp' style={{marginTop: 0, marginBottom: 40, width: width/3*2, display: width < 1000 ? 'none' : null}} />
            <img src='https://amperino.com/imagesv2/The%20Car/TopUpv2.webp' style={{marginTop: 0, marginBottom: 40, width, display: width < 1000 ? null : 'none'}} />
        </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{alignSelf:'center'}}>
                    <Button type="primary" onClick={() => setModal2Open(true)}>
                        <FormattedMessage id="car.details" />
                    </Button>
                    <Modal
                        title={<FormattedMessage id="car.details" />}
                        centered
                        open={modal2Open}
                        maskClosable={true}
                        footer={null}
                        onCancel={() => setModal2Open(false)}
                        style={{backgroundColor: '#000'}}
                    >
                        <br />
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info1" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info2" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info3" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info4" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info5" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info6" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info7" /></p>
                        <p style={{display: 'list-item'}}><FormattedMessage id="car.info8" /></p><br />
                    </Modal>
                </div> 
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default TheCar; 

