export function getStyles({width}) {

  const imageInformation = {
    width: '90%',
    marginLeft: '5%',
    textAlign: 'justify',
    display: width > 1000 ? 'flex' : 'table',
    paddingBottom: 50
  };
  
  const imageInformationReverse = {
    width: '90%',
    marginLeft: '5%',
    display: width > 1000 ? 'flex' : 'table',
    paddingBottom: 50,
    textAlign: 'justify',
    flexDirection: width > 1000 ? 'row-reverse' : null
  };
  
  const menubarStyle = {
    position: 'relative',
    width: width > 499 ? width - 18 : width,
    marginLeft: width > 499 ? -15 : -16,
    paddingTop: 100
  };
  
  const mainImg = {
    position: 'relative',
    width: width > 499 ? width - 17 : width,
    marginLeft: -16,
    paddingBottom: 50,
    letterSpacing: 1
  };
  
  const explanationP = {
    margin: 100,
    textAlign: 'justify',
    width: '86%',
    marginLeft: '8%'
  };

  return { imageInformation, imageInformationReverse, menubarStyle, mainImg, explanationP };
}
