import { Button } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

function Error() {
  return (
    <div style={{
        backgroundColor: '#000', 
        display: 'flex',
        height: '100%', 
        width: '100%',
        Index: '20',
        marginLeft: -16,
        marginTop: -16,
        position: 'absolute',
        alignItems: 'center'
        }}>
        <div style={{
            width: '100%', 
            transform: 'translate(0%, 0%)', 
            zIndex: '21',
            color: 'white', 
            textAlign: 'center',
            }}>
            <h2><FormattedMessage id="error.header" /></h2>
            <div><FormattedMessage id="error.header.info" /></div>
            <Button ghost href='/' size='large' style={{margin: 60}} ><FormattedMessage id="error.backButton" /></Button>
            
        </div>
    </div>
  )
}

export default Error