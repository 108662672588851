import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function DigitalVehiclePlatform() {

    const { height, width } = useWindowDimensions();

    const styles = getStyles({ width });

    const inlineDiv = {
        backgroundColor: 'white',
        textAlign: 'justify',
        padding: 50,
        marginTop: width < 1000 ? -5 : null
    }

    const imageInformationImg = {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '80%',
        marginLeft: '10%',
        display: 'flex',
        position: 'relative',
        marginTop: width > 1000 ? -150 : -30
    }

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="platform.header" />}
                    headerInfo={<FormattedMessage id="platform.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/DijitalAracMain.webp'
                    subImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/DijitalAracMain2.webp'
                />
                <div style={{ width: width > 499 ? width - 17 : width, marginTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Technology/digitalvehicle/display.webp' style={{ width }} />
                    <div style={imageInformationImg}>
                        <div style={{ minHeight: 250, width: width > 800 ? '50%' : '80%', marginLeft: width > 800 ? '25%' : '10%', marginTop: 60, paddingBottom: 50 }}>
                            <h2 style={{}}><FormattedMessage id="platform.subHeader1" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="platform.subHeader1.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Technology/digitalvehicle/mobile.webp' style={{ backgroundColor: ' rgba(230,230,230,1)', maxHeight: width > 1000 ? width / 3 : 1000 }} />
                    <div style={inlineDiv}>
                        <div>
                            <FormattedMessage id="platform.subHeader1.info2" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader1.info3" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader1.info4" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader1.info5" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader1.info6" />
                            <br /><br />
                        </div>
                    </div>
                </div>
                <div style={{ width: width > 499 ? width - 17 : width, marginTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Technology/digitalvehicle/computt.webp' style={{ width }} />
                    <div style={styles.imageInformationImg}>
                        <div style={{ minHeight: 250, width: width > 800 ? '50%' : '80%', marginLeft: width > 800 ? '25%' : '10%', marginTop: 60, paddingBottom: 50 }}>
                            <h2 ><FormattedMessage id="platform.subHeader2" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="platform.subHeader2.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Technology/digitalvehicle/kart.webp' style={width > 1000 ? { backgroundColor: ' rgba(230,230,230,1)', maxHeight: width > 1000 ? width / 3 : 1000 } : { backgroundColor: ' rgba(230,230,230,1)' }} />
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? null : { width: '120%', marginLeft: '-10%' }}>
                            <FormattedMessage id="platform.subHeader2.info2" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader2.info3" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader2.info4" />
                        </div>
                    </div>
                </div>
                <div style={{ width: width > 499 ? width - 17 : width, marginTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Technology/digitalvehicle/display2.webp' style={{ width }} />
                    <div style={styles.imageInformationImg}>
                        <div style={{ minHeight: 250, width: width > 800 ? '50%' : '80%', marginLeft: width > 800 ? '25%' : '10%', marginTop: 60, paddingBottom: 50 }}>
                            <h2 ><FormattedMessage id="platform.subHeader3" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="platform.subHeader3.info1" />
                                <br /><br />
                                <FormattedMessage id="platform.subHeader3.info2" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Technology/digitalvehicle/mobile2.webp' style={{ backgroundColor: ' rgba(230,230,230,1)', maxHeight: width > 1000 ? width / 3 : 1000 }} />
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? null : { width: '120%', marginLeft: '-10%', textAlign: 'justify' }}>
                            <FormattedMessage id="platform.subHeader3.info3" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader3.info4" />
                            <br /><br />
                            <FormattedMessage id="platform.subHeader3.info5" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default React.memo(DigitalVehiclePlatform);

