import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function SkateboardChassi() {

    const { height, width } = useWindowDimensions();

    const styles = getStyles({ width });

    const inlineDiv = {
        backgroundColor: 'white',
        padding: 50,
        marginTop: width < 1000 ? -5 : null,
    }

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="chassis.header" />}
                    headerInfo={<FormattedMessage id="chassis.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/KaykaysasiMain.webp'
                    subImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/KaykaysasiMain2.webp'
                />
                <div style={{ width: width - 17, marginBottom: 20 }}>
                    <div style={{ backgroundColor: 'white', textAlign: 'center', width: '80%', marginLeft: '10%', display: 'flex', position: 'relative', }}>
                        <div style={{ minHeight: 250, width }}>
                            <h2 style={{ textAlign: 'center', marginTop: '10%'}}><FormattedMessage id="chassis.subHeader1" /> </h2>
                            <p style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginBottom: '10%'  }}>
                                <FormattedMessage id="chassis.subHeader1.info1" /><br />
                                <FormattedMessage id="chassis.subHeader1.info2" /> <br />
                                <FormattedMessage id="chassis.subHeader1.info3" /><br />
                                <FormattedMessage id="chassis.subHeader1.info4" /><br />
                                <FormattedMessage id="chassis.subHeader1.info5" /><br />
                                <FormattedMessage id="chassis.subHeader1.info6" /> <br />
                                <FormattedMessage id="chassis.subHeader1.info7" /><br />

                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Technology/skateboardchassis/upChassiss.webp' style={{ height: width > 1200 ? width / 5 : 450 }} />
                    <div style={inlineDiv}>
                        <h2 style={width > 1000 ? null : { width: '120%', marginLeft: '-10%', textAlign: 'center' }}><FormattedMessage id="chassis.subHeader2" /></h2>
                        <div style={width < 1000 ? { width: '120%', marginLeft: '-10%', textAlign: 'justify' } : null}>
                            <p>
                                <FormattedMessage id="chassis.subHeader2.info" />
                            </p>
                        </div>
                    </div>
                </div>

                <div style={{ width: width - 17, marginTop: width > 1000 ? -255 : -5 }}>
                    <img src='https://amperino.com/imagesv2/Technology/skateboardchassis/sideChassis.webp' style={{ width: width - 19 }} />
                    <div style={styles.imageInformation}>
                        <div style={{ minHeight: 250, maxWidth: width, padding: 20 }}>
                            <h2 style={width > 1000 ? { marginTop: '3%' } : null}><FormattedMessage id="chassis.subHeader3" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="chassis.subHeader3.info" />
                            </p>
                        </div>
                    </div>
                </div>



            </div>
            <Footer />
        </div>
    )
}

export default React.memo(SkateboardChassi);

