import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function Prototip() {
    
    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});

    const imageInformationImg = {
        backgroundColor: 'white', 
        textAlign: 'center', 
        width: '80%', 
        marginLeft: '10%', 
        display: 'flex', 
        position: 'relative', 
        marginTop: width > 1000 ? -120 : -30
    }

  return (
    <div>
        <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="prototype.header" />}
                    headerInfo={<FormattedMessage id="prototype.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/PrototipMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/PrototipMain2.webp'
                />
            <div style={{width: width > 600 ? width-17 : width, paddingTop: 10}}>
                <img src='https://amperino.com/imagesv2/Development/Prototip/prototip.webp' style={{width}} />
                <div style={imageInformationImg}>
                    <div style={{minHeight: 200, width: width < 800 ? '98%' : '50%', marginLeft: width < 800 ? '1%' : '25%', marginTop: 50, paddingBottom: 50}}>
                        <h2><FormattedMessage id="prototype.subHeader" /></h2>
                        <p style={{textAlign: 'justify'}}>
                            <FormattedMessage id="prototype.subHeader.info" />
                        </p>    
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Prototip;

