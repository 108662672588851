import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Discover from '../components/Discover';
import useWindowDimensions from '../components/window';

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { height, width } = useWindowDimensions();
  const wheelThreshold = 3; // Tekerleğin ne kadar döndürülmesi gerektiğini belirleyen eşik değeri
  const [wheelCounter, setWheelCounter] = useState(0); // Tekerlek hareket sayacı
  

  const handleChangeIndex = index => {
    setCurrentIndex(index);
  };

  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      setWheelCounter(prevCount => prevCount - 1);
    } else {
      setWheelCounter(prevCount => prevCount + 1);
    }

    // Eğer tekerlek sayacı eşik değerine ulaştıysa, indeksi güncelle 
    if (Math.abs(wheelCounter) >= wheelThreshold) {
      setCurrentIndex(prevIndex => 
        event.deltaY < 0
          ? Math.max(prevIndex - 1, 0)
          : Math.min(prevIndex + 1, slides.length - 1)
      );
      setWheelCounter(0);
    }
  };
  
  const slides = [
    {
      src: 'https://amperino.com/imagesv2/HomeCar1.webp',
      header: 'menu.car',
      comment: 'home.Car',
      discoverLink: 'car'
    },
    {
      src: 'https://amperino.com/imagesv2/HomeTecv3.webp',
      header: 'menu.technology',
      comment: 'home.Technology',
      discoverLink: 'technology'
    },
    {
      src: 'https://amperino.com/imagesv2/HomeDev.webp',
      header: 'menu.development',
      comment: 'home.Development',
      discoverLink: 'development'
    },
    {
      src: 'https://amperino.com/imagesv2/HomeCom1.webp',
      header: 'menu.company',
      comment: 'home.Company',
      discoverLink: 'company'
    },
  ];

  const DotNavigation = ({ slides, currentIndex, setCurrentIndex }) => {
    return (
      <div style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)' }}>
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            style={{
              display: 'block',
              height: '15px',
              width: '15px',
              margin: '10px 0',
              marginRight: width < 600 ? -10 : 0,
              borderRadius: '50%',
              backgroundColor: currentIndex === index ? '#fadc43' : 'grey',
              border: 'none',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.9)',
            }}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    );
  };
  return (
    <>
      <SwipeableViews
        axis="y"
        index={currentIndex}
        onChangeIndex={handleChangeIndex}
        onWheel={handleWheel}
        containerStyle={{height, width,}}
        style={{ margin:-16, overflow: 'hidden', height}}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            style={{
              height: height,
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              backgroundImage: `url(${slide.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <div style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.25)',
              zIndex: 1, 
            }}></div>
            <Discover header={slide.header} comment={slide.comment} discoverLink={slide.discoverLink} />
          </div>
        ))}
      </SwipeableViews>
      <DotNavigation slides={slides} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
    </>
  );
};

export default Home;
