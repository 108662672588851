import React from 'react'
import MenuLink from './MenuLink'
import styled from 'styled-components';
import useWindowDimensions from './window';
import { FormattedMessage } from 'react-intl';

function MenuBar() {
  
  const { width } = useWindowDimensions();

  const resWidth = width < 1200 ? '100%' : '90.7%' 
  const resMargin = width < 1200 ?  '0%' : '4.65%' 

  const MenuContainer = styled.nav`
  display: flex;
  flex-direction: row;
  position: absolute; 
  justify-content: center;
  align-items: center;
  background-color: white;
  width: ${resWidth};
  margin-left: ${resMargin};
  z-index: 21;
  height: 67px;
  & > a {
    margin-right: 1em;
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
`

  return (
    <div>
      {width > 600 ? <MenuContainer>
        <MenuLink link="/" name={<FormattedMessage id="menu.home" />} />
        <MenuLink link="/car" name={<FormattedMessage id="menu.car" />} />
        <MenuLink link="/technology" name={<FormattedMessage id="menu.technology" />} />
        <MenuLink link="/development" name={<FormattedMessage id="menu.development" />} />
        <MenuLink link="/company" name={<FormattedMessage id="menu.company" />} />
      </MenuContainer> :
      <div style={{marginTop: -67}}></div>}
    </div>
    
  );
}

export default MenuBar

