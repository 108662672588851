import React, { memo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import useWindowDimensions from './window';
import { FormattedMessage } from 'react-intl';


const buttonStyle = {
  background: 'rgba(0, 0, 0, 0.25)',
  color: '#fadc43',
  border: '1px solid #fadc43',
  borderRadius: 50,
  width: 180,
  height: 'auto',
  padding: '10px 20px',
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'center', // Yatay olarak ortala
  alignItems: 'center', // Dikey olarak ortala
  fontSize: '16px',
  margin: '20px 2px',
  transition: 'background-color 0.5s, color 0.5s',
  cursor: 'pointer',
  zIndex: 1
};


const hoverStyle = {
  backgroundColor: '#fff', 
  color: '#000', 
  border: '1px solid #fff',
  borderRadius: 50,
  width: 180,
  height: 'auto',
  padding: '10px 20px',
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'center', // Yatay olarak ortala
  alignItems: 'center', // Dikey olarak ortala
  fontSize: '16px',
  margin: '20px 2px',
  transition: 'background-color 0.5s, color 0.5s',
  cursor: 'pointer',
  zIndex: 1
};


const PulseDiv = styled.div`
  overflow: hidden;
  max-height: 150px;
  width: 300px;
  align-self: center;
  display: flex; 
  justify-content: center;
  margin-top: 250px;
`;
 
const Discover = memo(({ header, comment, discoverLink }) => {
  const { height, width } = useWindowDimensions();
  const [isHovered, setIsHovered] = React.useState(false);

  const finalStyle = isHovered ? hoverStyle: buttonStyle;

  return (
    <div>
      <div style={{color: 'white', display: 'flex', position: 'absolute' , width: '100%', height: '100%', alignSelf: 'center', justifyContent: 'center', zIndex: 1}}>
        <div style={{ width: width < 500 ? 300 : 450, height: '20%', alignSelf: 'center', textAlign: 'center', marginTop: width/height <1 ? null : 80, textShadow:'0 2px 4px rgba(0, 0, 0, 1)'}}>
          <div style={{fontSize: 30, marginTop: discoverLink == "technology" ? -20 : -40, color: '#fadc43'}}>
            <FormattedMessage id={header} />
          </div>
          <FormattedMessage id={comment} />
        </div>          
      </div>       
        
      <div style={{ width:'100%', height: '100%', alignSelf: 'center', textAlign: 'center', justifyContent:'center', position: 'absolute', display:'flex'}}>
        <PulseDiv>
          <Button 
            ghost 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)} 
            href={discoverLink == null ? "car": discoverLink} 
            size='large' 
            style={ finalStyle} 
            >
              <div style={{fontSize: 20}}>
                <FormattedMessage id="home.title.Discover" />
              </div>
          </Button>
        </PulseDiv>  
      </div> 
    </div>
  );
}, areEqual);

function areEqual(prevProps, nextProps) {
  return prevProps.comment === nextProps.comment && prevProps.discoverLink === nextProps.discoverLink;
}

export default Discover;
