import React from 'react'
import useWindowDimensions from '../components/window';
import NewsCard from '../components/NewsCard';

const newsData = [
  {
    Img: 'https://image.milimaj.com/i/milliyet/75/0x0/6555ff3c86b24759e0190621.jpg',
    Header: "Yerli otomobil Amperino görücüye çıktı! İşte fiyatı ve özellikleri",
    Information: "Türkiye’nin yeni elektrikli araç Amperino bugün görücüye çıktı. Peki özellikleri neler? Fiyatı ne kadar olacak?",
    Date: '16.11.2023',
    Link: 'https://www.haber7.com/otomobil/haber/3368140-yerli-otomobil-amperino-gorucuye-cikti-iste-fiyati-ve-ozellikleri',
  },
  {
    Img: 'https://www.cumhuriyet.com.tr/Archive/2023/11/17/133614262-amperino.jpg',
    Header: "Yerli elektrikli araba Amperino'nun son hali paylaşıldı",
    Information: "Görsellere baktığımızda Amperino'nun üç kişilik yaşam alanına sahip bir mikro mobilite aracı olduğunu görmekteyiz. Daha önceki çizimlerde keskin hatlarıyla minik bir Jimny havası veren araç yeni haliyle farklı bir kimliğe bürünmüş.",
    Date: '31.07.2023',
    Link: 'https://www.donanimhaber.com/yerli-elektrikli-araba-amperino-nun-son-hali-payla--166334',
  },
  {
    Img: 'https://ares.shiftdelete.net/2023/07/amperino-ozellikleri-tasarimi-satis-tarihi-3-1.jpg',
    Header: "Özel haber: En ucuz yerli elektrikli otomobilin satış tarihi ve tasarımı ortaya çıktı!",
    Information: "Öncelikle haberimizde yer alan görselleri ve satış tarihi konusundaki bilgileri Amperino’dan aldığımızı belirtelim. Peki yeni bir Togg ile mi karşı karşıyayız yoksa çok daha ulaşılabilir bir otomobil mi bizleri bekliyor? İşte detaylar…",
    Date: '31.07.2023',
    Link: 'https://shiftdelete.net/amperino-ozellikleri-tasarimi-satis-tarihi',
  },
  {
    Img: 'https://i2.milimaj.com/i/milliyet/75/869x477/64229d8c86b244607cad127f.jpg',
    Header: "Yerli otomobil TOGG'a kardeş geliyor! Tasarımı dikkat çekti",
    Information: "3 kişilik ilk elektrikli mikro otomobil olarak adlandırılan tasarım Yönetim Kurulu Başkanı Yakup Canseven tarafından paylaşıldı. Amperino'nun 150 kilometre yol gidip, kullanıcısına park sorunu yaşatmayacağına dikkat çeken...",
    Date: '28.03.2023',
    Link: 'https://www.milliyet.com.tr/ekonomi/yerli-otomobil-togga-kardes-geliyor-tasarimi-dikkat-cekti-6923615',
  },
  {
    Img: 'https://i4.hurimg.com/i/hurriyet/75/750x422/63c297444e3fe1185c7fe6d2.jpg',
    Header: 'Çevreci ruhtan doğan elektrikli otomobil: Amperino',
    Information: '“Dünyamız hızla kirleniyor ve bu kirlenmenin farkındalığında sanayiciler olarak bizlere çok büyük sorumluluklar düştüğünün bilincinde gelecek nesillerimiz için bir şeyler yapmamız gerektiğini düşündük ve harekete geçtik”',
    Date: '14.01.2023',
    Link: 'https://www.hurriyet.com.tr/yerel-haberler/bursa/cevreci-ruhtan-dogan-elektrikli-otomobil-amperino-42203863',
  },
  {
    Img: 'https://cdn.motor1.com/images/mgl/g4AMw7/s1/amperino.webp',
    Header: "Yerli EV üreticisi Amperino InsideEVs Türkiye'ye konuştu!",
    Information: 'InsideEVs Türkiye olarak aldıkları yatırım ile birlikte oldukça ses getiren yerli otomobil üreticilerimizden Amperino ile gerçekleştirdiğimiz röportajımızla karşınızdayız. Şirketin Kurucu Ortakları arasında yer alan Osman Mücteba Bişkinler...',
    Date: '14.10.2022',
    Link: 'https://insideevs.com.tr/features/616158/yerli-ev-ureticisi-amperino-roportaji/',
  },
  {
    Img: 'https://cdn1.ntv.com.tr/gorsel/UyE15OnADkSXqqFAd23uIg.jpg?width=660&mode=both&scale=both',
    Header: "Amperino'ya bakanlıktan destek: Elektrikli otomobil 2025'te yollarda olacak",
    Information: 'Amperino Yönetim Kurulu Başkanı Yakup Canseven, hedeflerinin elektrikli otomobili 2024 Cenevre Otomobil fuarında sergilemek ve 2025 yılında da ilk aracı banttan indirmek olduğunu söyledi.',
    Date: '08.09.2022',
    Link: 'https://www.ntv.com.tr/otomobil/amperinoya-bakanliktan-destek-elektrikli-otomobil-2025te-yollarda-olacak,JYpi_xjuo0KTGHy9g8lmWQ',
  },
  {
    Img: 'https://www.normhaber.com/wp-content/uploads/2021/12/oto-800x450-1.jpg',
    Header: 'Bursa’dan büyük hamle! Şehir içi elektrikli otomobil nerede üretilecek?',
    Information: '“Çalışmalar yeni değil… İnegöl’de Erhun Metal, Lenova Mobilya, Yol Living Mobilya, Saloni ortaklığıyla oluşturulan Amperino Otomotiv iki yıl önce projeye başladı. Hedefte..."',
    Date: '16.12.2021',
    Link: 'https://www.normhaber.com/bursadan-buyuk-hamle-sehir-ici-elektrikli-otomobil-nerede-uretilecek-135423',
  },
  {
    Img: 'https://www.yildirimgazetesi.com/images/haberler/2021/07/inegolun_otomobili_iste_boyle_olacak_h62418_ac0b6.jpg',
    Header: 'İnegöl’ün otomobili işte böyle olacak',
    Information: 'Saloni Mobilya ve Erhun Metal iştirakiyle İnegöl’de üretilecek ilk yerli şehir içi elektrikli otomobilin 3D görüntüleri yayınlandı. 4 farklı tasarım İnegöl’de oylamaya sunulacak. En beğenilen modelin seri üretimine başlanacak.',
    Date: '08.07.2021',
    Link: 'https://www.yildirimgazetesi.com/gundem/inegolun-otomobili-iste-boyle-olacak-h62418.html',
  },
];

function News() {
    const { width } = useWindowDimensions();

    const Container={
      marginTop: 20, 
      marginLeft: width > 3000 ? '27.5%' : null,
      display: 'grid', 
      gridTemplateColumns: `repeat(${Math.round(width/530)}, 1fr)`, 
      gap: '12px', 
    }

    
  return (
    <div style={{boxShadow: "10px 50px 40px 100px #ebf3ff", backgroundColor: '#ebf3ff', display: 'flex', justifyContent: 'center'}}>
            <div style={Container}>
            {newsData.map((news, index) => (
              <NewsCard
                key={index}
                Img={news.Img}
                Header={news.Header}
                Information={news.Information}
                Date={news.Date}
                Link={news.Link}
              />
            ))}
            </div>     
    </div>
  )
}

export default News;

