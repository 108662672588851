import React from 'react'
import useWindowDimensions from './window';



function DetailHeadCom({ header, headerInfo, mainImg, subImg }) {
    const { height, width } = useWindowDimensions();

    const mainContainer = {
        overflow: 'hidden',
        width: width < 1300 ? '100%' : '90%',
        paddingLeft: width < 1300 ? '0%' : '3%',
        paddingRight: width < 1300 ? '0%' : '7%',
        height: 400,
        zIndex: 20,
        display: 'flex',
        position: 'relative',
        marginTop: 67,
        backgroundColor: '#EEEDEB',
        alignItems: 'center',
    }

    const technologyStyle = {
        position: 'absolute',
        color: 'white',
        fontSize: 24,
        width: '100%',
        height: '100%',
        textShadow: "-2px 1px 5px rgba(0, 0, 0, 1)",
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: width < 1300 ? 'center' : 'start',
        marginLeft: width < 1300 ? '0%' : '6%',
        flexDirection: 'column'
    }

    return (
        <div style={mainContainer}>
            <p style={technologyStyle}>
                <p style={{ justifyContent: 'center', textAlign: 'center', textJustify: 'center', width: 380, }}>
                    {header}
                </p>
                <p style={{ fontSize: 16, width: 380, justifySelf: 'center' }}>
                    {headerInfo}
                </p>
            </p>
            <div style={{ display: 'flex', position: 'absolute', justifyContent: 'center', width: '100%', height: '100%', zIndex: -10 }}>
                <img src={mainImg} style={{ objectFit: 'cover', height: '100%', display: 'flex', width: width < 1300 ? null : '50%' }} />
            </div>
            <img src={subImg} style={{ height: '70%', display: width < 1300 ? 'none' : 'flex', marginLeft: 'auto' }} />
        </div>
    )
}

export default DetailHeadCom