import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import ReactPlayer from 'react-player';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function Cad() {

    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});


    const imageInformationReverse = {
        width: '70%',
        marginLeft: '10%',
        marginTop: width > 1000 ? 100 : 10,
        display: width > 1000 ? 'flex' : 'table',
        paddingBottom: 20,
        flexDirection: 'row',
        alignItems: 'center'
    }

    const inlineDiv = {
        backgroundColor: 'white',
        padding: 50,
        marginTop: width < 1000 ? -5 : null,
        textAlign: 'justify'
    }

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="cad.header" />}
                    headerInfo={<FormattedMessage id="cad.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/CadMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/CadMain2.webp'
                />
                <div style={imageInformationReverse}>
                    <div style={{ marginLeft: width < 500 ? -39 : -54, }}>
                        <ReactPlayer
                            playing={true} loop={true} controls={true} muted={true}
                            width={width < 500 ? width : width < 1000 ? width - 60 : width / 3}
                            height={width < 500 ? width / 1.77 : width < 1000 ? width / 2 - 6 : width / 5 - 24}
                            url='https://www.youtube.com/watch?v=2OqBl9NC_qY'
                        />
                    </div>
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? { width: '130%' } : { width: '110%', marginLeft: '-17%', textAlign: 'justify' }}>
                            <h2><FormattedMessage id="cad.subHeader" /></h2>
                            <p>
                                <FormattedMessage id="cad.subHeader.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Development/Cad/cad.webp' style={{ maxHeight: width < 500 ? width / 1.77 : width < 1000 ? width / 2 - 6 : width / 5 - 24, marginLeft: '-3%' }} />
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? { width: '130%', marginLeft: '12%' } : { width: '150%', marginLeft: '-20%' }}>
                            <FormattedMessage id="cad.subHeader.info2" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cad;

