import React from 'react';
import { NavLink } from "react-router-dom";
//import { motion } from "framer-motion"

function BurgerSubLink({name, link, color}) {

  let activeStyle = {
    textDecoration: "none",
    color: 'white',
    fontSize: 20,    
    fontWeight: 'lighter'
  };

  const pasifStyles = {
    color: 'gray' ,
    textDecoration:'none',
    fontSize: 20,
    fontWeight: 'lighter'
  };

  const stylesHover = {
    scale: 1.1, 
  };
  
  return (
    //<motion.a whileHover={stylesHover} whileTap={{ scale: 0.9 }} >
    <a>
      <NavLink style={({ isActive }) => isActive ? activeStyle : pasifStyles } to={link} >{name}</NavLink>
    </a>
    //</motion.a>
  )
}

export default BurgerSubLink