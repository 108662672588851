import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import ReactPlayer from 'react-player';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function Cae() {
    
    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});

    const imageInformationReverse = {
        width: '80%', 
        marginLeft:'10%', 
        marginTop: 20,
        display: width > 1000 ? 'flex' : 'table', 
        paddingBottom: 50,
        flexDirection: width > 1000 ? 'row': null,
        alignItems: 'center'
    }
    const inlineDiv = {
        backgroundColor: 'white',  
        padding: 50,
        marginTop: width < 1000 ? -5 : null,
        marginLeft: width < 1000 ? '-10%' : null,
        textAlign: width < 500 ? 'justify' : 'justify'
    }

    
    const imageInformationImg = {
        backgroundColor: 'white', 
        textAlign: 'center', 
        width: '80%', 
        marginLeft: '10%', 
        display: 'flex', 
        position: 'relative', 
        marginTop : 60
    }

  return (
    <div>
        <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="cae.header" />}
                    headerInfo={<FormattedMessage id="cae.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/CaeMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/CaeMain2.webp'
                />
            <div style={{width: width-17, paddingTop: 10}}>
                <div style={imageInformationImg}>
                    <div style={{minHeight: 200, width: width > 1000 ? '50%' : '100%', marginLeft: width > 1000 ? '25%' : '10%', marginTop: 50, paddingBottom: 50, textAlign: 'justify'}}>
                        <h2  style={{textAlign: 'left'}}><FormattedMessage id="cae.subHeader1" /></h2>
                        <p>
                            <FormattedMessage id="cae.subHeader1.info" />
                        </p>    
                    </div>
                </div>
            </div>
            <div style={imageInformationReverse}>
                <div style={{ marginLeft: width <500 ? -39 : -54 }} >
                    <ReactPlayer 
                    playing={true} loop={true} controls={true} muted={true} playbackRate={0.75}
                    width={width <500 ? width : width <600 ? width-17 : width <2000 ? 595 : width/3} 
                    height={width <500 ? width/1.77 : width <600 ? width/1.83 : width <2000 ? 335 : width/5-47} 
                        url='https://www.youtube.com/watch?v=RFO17jzQFcQ'
                    />
                </div>
                <div style={inlineDiv}>
                    <div style={width > 1000 ? {marginTop: '10%'} : null}>
                        <h2 style={{textAlign: 'left'}}><FormattedMessage id="cae.subHeader2" /></h2>
                        <FormattedMessage id="cae.subHeader2.info" />
                    </div> 
                </div>
            </div>
            <div style={imageInformationReverse}>
                <img src='https://amperino.com/imagesv2/Development/CAE/steering.webp' style={{maxHeight: width <2000 ? 300 : width/5.6}}  />
                <div style={inlineDiv}>
                    <div  style={{width: width > 1000 ? '100%' : '120%', marginLeft: width > 1000 ? '0%' : '-5%'}}>
                        <h2><FormattedMessage id="cae.subHeader3" /></h2>
                        <FormattedMessage id="cae.subHeader3.info1" />
                        <br/> <br/>
                        <FormattedMessage id="cae.subHeader3.info2" />
                    </div> 
                </div>
            </div>
            <div style={imageInformationReverse}>
                <div style={{marginLeft: width <500 ? -39 : -54 }} >
                    <ReactPlayer 
                    playing={true} loop={true} controls={true} muted={true} playbackRate={0.75}
                    width={width <500 ? width : width <600 ? width-17 : width <2000 ? 595 : width/3} 
                    height={width <500 ? width/1.77 : width <600 ? width/1.83 : width <2000 ? 335 : width/5-47} 
                        url='https://www.youtube.com/watch?v=cDh63-kqRz8'
                    />
                </div>
                <div style={inlineDiv}>
                    <div style={width > 1000 ? {marginTop: '10%'} : {width: width > 1000 ? '50%' : '110%', marginLeft: width > 1000 ? '25%' : '-5%'}}>
                    <h2><FormattedMessage id="cae.subHeader4" /></h2>
                    <FormattedMessage id="cae.subHeader4.info" />
                    </div> 
                </div>
            </div>
            <div style={imageInformationReverse}>
                <div style={{marginLeft:  width <500 ? -39 : -54 }} >
                    <ReactPlayer 
                    playing={true} loop={true} controls={true} muted={true} playbackRate={0.75}
                    width={width <500 ? width : width <600 ? width-17 : width <2000 ? 595 : width/3} 
                    height={width <500 ? width/1.77 : width <600 ? width/1.83 : width <2000 ? 335 : width/5-47} 
                        url='https://www.youtube.com/watch?v=xtIjQTz641s'
                    />
                </div>
                <div style={inlineDiv}>
                    <div style={width > 1000 ? {marginTop: '10%'} : {width: width > 1000 ? '50%' : '110%', marginLeft: width > 1000 ? '25%' : '-5%'}}>
                        <h2><FormattedMessage id="cae.subHeader5" />  </h2>
                        <FormattedMessage id="cae.subHeader5.info" />
                    </div> 
                </div>
            </div>
            <div style={imageInformationReverse}>
                <div style={{marginLeft: width <500 ? -39 : -54 }} >
                    <ReactPlayer playing={true} loop={true} controls={true} 
                    width={width <500 ? width : width <600 ? width-17 : width <2000 ? 595 : width/3} 
                    height={width <500 ? width/1.77 : width <600 ? width/1.83 : width <2000 ? 325 : width/5-70} 
                    muted={true} playbackRate={0.75}
                        url='https://www.youtube.com/watch?v=ux-_ju9RrDw'
                    />
                </div>
                <div style={inlineDiv}>
                    <div style={width > 1000 ? {marginTop: '10%'} : {width: width > 1000 ? '50%' : '110%', marginLeft: width > 1000 ? '25%' : '-5%'}}>
                        <h2><FormattedMessage id="cae.subHeader6" /></h2>
                        <FormattedMessage id="cae.subHeader6.info" />
                    </div> 
                </div>
            </div>
            <div style={imageInformationReverse}>
                <img src='https://amperino.com/imagesv2/Development/CAE/metrology.webp' style={{backgroundColor: ' rgba(230,230,230,1)', maxHeight: width <2000 ? 300 : width/3}}  />
                <div style={inlineDiv}>
                    <div style={width > 1000 ? {marginTop: '5%'} : {width: width > 1000 ? '50%' : '110%', marginLeft: width > 1000 ? '25%' : '-5%'}}>
                    <h2><FormattedMessage id="cae.subHeader7" /></h2>
                    <FormattedMessage id="cae.subHeader7.info" />
                    </div> 
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Cae;

