import React, { useState } from 'react'
import MenuBar from '../components/MenuBar';
import useWindowDimensions from '../components/window';
import styled from 'styled-components';
import Footer from '../components/Footer';
import CareerCollapse from '../components/CareerCollapse';
import News from './News';
import { FormattedMessage } from 'react-intl';
import Contact from '../components/Contact';
import { Accordion } from '../components/accordion/Accordion';
import CompanyGallery from '../components/CompanyGallery';

function Company() {
    const [selected, setSelected] = useState("about");
    const { width } = useWindowDimensions();

    const headHeight = width > 600 ? 600 : 400
    
    const HeadImage = styled.div`
    background-size: cover;
    background-image: url(https://amperino.com/GoGreen/render4.webp);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    /* add tint */
    background-color: rgba(0,0,0,0.40);
    background-blend-mode: overlay;
    width: ${width}px;
    height: ${headHeight}px;
    overflow: hidden;
    @media (min-width: 2000px) {
        background-size: cover;
        background-image: url(https://amperino.com/GoGreen/render4.webp);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        /* add tint */
        background-color: rgba(0,0,0,0.50);
        background-blend-mode: overlay;
        width: ${width}px;
        height: ${width/2}px;
        overflow: hidden;
    }
    `;
    
    const MainContainer = styled.div`
    overflow: hidden;
    width: 100%;
    z-index: 20;
    display: flex;
    position: relative;
    margin-Top: 130px;
    `;    

    const technologyStyle={
        position: 'absolute',
        color: 'white',
        top: '50%',
        fontSize: 24,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textShadow: "-2px 1px 5px rgba(0, 0, 0, 1)"
    }

    const explanationP = {
        zIndex: '21',
        width: '100%',
        display: 'flex', 
        position: 'absolute', 
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center', 
        marginBottom: 50,
        marginTop: -84,
    }

    
  let activeStyle = {
    color:'black',
    backgroundColor: 'white',
    border: "0px",
    fontSize: 18,    
    cursor: "pointer",
    paddingLeft: localStorage.getItem("lang") === "tr-TR" ? width < 600 ? 22 : 30 : 30,
    paddingRight: localStorage.getItem("lang") === "tr-TR" ? width < 600 ? 22 : 30 : 30,
    marginLeft: 2,
    marginRight: 2,
    height: 34
  };

  const pasifStyles = {
    textDecoration:'none',
    color: 'white',
    backgroundColor: '#535454',
    fontSize: 18,
    border: "0px",
    cursor: "pointer",
    paddingLeft: localStorage.getItem("lang") === "tr-TR" ? width < 600 ? 22 : 30 : 30,
    paddingRight: localStorage.getItem("lang") === "tr-TR" ? width < 600 ? 22 : 30 : 30,
    marginLeft: 2,
    marginRight: 2,
    height: 34
  };
  
  const menubarStyle = {
    position: 'relative', 
    width: width > 499 ? width-18 : width, 
    marginLeft:  width > 499 ? -15 : -16
}
const mainImg = {
    position: 'relative', 
    width: width > 499 ? width-17 : width, 
    marginLeft: -16,
    paddingBottom: 50
}
    
  return (
    <div style={{boxShadow: "10px 0px 10px 100px #ebf3ff", backgroundColor: '#ebf3ff', letterSpacing: 1}}>
        <div style={menubarStyle}>
            <MenuBar />
        </div>
        <div style={mainImg}>
            <MainContainer>
                <HeadImage style={{marginTop: 67}} />
                <p style={technologyStyle}><FormattedMessage id="company.header" /></p>
            </MainContainer>
        </div>
        <div style={{boxShadow: "0px 56px 48px rgba(0, 37, 74, 0.102)", backgroundColor: '#fff', width: width < 600 ? '110.5%' : '90.7%', position: 'relative', marginLeft: width < 600 ? '-6%' : '4.65%', marginBottom: 109, paddingBottom: 50}}>
            
            <div style={explanationP}>
                <button style={selected === "about" ? activeStyle : pasifStyles} onClick={() => setSelected("about")} ><FormattedMessage id="company.subHeader1" /></button>
                <button style={selected === "career" ? activeStyle : pasifStyles} onClick={() => setSelected("career")} ><FormattedMessage id="company.subHeader2" /></button>
                <button style={selected === "news" ? activeStyle : pasifStyles} onClick={() => setSelected("news")} ><FormattedMessage id="company.subHeader3" /></button>
            </div>
            <div style={{position: 'flex', width: '90.7%'}}>
                {
                    selected === "about" ?
                    <div style={{width: '90%', marginLeft: '10%', paddingTop: 107, marginBottom: 30, justifyContent: 'center', textAlign: 'justify'}}>
                        <img src={'https://amperino.com/imagesv2/Company/team.webp'} />
                        <div>
                            <h1>GoGreen Bursa Tüyap</h1>
                            <CompanyGallery />
                            <a 
                                href='https://amperino.com/DataFiles/GoGreenFuar.pdf' 
                                target='_blank'
                                style={{
                                    textDecoration: 'none', 
                                    color: 'black', 
                                    fontWeight: 'bold', 
                                    justifyContent: 'end', 
                                    margin: 10,
                                    display: 'flex'
                                    }} >
                                        <FormattedMessage id="company.katalog" />
                            </a>
                        </div>
                        <p style={{marginTop: 50, marginBottom: 50}}>
                        <FormattedMessage id="company.teamInfo1" />
                        <br /><br />
                        <FormattedMessage id="company.teamInfo2" />
                        </p>
                        <Accordion />
                    </div> :

                    // selected === "team" ?
                    // <div style={{width: '90%', marginLeft: '10%', paddingTop: 107, marginBottom: 300, justifyContent: 'center', textAlign: 'center', fontSize: 24}} >
                    //     <img src={Team} />
                    //     <p>Contributors to Amperino</p>
                    // </div> :
                    
                    selected === "career" ?
                    <div style={{width: '90%', marginLeft: '10%', paddingTop: 107, marginBottom: 100, justifyContent: 'center', textAlign: 'center', fontSize: 24}} >
                        <CareerCollapse />
                        <Contact />
                    </div> : null
                }
                
            </div>  
            {selected === "news" ?
                <News /> : null
            }      
        </div>
        <Footer />
    </div>
  )
}

export default Company;