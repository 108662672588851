import React, { useRef, useState } from "react";
import "./css/Video.css";
import Tilt from 'react-parallax-tilt';
import playIcon from '../assets/icons/play.png';
import useWindowDimensions from "./window";

function Video({ src }) {
  const [playing, setPlaying] = useState(false);
  const { height, width } = useWindowDimensions();

  const videoRef = useRef(null);
  const handleVideoPress = () => {
    if (playing) {
      setPlaying(false);
      videoRef.current.pause();
    } else {
      videoRef.current.play();
      setPlaying((play) => !play);
    }
  };

  return (
    <Tilt scale={width> 500 ? 1.1 : false} tiltEnable={width> 500 ? true : false} transitionSpeed={2500} className="video__container">
      <img src={playIcon} style={{width: 60, height: 60, display: !playing ? "flex" : "none", position: 'absolute', marginLeft: '42%', marginTop: '92%'}}/>
      <video
        className="video__player"
        onClick={handleVideoPress}
        loop
        ref={videoRef}
        src={src}
        //controls
      />
    </Tilt>
  );
}

export default Video;

