import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import ReactPlayer from 'react-player'
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function Sketch() {

    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});

    const inlineDiv = {
        backgroundColor: 'white',
        padding: 50,
        textAlign: 'justify',
        marginTop: width < 1000 ? -5 : null
    }

    const imageInformationImg = {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '80%',
        marginLeft: '10%',
        display: 'flex',
        position: 'relative',
        marginTop: width > 1000 ? -200 : -50
    }
    
    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="sketch.header" />}
                    headerInfo={<FormattedMessage id="sketch.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/CizimMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/CizimMain2.webp'
                />
                <div style={{ width: width > 600 ? width - 17 : width, paddingTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Development/Sketch/sketch.webp' style={{ width: width }} />
                    <div style={imageInformationImg}>
                        <div style={{ minHeight: 200, width: width < 800 ? '98%' : '50%', marginLeft: width < 800 ? '1%' : '25%', marginTop: 50, paddingBottom: 50 }}>
                            <h2><FormattedMessage id="sketch.subHeader" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="sketch.subHeader.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformation}>
                    <div style={{ marginLeft: width < 500 ? -39 : -54, display: 'flex', justifyContent: 'center' }}>
                        <ReactPlayer
                            playing={true} loop={true} controls={true} muted={true} playbackRate={1}
                            width={width < 500 ? width : width < 600 ? width - 17 : width < 2000 ? 595 : width / 3}
                            height={width < 500 ? width / 1.78 : width < 600 ? width / 1.83 : width < 2000 ? 335 : width / 5 - 47}
                            url='https://www.youtube.com/watch?v=Bg422ZfdQaw'
                        />
                    </div>
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? { marginTop: '5%' } : { width: '120%', marginLeft: '-10%' }}>
                            <FormattedMessage id="sketch.subHeader.info2" />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }} >
                    <ReactPlayer
                        playing={true} loop={true} controls={true} muted={true}
                        width={width < 500 ? width : width < 1200 ? width - 17 : 1280}
                        height={width < 500 ? width / 1.77 : width < 1200 ? width / 1.83 : 720}
                        url='https://www.youtube.com/watch?v=NlPYNZPAfWg'
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default React.memo(Sketch);

