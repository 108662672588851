import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import { getStyles } from '../GetStyles';
import DetailHeadCom from '../../components/DetailHeadCom';


function Vr() {

    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});

    const imageInformationReverse = {
        width: '80%',
        marginLeft: '10%',
        marginTop: 20,
        textAlign: 'center',
        display: width > 1200 ? 'flex' : 'table',
        paddingBottom: 50,
        flexDirection: width > 1000 ? 'row-reverse' : null,
        alignItems: 'center'
    }
    const inlineDiv = {
        width: '80%',
        marginLeft: '-5%',
        backgroundColor: 'white',
        padding: 50,
        textAlign: 'justify',
        marginTop: width < 1000 ? -5 : null
    }

    const imageInformationImg = {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '80%',
        marginLeft: '10%',
        display: 'flex',
        position: 'relative',
        marginTop: width > 1000 ? -200 : -50
    }

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="vr.header" />}
                    headerInfo={<FormattedMessage id="vr.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/SanalGerMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/SanalGerMain2.webp'
                />
                <div style={{ width: width < 500 ? width : width - 17, paddingTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Development/VR/VRv2.webp' style={{ width: width < 500 ? width : width - 17 }} />
                    <div style={imageInformationImg}>
                        <div style={{ minHeight: 200, width: width < 800 ? '80%' : '50%', marginLeft: width < 800 ? '10%' : '25%', marginTop: 50, paddingBottom: 50, fontSize: 18 }}>
                            <h2><FormattedMessage id="vr.subHeader" /></h2> 
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="vr.subHeader.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Development/VR/VR2v2.webp' style={{ backgroundColor: ' rgba(230,230,230,1)', height: width > 1200 ? width / 4 : null }} />
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? { fontSize: 18 } : null}>
                            <FormattedMessage id="vr.subHeader.info2" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Vr;
