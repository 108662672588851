import React from 'react'
import MenuBar from '../components/MenuBar';
import useWindowDimensions from '../components/window';
import styled from 'styled-components';
import Footer from '../components/Footer';
import LineLink from '../components/LineLink';
import { FormattedMessage } from 'react-intl';
import Video from '../components/Video';


function Development() {
    
    const { height, width } = useWindowDimensions();
    
    const lineItem = [        
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/CizimMain.webp', 'development.subHeader1', '/development/sketch', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/CizimMain2.webp'], 
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/KilMain.webp', 'development.subHeader2', '/development/clay', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/KilMain2.webp'], 
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/TaramaMain.webp', 'development.subHeader3', '/development/scan', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/TaramaMain2.webp'], 
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/CadMain.webp', 'development.subHeader4', '/development/cad', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/CadMain2.webp'], 
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/SanalGerMain.webp', 'development.subHeader5', '/development/vr', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/SanalGerMain2.webp'],
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/CaeMain.webp', 'development.subHeader6', '/development/cae', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/CaeMain2.webp'], 
        ['https://amperino.com/imagesv2/Development/DevelopmentPageImages/PrototipMain.webp', 'development.subHeader7', '/development/prototype', 'https://amperino.com/imagesv2/Development/DevelopmentPageImages/PrototipMain2.webp'], 
        // [batteryImg, 'TEST', '/development/test'], 
    ]

    const headHeight = width > 600 ? 550 : 400

    const HeadImage = styled.div`
    background-size: cover;
    background-image: url('https://amperino.com/GoGreen/render1.webp');
    background-position: 100% 80%;
    background-repeat: no-repeat;
    /* add tint */
    background-color: rgba(0,0,0,0.40);
    background-blend-mode: overlay;
    width: ${width}px;
    height: ${headHeight}px;
    overflow: hidden;
    @media (min-width: 1000px) {
        background-size: cover;
        background-image: url(https://amperino.com/GoGreen/render1.webp);
        background-position: 50% 80%;
        background-repeat: no-repeat;
        /* add tint */
        background-color: rgba(0,0,0,0.50);
        background-blend-mode: overlay;
        width: ${width}px;
        height: ${headHeight+200}px;
        overflow: hidden;
    }
    @media (min-width: 2000px) {
        background-size: cover;
        background-image: url(https://amperino.com/GoGreen/render1.webp);
        background-position: 50% 80%;
        background-repeat: no-repeat;
        /* add tint */
        background-color: rgba(0,0,0,0.50);
        background-blend-mode: overlay;
        width: ${width}px;
        height: ${width/2}px;
        overflow: hidden;
    }
    `;
    
    

    const MainContainer = styled.div`
    overflow: hidden;
    width: 100%;
    z-index: 20;
    display: flex;
    position: relative;
    margin-Top: 130px;
    `;    

    const technologyStyle={
        position: 'absolute',
        color: 'white',
        top: '50%',
        fontSize: 24,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textShadow: "-2px 1px 5px rgba(0, 0, 0, 1)",
        textAlign: 'center'
    }

    const explanationP = {
        padding:  width > 700 ? 150 : 40, 
        textAlign: 'justify',
    }

    const menubarStyle = {
        position: 'relative', 
        width: width > 499 ? width-18 : width, 
        marginLeft:  width > 499 ? -15 : -16
    }
    const mainImg = {
        position: 'relative', 
        width: width > 499 ? width-17 : width, 
        marginLeft: -16,
        paddingBottom: 50
    }
    const videosDiv = {
        display: width > 900 ? 'flex' : 'table', 
        width:  width > 900 ? '80%' : '99%', 
        marginLeft: width > 900 ? '10%' : null,
    }
    const videosDivReverse = {
        ...videosDiv,
        marginTop: width > 1750 ? -250  : null
    }

  return (
    <div style={{letterSpacing: 1}}>
        <div style={menubarStyle}>
            <MenuBar />
        </div>
        <div style={mainImg}>
            <MainContainer>
                <HeadImage style={{marginTop: 30}} />
                <p style={technologyStyle}><FormattedMessage id="development.header" /></p>                
            </MainContainer>
            <div style={explanationP}>
                <p style={{fontWeight: 'bold', fontSize: 18}}><FormattedMessage id="development.exp.head" /></p>
                <FormattedMessage id="development.exp.info" />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems:"center", width: '99%'}}>
            <div style={{marginBottom: 20}}>
                <div style={videosDiv}>
                    <Video src={"https://amperino.com/videos/CuneytShorts.mp4"} />
                    <div style={{padding: 40, display: width < 900 ? 'none' : 'flex', marginRight: width > 1000 ? width/5 : null}}><FormattedMessage id="development.CuneytShorts" /></div>
                </div>
                <div style={videosDivReverse}>
                    <div style={{padding: 40, display: width < 900 ? 'none' : 'flex', marginLeft: width > 1000 ? width/5 : null, textAlign: 'end'}}><FormattedMessage id="development.NickShorts" /></div>
                    <Video src={"https://amperino.com/videos/NickShorts.mp4"} />
                </div>
            </div>
            </div>
            {lineItem.map((index) => 
                <LineLink midImg={index[0]} title={<FormattedMessage id={index[1]} />} mainTitle={<FormattedMessage id="menu.development" />} link={index[2]} endImg={index[3]} />
            )}
        </div>
        <Footer />
    </div>
  )
}

export default Development;

