const messages = {
	"tr-TR": {
		//Home Page
		"home.title.Car": "Araç",
		"home.title.Technology": "Teknoloji",
		"home.title.Development": "Geliştirmeler",
		"home.title.Company": "Şirket",
		"home.title.Discover": "Keşfet",

		"home.Car": "Amperino elektrikli araba, mikro arabalar arasından sıyrılan üç kişilik tasarımı ve geniş bagaj kapasitesi ile yenilikçi bir çözüm sunuyor. Şık dış tasarım, çağdaş teknolojiyle birleşerek eşsiz tarz ve işlevsellik sunan teknolojik olarak gelişmiş bir araba sunar.",
		"home.Technology": "Amperino olarak, yenilikçi batarya tasarımı ve çevre dostu malzemelerle sürdürülebilirliği hedefliyoruz. Kendi bünyemizdeki geliştirdiğimiz yazılım ve platformlar, en iyi performans ve bağlantılı bir sürüş deneyimi için teknolojinin tam kontrolünü sağlar.",
		"home.Development": "Geliştirme sürecimiz, yüksek kaliteli araçlar için çağdaş teknolojiyi geleneksel tasarım teknikleriyle harmanlar. Ekibimiz, CAD, CFD, CAE, kil modelleme, tarama teknolojisi ve yaratıcı tasarımın bir kombinasyonunu kullanarak sanal prototipleri hayata geçirir ve performanslarını optimize eder.",
		"home.Company": "Çalışanların bilgi ve uzmanlıklarını paylaşan uzman takım üyelerine erişimlerinin olduğu dinamik ve destekleyici bir çalışma ortamı, ofis kültürü, işbirliği, arkadaşlık ve problemleri çözmeye odaklanır ve topluma olumlu bir etki yapma ve yaşam kalitesini iyileştirme odaklıdır.",
		
		//Car Page
		"car.explanation": "TEKNOLOJİDE LÜKS, GELECEK İÇİN TEKNOLOJİ",
		"car.information1": "Doğa dostu",
		"car.information1.1": "%100 Elektrikli araba",
		"car.information2": "1/ Menzil",
		"car.information3": "1/Şarj Süresi",
		"car.information3.1": "Evde 240 dk",
		"car.information3.2": "İstasyonda 80 dk",
		"car.information4": "Maksimum hız",
		"car.information5": "90km/s",


		"car.notification": "Geleceğe dinamik bir bakış açısı",

		"car.details": "Detaylar",
		"car.info1": "Uzunluk: 3159 mm, Genişlik: 1500 mm, Yükseklik: 1450 mm",
		"car.info2": "Sınıfında en iyi bagaj hacmi (370 lt)",
		"car.info3": "Boş ağırlık (650 Kg)",
		"car.info4": "3 kişilik",
		"car.info5": "Yere yakın ağırlık merkeziyle iyi bir yol tutuşu.",
		"car.info6": "Dengeli ağırlık merkezi",
		"car.info7": "16 yaş ve üzeri A2 sürücü ehliyeti için uygundur.",
		"car.info8": "Sınıfındaki diğer araçlarda olmayan, bacak mesafesi ve oturum konforu",
	
		//Car Opacity
		"opacity.discover": "AMPERINO'NUN DETAYLARINI KEŞFEDİN",
		"opacity.composite1": "KOMPOZİT GÖVDE",
		"opacity.composite2": "Düşük yoğunluk",
		"opacity.composite3": " Cam fiber ile aynı mukavemete sahip",
		"opacity.composite4": "Titreşim sönümleme",
		"opacity.composite5": "Camdan iki kat, karbondan üç kat daha fazla emici.",
		"opacity.composite6": "(25°C'de 1 Hz frekansta marul epoksi kompozitin sonucuna dayalı)",
		"opacity.chassis1": "ŞASİ",
		"opacity.chassis2": "Ön ve arka çarpışma yönetim sistemi",
		"opacity.chassis3": "Crumble Zone - Sürücü ve yolcular için yüksek koruma",
		"opacity.chassis4": "Özel tasarlanmış ve Ekstrüzyonlu Yüksek Mukavemetli Alüminyum Alaşımları",
		"opacity.chassis5": "Düşük ağırlık merkezi - Yüksek Araç Dinamikleri ve Yol Tutuşu",
		"opacity.chassis6": "Bağımsız Ön Süspansiyon - Yarı bağımsız Arka Süspansiyon",
		"opacity.battery1": "BATARYA PAKETİ",
		"opacity.battery2": "Hücre seviyesi sigortalanması",
		"opacity.battery3": "Yangın koruma sistemi",
		"opacity.battery4": "Yapısal Batarya Paketi",
		"opacity.battery5": "Batarya Yönetim Sistemi",
		"opacity.battery6": "Daldırma Soğutma ve Isıtma",
		"opacity.battery7": "3 Fazlı ve Mono faz şarj seçeneği",
		"opacity.battery8": "Hücre seviyesi voltaj ve batarya paketi seviyesi akım ölçümü",


		"opacity.technology1": "SÜRÜŞ TEKNOLOJİSİ",
		"opacity.technology2": "Amperino Dijital Araç Platformu ve tekerlekli motorlar, Amperino kullanıcılarının gelişmiş sürüş özelliklerinin keyfini çıkarmasını sağlar.",
		"opacity.technology3": "OTA güncellemeleriyle her zaman geliştiriliyor!",

		//Technology Page
		"technology.header": "TEKNOLOJİ",
		"technology.exp.head": "Yenilikleri Öncülük Ediyoruz",
		"technology.exp.info": `Amperino, sürdürülebilir bir gelecek yaratmaya adanmıştır ve bunu teknolojisi aracılığıyla gerçekleştirir. 
								Yenilikçi batarya paketi tasarımı ve optimize edilmiş paketleme ile verimliliği ve menzili artıran teknolojisiyle öne çıkar. 
								Dış yüzey, çevre dostu keten elyafı kullanarak geleneksel malzemelerin yerini alır. Ayrıca, tüm donanım ve yazılım kendi içlerinde tasarlanır ve 
								geliştirilir, böylece araçları hareket ettiren teknolojide tam bir kontrol sağlanır. Mobil uygulamaları, kullanıcıların araçlarıyla ilgili performans 
								bilgilerine ve daha fazlasına erişmelerine olanak tanır. Kaykay şasi, yere yakın ağırlık merkezi sağlayarak yol tutuşunu artırır ve ferah bir iç mekan 
								oluşturur. Yenilik ve sürdürülebilirlik konularına güçlü bir vurgu yapan Amperino, elektrikli araç devriminin öncülerinden olmayı hedeflemektedir.`,

		"technology.FurkanShorts": `"Merhaba ben Furkan Tokaç.
									Elektronik yazılım ekibi lideriyim									
									aynı zamanda kurucu ortaklardanım.									
									Elektronik yazılım ekibi olarak tamamen bünyemizde									
									geliştirdiğimiz kontrol ünitelerimiz sayesinde aracımızı tamamen									
									kendi yazılımlarımızla birlikte sürmekteyiz.									
									Mimarimizi baştan itibaren									
									tamamen uzaktan güncellenebilir şekilde tasarladık.									
									Bu sayede aracımıza her an güncelleme gelebilir,									
									ve hemen güncelleyebileceksiniz.									
									Saha testlerimiz sonucu elde ettiğimiz verilerle									
									birlikte aracımızı sürekli güncellemekteyiz.									
									Bünyemizde geliştirdiğimiz kontrol ünitelerimizden
									İki tanesi şöyledir;
									aracımızı süren araç bilgisayarımız 
									ve far gibi gövde parçalarını kontrol eden gövde kontrol ünitemiz.
									Birlikte daha da ileriye gitmek için ve
									geleceği şekillendirmek için sürüşe hazır olun."
									`,
		
		"technology.MuctebaShorts": `"Merhaba, ben Osman Mücteba Bişkinler.
									Batarya ve şasi ekiplerinin lideriyim
									ve Amperino'nun kurucu ortaklarındanım.
									Amperino'da, sadece elektrikli araç tasarlamıyoruz,
									aynı zamanda teknolojilerini de kendi bünyemizde geliştiriyoruz.
									Örnek olarak,
									Doğrudan sıvı soğutma (immersion cooling) ve tel bağlama (wire bonding) teknolojilerini sayabiliriz.
									Batarya ekibi dörde ayrılmaktadır;
									modül, termal, elektrik ve yapısal tasarım.
									Şasi ekibiyse üçe ayrılmaktadır;
									statik analiz, araç dinamikleri ve tasarım.
									Teşekkürler. Görüşmek üzere.
									"`,

		"technology.subHeader1": "Batarya Paketi",
		"technology.subHeader2": "Kompozit",
		"technology.subHeader3": "Sürüş Teknolojisi",
		"technology.subHeader4": "Dijital Araç Platformu",
		"technology.subHeader5": "Kaykay Şasi",

		//Development Page
		"development.header": "GELİŞTİRME",
		"development.exp.head": "Teknolojinin ve Zanaatkarlığın Birleşimi",
		"development.exp.info": `Amperino'da geliştirme süreci, çağdaş teknolojinin yanı sıra geleneksel tasarım tekniklerinin de birleşiminden oluşmaktadır. 
								Ekip, performansı test edilmiş ve optimize edilmiş sanal prototipler oluşturmak için Bilgisayar Destekli Tasarım (CAD), 
								Hesaplamalı Akışkanlar Dinamiği (CFD) ve Bilgisayar Destekli Mühendislik (CAE) kullanmaktadır. Ayrıca, tasarımı fiziksel olarak şekillendirmek ve 
								geliştirmek için kil modelleme yöntemlerine başvurulmaktadır. Doğruluğu sağlamak için, her modelin hassas detaylarını yakalamak için tarama teknolojisi 
								kullanılmaktadır. Son olarak, tasarımcılar vizyonlarını çizerek aracı hayata geçirir ve tasarımın son dokunuşlarını ekler. Teknoloji ve yaratıcılığı 
								bir arada kullanarak, insanlara mümkün olan en yüksek kalitede araçlar sunmayı hedeflemektedir.`,
								
		"development.CuneytShorts": `"Merhaba, ben Cüneyt Tamaç.
									Amperino kurucu teknik liderlerindenim
									aynı zamanda tasarım birim liderliğini de yürütmekteyim.
									Tasarım birimi, yapılan stil çalışması üzerine,
									mühendis tasarımını da ekleyerek
									ortaya üretilebilir bir tasarım koymaktadır.
									Bu aşamada, kendi tecrübelerimizi ve bağlantıda
									olduğumuz üretici firmalardan aldığımız bilgileri de
									harmanlayarak çalışma yürütmekteyiz.
									Yapılan bu çalışmayla birlikte Amperino
									bilgi birikimini de (know-how) geliştirmekte.
									Amperino'nun gelişen know-howı,
									farklı modelleri üzerinde hızlıca
									aksiyon alabilmemizi bize sağlamaktadır.
									Yapılan üretilebilir tasarımların prototip
									çalışmalarını da bünyemizde yapmaktayız.
									Yapılan prototip çalışmalarını, çeşitli
									doğrulama yöntemleriyle doğruladıktan sonra
									tasarımlarımızı dondurmaktayız.
									Donan tasarımlarla birlikte, seri üretim
									planlarımız daha da netleşmektedir.
									Yakında yollarla görüşmek üzere.
									"`,
		"development.NickShorts": `"Merhaba, benim adım Mcdominic Eze ve ben	
									Amperino Otomotiv'de şasi departmanının teknik lideriyim.
									Amperino'da, düşük ağırlık merkezine sahip	
									benzersiz bir skateboard şasimiz var.  
									Şasimiz ölçeklenebilir ve modülerdir ve farklı sınıf konfigürasyonları için	
									kolayca ayarlanabilmektedir.
									Ayrıca düşük dönüş yarıçapı sayesinde		
									kolayca yönlendirilebilir ve keyifli sürüş deneyimi elde etmemizi sağlar.	
									Şasimizin ön yüzünde  MacPherson bağımsız süspansiyonumuz var
									ve şasimizin arka yüzünde
									yarı bağımlı süspansiyonumuz bulunmaktadır.
									Pil paketimizi de kendi bünyemizde geliştirdiğimizden,
									şasimize kolayca entegre edilebilmektedir
									ve böylece şasimizin yapısal bir üyesi olarak hareket eder. 
									Bu da şasimizin yapısal sağlamlığını arttırır.
									Teşekkürler. İyi günler. Hoşca kalın.
									"`,

		"development.subHeader1": "ÇİZİMLER",
		"development.subHeader2": "KİL",
		"development.subHeader3": "TARAMA",
		"development.subHeader4": "CAD",
		"development.subHeader5": "Sanal Gerçeklik",
		"development.subHeader6": "CAE",
		"development.subHeader7": "Prototip",

		//Company Page
		"company.header": "Şirket",
		"company.subHeader1": "Hakkımızda",
		"company.subHeader2": "Kariyer",
		"company.subHeader3": "Haberler",
		"company.katalog": "Daha fazlası için tıklayınız",

		//About Us
		"company.teamInfo1":`Amperino, şehir araçlarının perspektifini değiştirmek için yola çıkmıştır. Şehir yoğunluğunun tüm dünyada büyük ölçüde artması ve yolların ve şehir planlarının bu yoğunlukla başa çıkamayacak olması nedeniyle şehir arabalarının önemi artacaktır. Bu soruna çözüm olarak, Amperino rahatınızdan ödün vermeden hemen hemen her yer ve her zaman sığabilecek bir kompakt konsepti ortaya koydu. Araba yaşam tarzınızı sınırlandırmayacak, aksine iyileştirecek.`,
		"company.teamInfo2": `Yıllar boyunca, ICE arabaları üreten şirketlerin sınırlı hayal gücüyle tekrarlayan ve mekanik teknolojilerine maruz kaldık. Ancak artık teknolojinin bu kalıptan öteye geçme kapasitesi var. Sürücü pozisyonunu kullanıcıya dönüştürmek istiyoruz. Bir kullanıcı olarak, size en iyi sürüş deneyimini sunmak için sizin ihtiyaçlarınıza uyum sağlayan ve arabayı daha önce hiç olmadığı kadar kontrol etme imkanı sağlıyor.`,

		//Career Collapse
		"career.header.info": "Sıkça Sorulan Sorular",
		"career.header1": "Esnek çalışma saatleri var mı?",
		"career.info1": `Öğrenci çalışan arkadaşlarımız hariç, esnek çalışma saatlerini tercih ediyoruz. Bu ayrıca, çalışma gününüzü size ve ekibinize en uygun şekilde düzenleyebileceğiniz anlamına gelir. Bu özgürlük, kendi sorumluluğunuzda hedeflerinizi başarma ve iş-yaşam dengenizi kontrol altında tutma zorluğuyla birlikte gelir.`,
		"career.header2": "Evden çalışmak mümkün mü?",
		"career.info2.1": `Zaman zaman, evden çalışmak sorun değil çünkü bazen evden çalışmanın ne kadar yararlı olabileceğini biliyoruz.`,
		"career.info2.2":`Ancak, takım bir arada olduğunda iletişim ve takım çalışması, ayrıca bölümler arası arayüz yönetimi çok daha iyi işler. Büyük şirketler tarafından domine edilen bir endüstride çalışma şeklimiz nedeniyle hızlı karar verme, çok sayıda kısa toplantı ve ekipler arası sürekli bir iletişim ihtiyacımız var. Bu nedenle, iş ortamında bulunmak bizim için özellikle önemlidir. İş yerinde çalışmayı mümkün olduğunca keyifli ve faydalı hale getirmeye çalışıyoruz.`,
		"career.header3": "Bursa dışında ofisleriniz var mı?",
		"career.info3": `Şu anda iki ofisimiz var. Biri Bursa'da, diğeri ise Kocaeli Bilişim Vadisi'nde.`,
		"career.header4": "Mülakat süreci nasıl işliyor?",
		"career.info4": `CV'ler uzmanlarımız tarafından inceleniyor. Uygun görülürse, başvuru sahiplerine kısa bir görev atanabilir. Daha sonra, belirlenen görevi en iyi şekilde tamamlayan adaylar, konu hakkında bir sunum yapmaları istenebilir. Olumluysa, bir mülakat yapılır ve ardından adaya teklif sunulur.`,
		"career.header5": "Hangi tür bir çalışma ortamınız var?",
		"career.info5": `Amperino'da sadece inanılmaz bir çalışma ortamı elde etmeyeceksiniz, aynı zamanda dinamik uzman takım üyeleri ile birlikte çalışacak ve uzmanlıklarınızı başkalarıyla paylaşmak isteyeceksiniz. Duygusal destek ve işbirliğine dayalı ortamda, herkesi evinde hissettirmek istiyoruz. Bizim için, ofisimiz sadece iş değil, aynı zamanda arkadaşlıkların kurulduğu ve dünya problemlerine çözüm bulduğumuz bir yer. Yaptığımız şey sadece fikirlerle sınırlı değil, aynı zamanda onları gerçekleştiriyoruz. Soyutlamayı yeniden şekillendiriyor ve topluma katkıda bulunmak ve yaşam kalitesini iyileştirmek için onlara yeni bir somut şekil veriyoruz.`,

		//Contact window
		"contact.header1": "Bizimle",
		"contact.header2": "İletişime Geç",
		"contact.name": "Ad",
		"contact.name.placeholder": "Adınız?",
		"contact.email": "E-posta",
		"contact.email.placeholder": "E-posta adresiniz?",
		"contact.message": "Mesaj",
		"contact.message.placeholder": "Mesajınız?",
		"contact.send": "Gönder",
		"contact.success": "Teşekkür ederiz. En kısa sürede size geri döneceğiz.",
		"contact.error": "Bir şeyler yanlış gitti. Lütfen tekrar deneyin.",
		"contact.error.empty": "Formda boşluk veya boşluklar olduğu için mesajınız gönderilemedi.",

		//Battery Page
		"battery.header": "Batarya",
		"battery.header.info": "Amperino'nun bataryası, batarya sisteminin verimliliğini ve güvenliğini artırmak için tasarlanmıştır.",
		"battery.subheader1": "Batarya",
		"battery.subheader1.info1": `Batarya sistemi, bataryaların daha fazla ısıya dayanabilmesine ve böylece daha verimli hale gelmesine yardımcı olan bir batarya sistemi ile donatılmıştır. 
									Sabit sıvı transferi sayesinde soğutma sıvısı ve hücreler arasında ısı transferi gerçekleştirilir. Böylece aşırı ısınmaya bağlı patlama riski azaltılır ve ısıl kaçakların yayılması engellenir. 
									Modüler yapı sayesinde, batarya modülleri skateboard şasisinde esnek bir şekilde düzenlenebilir ve arıza durumunda etkilenen modül diğer batarya sisteminden güvenli ve verimli bir şekilde izole edilebilir.`,
		"battery.subheader1.info2": "• Hücre seviyesinde sigorta",
		"battery.subheader1.info3": "• Direkt sıvı soğutma",
		"battery.subheader1.info4": "• Düşük ağırlık merkezi",
		"battery.subheader1.info5": "• Modül seviyesinde IP67 koruma",
		"battery.subheader1.info6": "• Değiştirilebilir batarya paketi",
		"battery.subheader1.info7": "• Delinme ve yangına karşı dayanıklı batarya yapısı",
		"battery.subheader1.info8": "• Yüksek enerji ve güç yoğunluğuna sahip Silindirik 21700 hücreler",
		"battery.subheader1.info9": "• Modül Tasarımını Değiştirmeden Paralel-Seri Ölçeklenebilir",
		"battery.subheader1.info10": "• Hücre Seviyesinde Voltaj Dengelenmesi ve İzleme, Blok Seviyesinde Sıcaklık İzleme, Paket Seviyesinde Akım İzleme",


		"battery.subheader2": "Batarya Modülü",
		"battery.subheader2.info": `Amperino'nun ilk modeli, son teknoloji bir batarya paketi ile donatılmıştır. 
									Model, toplam 20 modülden oluşur ve 12.4 kWh enerji içeriği sağlar. Daha büyük veya daha küçük elektrikli araç üreticileri için Amperino batarya paketi modül tasarımı ölçeklenebilir bir yapıdadır. 
									Her bir 21700 hücre, her iki taraftan da sigorta ile korunmaktadır.`,

		"battery.subheader3": "Batarya İklimlendirme  Teknolojisi",
		"battery.subheader3.info": `Direkt sıvı daldırma teknolojisinin yardımıyla, batarya paketleri ve batarya modülleri, 20% - 80% enerji kapasiteleri için 20 dakikadan daha hızlı şarj edilebilir. Çoğu lityum hücresinin en büyük düşmanı uygun olmayan termal yönetimdir. Direkt sıvı daldırma, silindirik hücrenin kullanılabilir kapasitesini uzatır ve ısıyı hücreler arasında eşit şekilde dağıtır. Batarya hücreleri her zaman kullanım sırasında 15C ° -25C ° aralığında korunur ve istenmeyen durumlarda, Amperino batarya paketi termal kaçışın yayılmasını önler.`,
 		"battery.subheader4": "Tel Bağlantısı",
		"battery.subheader4.info": `Hücreden Busbar'a bağlantılar %99,99 saflıkta alüminyum tel ile yapılır.
									Bu teller önceden belirlenmiş akım seviyelerine kadar sınırlıdır.
									Alüminyum Tel, akım seviyesi aşılırsa kırılır ve hücre diğer hücrelerden ayrılır, böylece olası bir sorunu önler.
									Batarya modülümüzde tel bağlama teknolojisi kullanmanın bir diğer avantajı,  oda sıcaklığında gerçekleşebilir olmasıdır.
									Hücre yüzeyine herhangi bir ısı uygulanmaz ve tel bağlama işlemi sırasında ısı üretilmez.
`,
		
		//Bio-Kompozit Sayfası
		"composite.header": "Biyo-Kompozit",
		"composite.header.info": "Karbon ayak izimizi azaltmak önceliklerimizden biridir ve mümkün olan en çevre dostu arabayı yapmak için sınırları zorlamaya kararlıyız.",

		"composite.subheader1": "Biyo-Kompozit",
		"composite.subheader1.info": `Amperino araçlarının Yaşam Modülü, yolcuları maksimum güvenlik ve koruma sağlamak için tamamen kendi bünyemizde geliştirilen 
									son derece ileri bir tasarım özelliğidir. Bu Biyo-Kompozit yapısı, sadece dış ve iç kaplamaların taşıyıcısı olarak görev yapmakla kalmaz,
									aynı zamanda bir kazada sağlam bir koruma kalkanı oluşturur. Yaşam Modülü, İç ve Dış Kaplamalarla birlikte,
									Amperino aracının üst gövdesini oluşturarak şık ve zarif bir görünüm sunarken, aynı zamanda eşsiz bir güvenlik sağlar.
									Biyo-Kompozitler, doğal elyaf ve biyo-bazlı bir reçineden yapılmış bir matris malzemesinin birleşimidir,
									bu da Yaşam Modülünü otomotiv endüstrisi için sürdürülebilir bir çözüm haline getirir.
									Bu benzersiz malzeme hafif ancak sağlamdır, mükemmel çarpışma koruma ve enerji  sönümleme özellikleri sunar.
									Yaşam Modülünde Biyo-Kompozit kullanımı, karbon emisyonlarını azaltır ve aracın genel ağırlığını azaltarak yakıt verimliliğini ve performansı artırır.`,


		"composite.subheader2": "ISI YALITIMI",
		"composite.subheader2.info": "Cam ile karşılaştırıldığında %15 ila %30 daha iyi yalıtım sağlar.",
		"composite.subheader3": "FLEXİON ÖZELLİKLERİ",
		"composite.subheader3.info1": "Fransız keteni, cam ve karbon takviyeli kompozitlere kıyasla benzersiz fleksiyon özelliklerine sahip",
		"composite.subheader3.info2": ".",
		"composite.subheader3.info3": "Daha büyük fleksiyon özellikleri sayesinde kırılma olasılığı daha azdır.",


		"composite.subheader4": "YÜKSEK DİRENÇ",
		"composite.subheader4.info": "Camdan %30 daha fazla dayanıklı.",


		//Drive Technology Page
		"drive.header": "Sürüş Teknolojisi",
		"drive.header.info": "Amperino'da dijital araç platformu ve tekerlek içi motorlar gelişmiş konfor ile üst düzey bir sürüş deneyimi sağlar.",


		"drive.subHeader1": "Tekerlek İçi Motor",
		"drive.subHeader1.info1": `Tekerlek içi elektrik motoru, bir aracın tekerlek içine yerleştirilen bir elektrik motoru türüdür.
								Bu tasarım, alanın verimli kullanımına olanak tanır ve araçların kontrolünü ve performansını potansiyel olarak artırabilir.
								Tekerlek içi motorlar, tekerleği doğrudan tahrik edebilir, şanzıman veya aktarma miline ihtiyaç duymaz.
								Ayrıca, her bir tekerleğin ayrı kontrolünü sağlar, bu da çekiş kontrolünü ve stabiliteyi artırabilir.
								Bu tasarım, aracın genel verimliliğini ve tek şarjla menzilini artırabilir.`,
		"drive.subHeader1.info2": `Daha iyi araç kontrolü ve performansı: Her bir tekerleğin ayrı kontrolüne sahip olmak, tekerlek içi motorların
								çekiş kontrolünü ve stabiliteyi artırabileceği anlamına gelir. Bu, daha iyi bir kontrol ve daha duyarlı bir sürüş deneyimi sağlayabilir.`,
		"drive.subHeader1.info3": `Artan verimlilik: Tekerlek içi motorlar, enerji kaybını azaltabilecek şanzıman veya aktarma mili ihtiyacını ortadan kaldırır
								ve aracın genel verimliliğini artırabilir.`,
		"drive.subHeader1.info4": `Artan menzil: Şanzıman ihtiyacını ortadan kaldırarak, enerji doğrudan tekerleğe yönlendirilebilir ve bu, aracın tek şarjla
								menzilini potansiyel olarak artırabilir.`,
		"drive.subHeader1.info5": `Maliyet etkin: Tekerlek içi motorlar, şanzıman, aktarma milleri ve diferansiyel gibi ek bileşenlere ihtiyacı azaltarak maliyet etkin olabilir.`,
		"drive.subHeader1.info6":`Azaltılmış ağırlık: Tekerlekli motorlar, geleneksel motorlardan daha küçük ve hafiftir, bu da aracın genel ağırlığını azaltabilir ve enerji verimliliğini artırabilir.`,
		"drive.subHeader2": "Tork Vektörleme",
		"drive.subHeader2.info": `Tork vektörleme, aracın her bir tekerleğine verilen torkun hassas bir şekilde kontrol edilmesine izin veren son teknoloji bir teknolojidir. Amperino'nun elektrikli araçlarında, güç tekerlekler arasında akıllıca dağıtılarak optimal performans ve yönetilebilirlik sağlar. Bu teknoloji, aracın kararlılığını, çekiş gücünü ve manevra kabiliyetini ihtiyaç duyulduğunda yeniden dağıtarak, daha dinamik ve verimli bir sürüş deneyimi sunar. Tork vektörleme ile elektrikli araçlarımız en zorlu yol koşullarının üstesinden kolayca gelebilmektedirler.`,
		
		//Digital Vehicle Platform Page
		"platform.header": "Dijital Araç Platformu",
		"platform.header.info": "Amperino Dijital Araç Platformu, sürüş deneyimini geliştiren kapsamlı bir yer içi çözümüdür. Esnek, genişletilebilir ve cihazlarla iyi entegre olur.",


		"platform.subHeader1": "Mobil Uygulama",
		"platform.subHeader1.info1": `Amperino Mobil Uygulaması, Amperino elektrikli aracınızın çeşitli yönlerini kontrol etmenize olanak tanıyan kullanıcı dostu bir uygulamadır.
									Bu uygulama ile aracın kapıları ve pencereleri gibi gövde parçalarını kolayca kontrol edebilir ve hatta akıllı telefonunuz veya tabletinizde bir dokunuşla aracı ileri ve geri hareket ettirebilirsiniz.
									Aracınızı uzaktan kontrol eden bir kumanda gibi düşünebilirsiniz, aracınızı her yerden, her zaman kontrol etme gücüne sahip olursunuz. Bu özellik, araçlarını üzerinde daha fazla kontrol sahibi olmak isteyenler için mükemmel bir ek özelliktir ve sürüş deneyimlerini geliştirir.`,
		"platform.subHeader1.info2": "Amperino Mobil Uygulaması kullanıcılarına birçok avantaj sunar. Bunlar arasında şunlar yer alır:",
		"platform.subHeader1.info3": `Konfor: Uygulama sayesinde aracınızı uzaktan kontrol edebilirsiniz. Bu, ayarlamaları yapmak için araçta fiziksel olarak bulunmanız gerekmeyeceği anlamına gelir. Bu özellik, aracınızı içeri girmeden önce soğutmanız veya ısıtmanız gerektiğinde veya bir şeyi almak için bagajınızı açmanız gerektiğinde özellikle kullanışlıdır.`,
		"platform.subHeader1.info4": `Uygulama Marketi: Uygulama, Amperino Uygulama Marketi'ne erişim sağlar. Bu market, sürüş deneyiminizi geliştirmek ve araçta geçirdiğiniz zamanı daha üretken ve keyifli hale getirmek için özel olarak tasarlanmış birçok uygulama ve hizmeti kolayca gözden geçirmenizi ve indirmenizi sağlar.`,
		"platform.subHeader1.info5": `Güvenlik: Uygulamayı, aracınızın pil seviyesi, konumu ve hatta aracınızın hareketlerini izlemek için de kullanabilirsiniz. Bu, aracınızın nerede olduğunu her zaman bilebilmeniz için size güvenlik ve huzur sağlayabilir.`,
		"platform.subHeader1.info6": `Geliştirilmiş sürüş deneyimi: Aracı uzaktan ileri veya geri hareket ettirebilme özelliği sayesinde, aracı sıkı park alanlarında veya diğer dar alanlarda kolayca manevra yapabilirsiniz.`,
		"platform.subHeader2": "Araç Bilgisayar Sistemi",
    	"platform.subHeader2.info1": `Amperino Araç Bilgisayarı (VC), sürüş deneyimini geliştirmek için tasarlanmış, bünyemizde geliştirilen bir kontrol ünitesidir. Bu güvenlik kritik uygulama, aracın çeşitli yönlerini kontrol edebilecek kapasitede olup, aracın merkezi sinir sistemi haline gelmesini sağlar. VC, otomatik sürüş deneyimine öncelik vererek aracı tek bir noktadan kontrol ederek gelişmiş sürücü destek sistemleri (ADAS) çözümlerinin uygulanmasını kolaylaştırır.`,

		"platform.subHeader2.info2": `VC'nin temel özelliklerinden biri, düşük gecikme ile 4 adet tekerlekli motora bağımsız olarak kontrol etme yeteneğidir. 
									Bu teknoloji sayesinde aracınızın dijitalleşmesini daha önce hiç hissetmediğiniz bir şekilde hissedeceksiniz. 
									VC, aracınızın hareketlerini daha hassas ve kontrol edilebilir hale getirerek, gelişmiş bir sürüş deneyimi sağlar.`,
		"platform.subHeader2.info3": `Ayrıca, VC donanım tasarımından yazılıma kadar tamamen yerli olarak tasarlanmış ve geliştirilmiştir. 
									Bu, Amperino Automotive'nin VC'nin işlevselliği ve kalitesi üzerinde tam kontrol sahibi olmasını sağlar ve 
									en yüksek güvenlik ve güvenilirlik standartlarını karşıladığından emin olur.`,
		"platform.subHeader2.info4": `Genel olarak, Amperino Araç Bilgisayarı, sürüş deneyimini artıran gelişmiş özellikler ve yetenekler sunan elektrikli araçlarımızın 
									temel bir bileşenidir. Tek bir noktadan aracı kontrol etme yeteneği, düşük gecikme ve bağımsız tekerlekli motor kontrolü ile 
									ADAS çözümlerinin uygulanmasını kolaylaştırır ve sürüş deneyimini daha güvenli, verimli ve keyifli hale getirir.`,
		"platform.subHeader3": "Yazılım Platformu",
		"platform.subHeader3.info1": `Amperino yazılım platformu, genel sürüş deneyimini geliştirmek için birçok özellik ve yetenek sağlayan son teknoloji bir in-house çözümüdür. Bu platform, yüksek derecede esnek ve genişletilebilir olacak şekilde tasarlanmış olup, cihaz entegrasyonu konusunda da mükemmel bir kabiliyete sahiptir.`,
		"platform.subHeader3.info2": `Bu platformun temel özelliklerinden biri API ve bağlantı kabiliyetidir. Platform, mobil cihazlar, ses cihazları, ekran cihazları ve hatta bilgisayarlar gibi çeşitli harici cihazlarla kolayca entegre edilebilecek şekilde tasarlanmıştır. Bu, kullanıcıların Amperino API'sını destekleyen herhangi bir cihazla araçlarını kolayca genişletmelerine olanak tanır.`,
		"platform.subHeader3.info3": `Bu özellik sayesinde aracınızı kişiselleştirmeniz ve daha verimli ve keyifli hale getirmeniz mümkün olur. Örneğin, mobil cihazınızı kolayca bağlayarak müzik, podcast veya sesli kitapları aracın ses sistemi üzerinden doğrudan dinleyebilir veya kendi müzik kütüphanenize erişebilirsiniz. Ayrıca, işinizi halletmek veya planlarınızı yapmak için aracınızdaki zamanınızı, okuma, yazma ve görevlerinizi organize etmenize olanak tanıyan bir dizi üretkenlik uygulamasıyla değerlendirebilirsiniz.`,
		"platform.subHeader3.info4": `Ayrıca, platform yüksek özelleştirilebilirliğe sahiptir, bu da kullanıcıların araçlarının özelliklerini ve yeteneklerini kendi ihtiyaçlarına göre ayarlamalarını sağlar. Bunlar sıcaklık ve aydınlatmayı ayarlamak gibi şeyleri, hatta kapılar ve pencereler gibi aracın gövde parçalarını kontrol etmeyi bile içerebilir.`,
		"platform.subHeader3.info5": `Özet olarak, Amperino yazılım platformu, genel sürüş deneyimini geliştirmek için birçok özellik ve yetenek sağlayan güçlü bir in-house çözümüdür. API ve bağlantı kabiliyetleriyle, kolay cihaz entegrasyonu ve özelleştirme imkanı sunar. Bu, daha kişiselleştirilmiş ve keyifli bir sürüş deneyimi sağlar.`,
		
		//Chassis Page
		"chassis.header": "Kaykay Şasi",
		"chassis.header.info": `Amperino'da pil paketini kaykay şasisine entegre ettik ve bu sayede aracın stabilitesi ve yönetimi için zemine yakın bir ağırlık merkezi oluşturduk.`,
		"chassis.subHeader1": "Kaykay Şasi Özellikleri",
		"chassis.subHeader1.info1": "Sınıf lideri çarpışma koruması",
		"chassis.subHeader1.info2": "Rivet ve yapıştırma kullanımı",
		"chassis.subHeader1.info3": "Hafif alüminyumdan yapılmış",
		"chassis.subHeader1.info4": "7.5 metre kaldırım-kaldırım dönüş yarıçapı",
		"chassis.subHeader1.info5": "Kaykay platformunun ölçeği değiştirilebilir uzunluk ve genişlik",
		"chassis.subHeader1.info6": "Bağımsız ön MacPherson",
		"chassis.subHeader1.info7": "Yarı bağımsız torsiyon çubuk arka süspansiyon",
		"chassis.subHeader2": "Kaykay Şasi",
		"chassis.subHeader2.info": `Kaykay şasi, batarya paketini taban yapısının bir parçası olarak entegre eder;
									bu konumlandırma aracın ağırlık merkezini düşürerek, aracın kararlılığını önemli ölçüde arttırır.
									Ayrıca, bataryalar araç yapısını değiştirmeden kolayca değiştirilebilir ve yeniden boyutlandırılabilir.`,
		"chassis.subHeader3": "Düşük Ağırlık Merkezi",
		"chassis.subHeader3.info": `Batarya paketi şasinin alt kısmında bulunur. Batarya paketinin ağırlık yoğunluğu ve konumu nedeniyle, bu konfigürasyon aracın merkez yer çekimini son derece zemine yaklaştırır. Şasi platformu ve batarya paketi, aracın ön, yan ve arka çarpışma korumasını sağlayarak yapısal ve burulma rijitliğine katkıda bulunan birbirine bağlı bir yapı oluşturur. 2075mm dingil mesafesi kategorisindeki en uzun mesafedir ve daha uzun dingil mesafesi devrilme riskini önemli ölçüde azaltır. Araç dinamiklerine ek olarak, 2075mm dingil mesafesi daha fazla yolcu ve yük için daha fazla alan açar.`,
		"sketch.header": "ESKİZLER",
		"sketch.header.info": "Amperino'nun ilk aracının tasarımı eskizlerle başladı.",
		"sketch.subHeader": "Eskizler", 
		"sketch.subHeader.info1": `Amperino'nun açılış elektrikli aracı için tasarım yolculuğu birçok model konseptindeki sayısız eskizlerin üretimiyle başladı.`, "sketch.subHeader.info2": ` Eskiz çalışmalarında ki amaç, görsel olarak çekici olmanın yanı sıra, hedef pazarın taleplerini ve tercihlerini karşılayacak şekilde benzersiz ve yenilikçi bir tasarım oluşturmaktı ve benzerlerinden farklı olmaktı. Amperino'daki profesyonel tasarım ekibi, son tasarımın sadece estetik açıdan değil, aynı zamanda aracın amaçlanan amacı için de verimli ve pratik olmasını garanti etmek için büyük çaba sarf etti.`,
 
		//Clay Page
		"clay.header": "KİL",
		"clay.header.info": "Sınıfımızda ki en benzersiz aracı tasarladık.",
		"clay.subHeader": "Kil",
		"clay.subHeader.info1": `Harika bir tasarımın, harika bir arabanın anahtarı olduğuna inanıyoruz ve bu nedenle tasarım sürecinde güvenilir bir yöntem olan kil modellemeyi kullanıyoruz. Otomotiv endüstrisinde yıllardır yaygın olarak kullanılan bu yöntem, elektrikli araçlarımız için mükemmel tasarımı oluşturmak için fikirlerimizi hayata geçirmemize ve gerekli değişiklikleri yapmamıza olanak tanır.`,
		"clay.subHeader.info2": `Süreç, eskiz çalışmalarıyla başlar, ardından 1/4 boyutlu kil model çalışmaları yapılır ve bu çalışmalar, seçilen modelin gerçek boyutlarını ve hatlarını daha iyi anlamamızı sağlar. Bu temel alınarak, yetenekli tasarımcı ekibimiz herhangi gerekli değişiklikleri yapar ve ardından 1/1 boyutlu kil model çalışmalarına geçilir. Bu son aşama, tasarımı daha da geliştirmemize olanak tanır ve sonuç olarak, bitmiş ürünün görsel olarak çarpıcı olmasının yanı sıra işlevsel ve pratik olmasını da sağlar.`,
		"scan.header": "3D Tarama",
		"scan.header.info": "Sınıfındaki en benzersiz aracı tasarlamak için teknolojinin her yönünü kullanıyoruz.",
		"scan.subHeader": "Tarama",
		"scan.subHeader.info1": `Amperino'daki geliştirme süreci, yenilikçi teknolojiye ve hassasiyete bağlılıkla belirlenir. Bu süreçteki önemli araçlardan biri tarama teknolojisidir. Bu teknoloji, son ürünün en yüksek kalitede olmasını sağlamak için üretim sürecinin birden fazla aşamasında kullanılır. İlk olarak, kil model çalışmalarından onaylanmış tasarım, tarama teknolojisi kullanılarak sanal bir ortama aktarılır.`,
		"scan.subHeader.info2": `Bu, tasarımın ayrıntılı bir şekilde incelenmesine ve iyileştirilmesi gereken herhangi bir alanın belirlenmesine olanak tanır. Ayrıca, üretim için kalıpların onayında da kullanılır. Bu, kalıpların tam olarak mühendislik olarak hazırlandığından ve en yüksek kalite standartlarını karşılayacak parçalar üreteceklerinden emin olmaya yardımcı olur. Son olarak, kalite kontrol sürecinde kullanılır, Amperino'nun araçlarından beklenen yüksek standartları karşılamak için üretilen parçaların kritik bir kontrolünü sağlar. Geliştirme sürecinin her aşamasında tarama teknolojisini kullanarak, Amperino araçlarının tasarlandığı, inşa edildiği ve test edildiği yüksek standartları garanti edebilir.`,

		//CAD Page
		"cad.header": "CAD",
		"cad.header.info": "Tıpkı diğer araç üreticileri gibi, Amperino da araçlarının mühendislik tasarımlarını CAD tasarım programlarında yapar.",
		"cad.subHeader": "Cad",
		"cad.subHeader.info1": `Amperino'da, elektrikli araç tasarım ve geliştirme sürecine kapsamlı bir yaklaşım benimsiyoruz. Ekibimiz, modelimizin yalnızca şık ve tarz sahibi olmadığını, aynı zamanda üretilebilirliği de yüksek olduğunu sağlamak için çalışıyor. Pürüzsüz yüzeyler için mühendislik çalışması, gelişmiş CAD teknolojisi kullanılarak oluşturulur ve aracın ambalajlamasını hassas bir şekilde optimize etmemize olanak tanır.`,
		"cad.subHeader.info2": `Ambalajlama işlemi tamamlandıktan sonra, üretim verilerini hazırlamak için aynı CAD ortamını kullanırız, böylece imalat sürecinin her yönü sorunsuz ve verimli bir şekilde işler. Yüksek kaliteli tasarım ve mühendisliğe olan bağlılığımız, hem güzel hem de işlevsel bir araç ortaya çıkarmamıza yol açmıştır ve bunu piyasaya sunmaktan gurur duyuyoruz.`,

		//VR Page
		"vr.header": "VR",
		"vr.header.info": "Tasarımlarımızı doğrularken, geleneksel yöntemlerin yanı sıra en son teknolojileri de sürecimize entegre ediyoruz.",
		"vr.subHeader": "Sanal Gerçeklik (VR)",
		"vr.subHeader.info1": `Çizim çalışmalarından sonra, tasarımın hatları, boyutları ve ergonomisi, kil modeli ile birlikte Sanal Gerçeklik teknolojisi kullanılarak kontrol edilir. Kil model çalışması ve Sanal Gerçeklik teknolojisi işlemleri aynı anda yürütülür.`,
		"vr.subHeader.info2": `Tasarımlar, Sanal Gerçeklik'ten alınan Ergonomi verileri ile olgunlaşır. Tasarımlar olgunlaştığında ve tamamlandığında, tasarım onay süreci tekrar kil modeli ve Sanal Gerçeklik teknolojisi ile onaylanır ve mühendislik çalışmaları başlar.`,

		//CAE Page
		"cae.header": "CAE",
		"cae.header.info": "Bilgisayar destekli mühendislik (CAE) teknolojilerimizle, araç performansını ve güvenliğini maksimize ediyoruz.",
		"cae.subHeader1": "Bilgisayar Destekli Mühendislik",
		"cae.subHeader1.info": `Sınıfında üstün bir araç inşa etmeye karar verdik. 2023 itibariyle pasif güvenlik standartlarını karşılamak zorunda değiliz. Ancak, tüm araçlarımız gerçek dünya standartları ve koşullarını karşılamak üzere tasarlanmıştır. Ayrıca, boş bir sayfadan başlayarak araç platformunu kendi özelliklerimize göre inşa etme meydan okumasını da üstlendik. 64 çekirdekli, 128 GB Ram iş istasyonumuz, araç konforu ve güvenlik özelliklerimizi geliştirmek için çeşitli simülasyonlar üzerinde 24/7 çalışıyor.`,
		"cae.subHeader2": "Ön Çarpışma Yönetim Sistemi",
		"cae.subHeader2.info": `Süreç, akademik makaleleri araştırarak başlar, daha sonra basit kavramlar geliştirme, sanal analiz simülasyonu ve sonunda kabul edilebilir sonuçlar bulduktan sonra rafine edilerek ayrıntılı tasarım aşamasına geçilir/ Simülasyon mühendislerimiz, malzemeler ve metalurji mühendislerimizle yakın iş birliği yaparak, gerekli görev için en uygun alaşımı bulmak için çalışırlar.`,
		"cae.subHeader3": "Yapısal Analizler",
		"cae.subHeader3.info1": `Elektrikli bir araç tasarlarken en önemli parametrelerden biri ağırlıktır. Yapısal güvenliği riske atmadan daha hafif araçlar inşa etmek büyük bir zorluktur. Bu hedefi karşılamak için Amperino mühendisleri ANSYS kullanıyor. Bu yazılım gerçek ve sanal testler arasındaki en yakın ilişkiyi sağlar.`,
		"cae.subHeader3.info2": `Araç bileşenlerinin geliştirilmesi, ilk konsept CAD modelleri ile başlar ve simülasyon çalışmalarına dayanarak, CAE ekibi Şasi tasarım ekibine bazı değişiklikler önerir. Sanal analiz tamamlandıktan sonra, bileşen seviyesinde testlere başlanır ve ardından sistem seviyesine, en sonunda araç seviyesine geçilir.`,
		"cae.subHeader4": "Aerodinamik",
		"cae.subHeader4.info": `En büyük hedefimiz, aracın sürüş menzilini ve verimliliğini artırmak için mümkün olan en düşük aerodinamik katsayıya ulaşmaktı ve araç tasarımının estetiğini feda etmeden bunu başarmaktı. Hesaplamalı Akışkanlar Dinamiği Mühendislerimiz, optimum araç aerodinamik özelliklerini ve dinamiklerini elde etmek için birçok ay boyunca çalıştılar.`,

		"cae.subHeader5": "Difüzör",
		"cae.subHeader5.info": `Amperino mühendisleri, aracın arka kısmında indirgeyici etkiyi azaltmak ve aşağı doğru kuvveti artırmak için son derece gelişmiş bir difüzör geliştirdi.`,
		"cae.subHeader6": "HVAC",
		"cae.subHeader6.info": `Ön cam buğu çözücüsü ve yan ayna buğu çözücüsü analizleri, en etkili hava ızgarası çıkışını bulmak için sanal simülasyonlarda gerçekleştirilir.`,
		"cae.subHeader7": "Metroloji",
		"cae.subHeader7.info": `Deney prototipi ve doğrulama prototipi araçları "3 Boyutlu Tarama Teknolojileri" ile kontrol edilir ve CAD mühendislerimiz, CAD verileri ile gerçek ürün arasındaki boyutsal farklılıkları bulmak için metroloji kullanır.`,
    
		//Prototype Page
		"prototype.header": "Prototip",
		"prototype.header.info": "Prototip geliştirme sürecimiz, her detayın titizlikle test edilip optimize edildiği yüksek hassasiyetli bir süreçtir.",
		"prototype.subHeader": "Deneyimsel Prototip",
		"prototype.subHeader.info": `Amperino olarak, son teknoloji ve yenilikçi tasarımın birleşimiyle yüksek kaliteli araçlar yaratmaya odaklanıyoruz. Üç aşamalı deneysel prototiplerimiz, her biri öncekinden daha gelişmiş sistem ve teknolojiye sahip olan bu vizyonu yansıtır. Bu prototiplerin tamamlanmasının ardından, araçlarımızın Avrupa ve Türkiye pazarları için tam olarak homologe edilmesini sağlamak için doğrulama prototipleri üreteceğiz.`,

		//Error Page
		"error.header": "SAYFA BULUNAMADI",
		"error.header.info": "Kaybolduğunuzu düşünüyoruz. Endişelenmeyin, sizi yönlendiriyoruz.",
		"error.backButton": "Amperino'ya Git",

		"footer.follow": "Takip Et",
		"footer.center": "Merkez",
		"footer.header1": "ANA SAYFA",
		"footer.header1.subHeader1": "Araç",
		"footer.header1.subHeader2": "Teknoloji",
		"footer.header1.subHeader3": "Geliştirme",
		"footer.header1.subHeader4": "Şirket",
		"footer.header2": "TEKNOLOJİ",
		"footer.header2.subHeader1": "Batarya Paketi",
		"footer.header2.subHeader2": "Kompozit",
		"footer.header2.subHeader3": "Sürüş Teknolojisi",
		"footer.header2.subHeader4": "Dijital Araç Platformu",
		"footer.header2.subHeader5": "Kaykay Şasi",

		"footer.header3": "GELİŞTİRME",
		"footer.header3.subHeader1": "Tasarım",
		"footer.header3.subHeader2": "Kil",
		"footer.header3.subHeader3": "Tarama",
		"footer.header3.subHeader4": "CAD",
		"footer.header3.subHeader5": "Sanal Gerçeklik",
		"footer.header3.subHeader6": "CAE",
		"footer.header3.subHeader7": "Prototip",
		"footer.header4": "ŞİRKET",
		"footer.header4.subHeader1": "Hakkımızda",
		"footer.header4.subHeader2": "Kariyer",
		"footer.header4.subHeader3": "Haberler",

		//MenuBar
		"menu.home": "Anasayfa",
		"menu.car": "Araç",
		"menu.technology": "Teknoloji",
		"menu.development": "Geliştirme",
		"menu.company": "Şirket",
		//BurgerMenu
		"burger.header1": "Anasayfa",
		"burger.header2": "Araç",
		"burger.header3": "Teknoloji",
		"burger.header3.subHeader1": "Batarya Paketi",
		"burger.header3.subHeader2": "Kompozit",
		"burger.header3.subHeader3": "Sürüş Teknolojisi",
		"burger.header3.subHeader4": "Dijital Araç Platformu",
		"burger.header3.subHeader5": "Skateboard Şasi",
		"burger.header4": "Geliştirme",
		"burger.header4.subHeader1": "Eskiz",
		"burger.header4.subHeader2": "Kil",
		"burger.header4.subHeader3": "Tarama",
		"burger.header4.subHeader4": "CAD",
		"burger.header4.subHeader5": "VR",
		"burger.header4.subHeader6": "CAE",
		"burger.header4.subHeader7": "Prototip",
    	"burger.header5": "Şirket",
	},

	"en-US": {
		//Home Page
		"home.title.Car": "Car",
		"home.title.Technology": "Technology",
		"home.title.Development": "Development",
		"home.title.Company": "Company",
		"home.title.Discover": "Discover",
		
		"home.Car": "The Amperino electric car offers an innovative solution with its three-seater design and spacious trunk capacity that stands out among micro cars. The sleek exterior is paired with cutting-edge technology, delivering a technologically advanced car with unmatched style and functionality.",
		"home.Technology": "At Amperino, we strive for sustainability through unique battery design and eco-friendly materials.	Our in-house development ensures full control of technology for optimal performance and a connected driving experience.",
		"home.Development": "Our development process blends cutting-edge technology with traditional design techniques for the highest quality vehicles. Our team uses a combination of CAD, CFD, CAE, clay modeling, scanning technology, and creative design to bring virtual prototypes to life and optimize their performance.",
		"home.Company": "A dynamic and supportive working environment, where employees have access to expert team members who share their knowledge and expertise. The office culture values collaboration, friendship, and problem-solving, with a focus on making a positive impact on society and improving quality of life.",
		
		//Car Page
		"car.explanation": "LUXURY IN TECHNOLOGY, TECHNOLOGY FOR THE FUTURE",
		"car.information1": "Nature friendly",
		"car.information1.1": "%100 Electric car",
		"car.information2": "1/Combined Range",
		"car.information3": "1/Time to fully charge",
		"car.information3.1": "House 240 min",
		"car.information3.2": "Station 80 min",
		"car.information4": "Maximum Speed",
		"car.information5": "90km/h",

		"car.notification": "Dynamic View To The Future",

		"car.details": "Details",
		"car.info1": "Length: 3159 mm Width: 1500 mm Height: 1450 mm",
		"car.info2": "Best-in-class Luggage Volume (370Lt)",
		"car.info3": "Curb Weight (650 Kg)",
		"car.info4": "For 3 person",
		"car.info5": "Good handling with a low center of gravity.",
		"car.info6": "Balanced center of gravity",
		"car.info7": "Suitable for age 16 A2 driver's license.",
		"car.info8": "Largest legroom in its class for rear passengers.",
		
		//Car Opacity
		"opacity.discover": "DISCOVER THE DETAILS OF AMPERINO",
		"opacity.composite1": "BODY IN COMPOSITE",
		"opacity.composite2": "Low density",
		"opacity.composite3": "-40% less than fibreglass",
		"opacity.composite4": "Vibration Damping",
		"opacity.composite5": "Up to twice as absorbent as glass and three times as absorbent as carbon. ",
		"opacity.composite6": "(based on the result of flax epoxy composite exposed to frequency of 1 Hz at 25°C)",
		
		"opacity.chassis1": "CHASSIS",
		"opacity.chassis2": "Crash Management System in Front & Rear",
		"opacity.chassis3": "Crumble Zone - High Protection for driver and passengers",
		"opacity.chassis4": "Specially designed & Extruded High Strenght Aluminium Alloys",
		"opacity.chassis5": "Low Center of gravity - High Vehicle Dynamics and Road Handling",
		"opacity.chassis6": "Independant Front Suspension - Semi independent Rear Suspension",
	
		"opacity.battery1": "BATTERY PACK",
		"opacity.battery2": "Cell level fusing",
		"opacity.battery3": "Fire protection system",
		"opacity.battery4": "Structural Battery Pack",
		"opacity.battery5": "Battery Management System",
		"opacity.battery6": "Immersion Cooling & Heating",
		"opacity.battery7": "3 Phase and Mono phase charging option",
		"opacity.battery8": "Cell level voltage and battery pack level current measurement",

		"opacity.technology1": "DRIVING TECHNOLOGY",
		"opacity.technology2": "Amperino Digital Vehicle Platform and in-wheel motors let the Amperino Users experience lots of advanced driving features.",
		"opacity.technology3": "It is always improving by OTA updates!",

		//Technology Page
		"technology.header": "TECHNOLOGY",
		"technology.exp.head": "Leading The Charge",
		"technology.exp.info": `At Amperino, we are devoted to forging a sustainable future through our technology, 
								which showcases a one-of-a-kind battery pack design and packaging for optimized efficiency and range. 
								The exterior is reinforced with eco-friendly flax fiber, instead of traditional materials like fiberglass or carbon fiber. 
								Additionally, all hardware and software is devised and developed in-house, ensuring full control over the technology that drives our vehicles. 
								Our mobile application keeps you connected to your car and provides performance insights, and more. 
								The skateboard chassis also provides a low center of gravity, enhancing handling and creating a spacious interior. 
								With a strong emphasis on innovation and sustainability, Amperino is at the forefront of the electric vehicle revolution.`,

		"technology.FurkanShorts": `"I'm Furkan Tokaç,
									I am the leader of the Electronic Software Team,									
									and also one of the co-founders.									
									As the Electronic Software Team,									
									We have developed control units in-house 									
									to drive our vehicle using our software.									
									From the beginning, we designed our architecture									
									completely in a way that can be updated remotely.									
									Therefore, our vehicle can get updates at any moment,									
									and you will be able to update it immediately.									
									With the data we obtained from our field tests,									
									we continually update our vehicle.									
									Two of our control units that we developed in-house are;									
									our vehicle computer that drives our vehicle									
									and our body control unit that controls body parts like headlights.									
									Get ready to drive to go further together and									
									shape the future."		
									`,
		"technology.MuctebaShorts": `"Hello My name is Osman Mücteba Bişkinler. 
									I am Battery and chassis team leader
									and one of the co-founders of Amperno.
									In Amperno, we don't only design electrical car, 
									we also develop technologies in house.
									I can give an example like
									immersion cooling and wire bonding.
									In Battery department, we have four teams,
									module,
									thermal, 
									electrical and structure.
									In chassis department, we have three teams
									static analysis, 
									vehicle dynamics and design.
									Thank you very much.
									See you soon."`,

		"technology.subHeader1": "Battery Pack",
		"technology.subHeader2": "Composite",
		"technology.subHeader3": "Drive Technology",
		"technology.subHeader4": "Digital Vehicle Platform",
		"technology.subHeader5": "Skateboard Chassis",
		
		//Development Page
		"development.header": "DEVELOPMENT",
		"development.exp.head": "A Fusion of Technology and Craftsmanship",
		"development.exp.info": `Our development process at Amperino is a combination of cutting-edge 
								technology and traditional design techniques. Our team utilizes Computer-Aided Design (CAD), 
								Computational Fluid Dynamics (CFD), and Computer-Aided Engineering (CAE) 
								to create virtual prototypes that are tested and optimized for performance. 
								Additionally, we use clay modeling to physically sculpt and refine the design. 
								To ensure accuracy, we use scanning technology to capture the precise details of each model. 
								Finally, our designers sketch out their vision to bring the car to life and add the final touches to the design. 
								By utilizing both technology and creativity, we are able to bring our customers the highest quality vehicles possible.`,

		"development.CuneytShorts": `"Hello, I am Cüneyt Tamaç.
									I'm one of the technical founders at Amperino 		
									and I also lead the design team.		
									The design team, after the style work,		
									incorporates the engineering design,		
									and comes up with a manufacturable design.		
									At this point, we blend our experiences and		
									information from the manufacturers we're linked with		
									to conduct our work.		
									Through this work, Amperino		
									advances its know-how.		
									Amperino's growing know-how		
									provides us the ability to quickly		
									act on various models.		
									We also carry out the prototype		
									work of the manufacturable designs in-house.		
									Once we verify these prototypes with		
									different validation methods,		
									we finalize our designs.		
									With the finalized designs, our mass production		
									plans become even more clear.		
									Look forward to seeing you on the roads soon."
									`,
		"development.NickShorts": `"Hi my name is Mcdominic Eze, and Im
									the technical leader in chassis department at Amperino Automotive.
									In Amperino, we have a unique skateboard chassis
									with low center of gravity.
									Our chassis is scalable and modular and is easy
									to configure for different class configuration, we also have
									designed a chassis with a low turning radius which means
									it is easy to steer and the handling feels great
									we have in our chassis front setup a MacPherson independent suspension
									and also at the rear end of our chassis
									we have semi-dependent suspension 
									we have also developed in house our battery pack 
									which means it is easy to integrate to the chassis as a single unit 
									acting as a structural member of our chassis.
									This improves the structural rigidity of our chassis.
									Thanks.	Have a nice day. Bye bye.
									"`,

		"development.subHeader1": "SKETCH",
		"development.subHeader2": "CLAY",
		"development.subHeader3": "SCAN",
		"development.subHeader4": "CAD",
		"development.subHeader5": "VR",
		"development.subHeader6": "CAE",
		"development.subHeader7": "PROTOTYPE",
	
		//Company Page
		"company.header": "Company",
		"company.subHeader1": "About Us",
		"company.subHeader2": "Career",
		"company.subHeader3": "News",
		"company.katalog": "Click for more",
		
		//About Us
		"company.teamInfo1":`Amperino is established to change the perspective of the city cars. We believe the importance 
							of the city cars will flourish as the city density dramatically increases all across the world 
							and the roads and the city plans are not going to be able to keep up with this intensity. 
							As a solution to this, Amperino came up with the compact concept that is designed to fi​t almost 
							anywhere at any time without making concessions from your comfort. The car is not going to limit 
							your lifestyle but instead it is going to improve it.`,
		"company.teamInfo2":`Through all those years, we are exposed to unimaginative, repetitive, and mechanical technologies 
							of the companies that produce ICE cars. However, now that technology has the capacity to go beyond 
							this stigma. We want to convert the driver's position into the user. As a user, you are going to be 
							in control of the car more than ever because we comply with the needs of the people and try our best 
							to offer you the best driving experience.`,
		
		//Career Collapse
		"career.header.info": "Frequently Asked Questions",
		"career.header1": "Flexible working hours?",
		"career.info1": `Except for working student contracts, our motto is: trust before control! 
						Thus,  we prefer flexible working hours. 
						This also means that you can arrange your working day to suit you and your team best. 
						This freedom goes hand in hand with the challenge to achieve your goals on your own responsibility and 
						to have your work-life balance under control.`,
		"career.header2": "Is it possible to work from home?",
		"career.info2.1": `From time to time, home office is not a problem because we know how useful it can be to work from home occasionally.`,
		"career.info2.2": `However, we have found that communication and teamwork, as well as cross-departmental interface management work much better when the team is together. 
							Since our way of working in an industry dominated by large corporations, we need quick decision making, many short meetings and constant exchange between the teams to cover up. 
							Hence personal presence is particularly important to us. That's why we try to make working in our office as pleasant and beneficial as possible.`,
		"career.header3": "Are there offices outside of Bursa?",
		"career.info3": `We currently have two offices. One of them is in Bursa and the other is in Kocaeli, in the Informatics Valley.`,
		"career.header4": "How does the interview process work?",
		"career.info4": `CV's are reviewed by our experts. If deemed appropriate, a short task might be assigned to applicants. 
						Then, the candidates who have completed the defined task in the best way, might be requested to make a presentation about  the subject. 
						If it is positive, an interview will be conducted and then an offer is made to the candidate.`,
		"career.header5": "What kind of work environment do you have?",
		"career.info5": `In Amperino, not only will you be blessed with the amazing working environment but 
						also you will work with dynamic expert team members who are willing to share their expertise with others. With the emotional 
						support and collaborative environment, we are eager to make everyone feel at home. For us, our office is just more than work, 
						it is the place where we form friendships and find solutions to the problems of the world. What we are doing is not only limited by the ideas, 
						but also the realization of them. We are reshaping the abstraction and giving them a new concrete shape for the sake of contributing to society and 
						improving the quality of life.`,

		//Contact window
		"contact.header1": "Contact",
		"contact.header2": "Us",
		"contact.name": "Your Name",
		"contact.name.placeholder": "What's your name?",
		"contact.email": "Your Email",
		"contact.email.placeholder": "What's your email?",
		"contact.message": "Your Message",
		"contact.message.placeholder": "What's your message?",
		"contact.send": "Send",
		"contact.success": "Thank you. We will get back to you as soon as possible.",
		"contact.error": "Something went wrong. Please try again.",
		"contact.error.empty": "Your message could not be sent because there are space or spaces in the form.",


		//Battery Page
		"battery.header": "Battery",
		"battery.header.info": "Amperino's immersion cooled modular battery system is designed to improve efficiency and safety.",

		"battery.subheader1": "Battery",
		"battery.subheader1.info1": `With the help of immersion cooled modular battery system, not only can the batteries endure m​ore heat exposure, thus 
									become more efficient, but it also decreases the possibility of overthrust due to constant liquid transfer of heat between 
									the coolant fluid and the cells; and as a result, prevents the propagation of thermal runaway. With the modularity, battery modules 
									can be flexibly arranged in a skateboard chassis and in the case of a malfunction, it isolates the affected module from the rest of 
									the battery system safely and efficiently.`,
		"battery.subheader1.info2": "• Cell - Level Fusing",
		"battery.subheader1.info3": "• Direct Liquid Cooling",
		"battery.subheader1.info4": "• Low center of gravity",
		"battery.subheader1.info5": "• Module level IP67 Protection",
		"battery.subheader1.info6": "• Easily changeable battery pack",
		"battery.subheader1.info7": "• Puncture & Fire resistant Battery Structure",
		"battery.subheader1.info8": "• High energy and power dense Cylindrical 21700 cells",
		"battery.subheader1.info9": "• Scalable Parallel - Series without altering Module Design",
		"battery.subheader1.info10": "• Cell Level Voltage Balancing & Monitoring, Block Level Temperature Monitoring, Pack Level Current Monitoring",

		"battery.subheader2": "Battery Module",
		"battery.subheader2.info": `Amperino’s first Model  comes with a state of the art battery pack.  
									The model consists of 20 modules which add up to 12.4kWh energy content. 
									For larger or smaller electric vehicle manufacturers, Amperino battery pack module design is scalable. 
									Each 2170 cells are protected by a fuse from both sides.`,

		"battery.subheader3": "Battery Immersion Technology",
		"battery.subheader3.info": `With the help of Immersion Cooling technology, Battery packs and 
									Battery Modules can charge as fast as 20 minutes for their 20%-80% energy capacity. 
									Worst enemy of most Lithium cells is improper  thermal management. 
									Immersion cooling prolongs the usable capacity of the cylindrical cell and dissipates the heat across the cells evenly. 
									Battery cells are always maintained at 15C°-25C° during usage.
									Another big advantage of Immersion Cooling is that in case of an unwanted car accident, 
									Amperino battery pack Immersion Cooling prevents propagation of thermal runaway.`,

		"battery.subheader4": "Wire Bonding",
		"battery.subheader4.info": `Cell to Busbar connections are made by 99.99% pure aluminum wire. 
									Those wires are limited to pre-specified current levels. 
									Aluminium Wire breaks if current level is past and cell is disconnected from other cells to prevent any possible problem.
									Another advantage of using wire bonding technology in our battery module is that Wire bonding process is done at room temperatures. 
									No heat is applied to the cell surface or neither heat generated during the bonding process unlike other connection methods like spot welding.`,
		
		//Composite Page
		"composite.header": "Bio-Composite",
		"composite.header.info": `Since reducing our carbon footprint is one of our priorities, we are dedicated to pushing the boundaries to come 
								up with the most environmentally friendly car that is po​ssible to make.`,

		"composite.subheader1": "Bio-Composite",
		"composite.subheader1.info": `The Life Module of Amperino vehicles is a cutting-edge design feature, 
									developed entirely in-house to ensure maximum safety and protection for its passengers. 
									This Bio Composite structure not only acts as the carrier of the exterior and interior trim, 
									but also creates a sturdy protective shield in the event of an accident. The Life Module, in combination with the Interior and Exterior Trim, 
									forms the upper body of the Amperino vehicle, providing a sleek and stylish look while also delivering unbeatable safety. 
									Bio Composites are a combination of natural fibers and a matrix material made from a bio-based resin, 
									making the Life Module a sustainable solution for the automotive industry. 
									This unique material is lightweight yet strong, providing excellent crash protection and energy absorption properties. 
									The use of Bio Composites in the Life Module also reduces carbon emissions and lowers the vehicle's overall weight, 
									leading to improved fuel efficiency and performance.`,

		"composite.subheader2": "THERMAL INSULATION",
		"composite.subheader2.info": "15 to 30% better insulation compared to glass.",

		"composite.subheader3": "FLEXION PROPERTIES",
		"composite.subheader3.info1": "French flax has unique flexion properties compared",
		"composite.subheader3.info2": "with glass and carbon reinforced composites:",
		"composite.subheader3.info3": "greater flexion hence less likely to break.",

		"composite.subheader4": "HIGH SPECIFIC STIFFNESS AND RESISTANCE",
		"composite.subheader4.info": "Up to 30% more specific stiffness than glass.",

		//Drive Technology Page
		"drive.header": "Drive Technology",
		"drive.header.info": "At Amperino, the Digital Vehicle Platform and in-wheel motors provide an elevated driving experience with advanced comfort.",

		"drive.subHeader1": "In-Wheel Motor",
		"drive.subHeader1.info1": `An in-wheel electric motor is a type of electric motor that is mounted inside the wheel of a vehicle. 
								This design allows for efficient use of space and can potentially improve vehicle handling and performance. 
								In-wheel motors can drive the wheel directly, eliminating the need for a drive shaft or transmission. 
								They also allow for individual control of each wheel, which can improve traction control and stability. 
								This design can improve the overall efficiency of the vehicle, as well as its range on a single charge.`,
		"drive.subHeader1.info2": `Better vehicle handling and performance: By having individual control of each wheel, in-wheel motors 
								can improve traction control and stability. This can lead to better handling and a more responsive driving experience.`,
		"drive.subHeader1.info3": `Increased efficiency: In-wheel motors eliminate the need for a drive shaft or transmission, which can reduce energy 
								loss and improve overall vehicle efficiency.`,
		"drive.subHeader1.info4": `Increased range: By eliminating the need for a transmission, energy can be directed straight to the wheel, which can 
								potentially increase the range of the vehicle on a single charge.`,
		"drive.subHeader1.info5": `Cost-effective: In-wheel motors can be cost-effective as they reduce the need for additional components such as 
								transmission, drive shafts and differentials.`,
		"drive.subHeader1.info6": `Reduced weight: The In-wheel motors are smaller and lighter than traditional motors which can reduce the overall 
								weight of the vehicle and improve its energy efficiency.`,
		
		"drive.subHeader2": "Torque Vectoring",
		"drive.subHeader2.info": `Torque vectoring is a cutting-edge technology that allows for precise control of the torque delivered to each wheel of a vehicle. In Amperino's electric 
								vehicles, it enables the power to be distributed intelligently between the wheels for optimal performance and handling. This technology enhances the vehicle's stability, 
								traction, and maneuverability by redistributing torque as needed, resulting in a more dynamic and efficient driving experience. With torque vectoring, our electric 
								vehicles are able to tackle even the most challenging road conditions with ease.`,

		//Digital Vehicle Platform Page
		"platform.header": "Digital Vehicle Platform",
		"platform.header.info": "The Amperino Digital Vehicle Platform is a comprehensive, in-house solution that improves driving experience. It is flexible, extendable and integrates well with devices.",

		"platform.subHeader1": "Mobil App",
		"platform.subHeader1.info1": `The Amperino Mobile Application is a convenient and user-friendly app that allows you to control 
									various aspects of your Amperino electric vehicle. With this app, you can easily control the car's 
									body parts, such as the doors and windows, and even move the car forward and backward with just a tap 
									on your smartphone or tablet. Think of it as a remote control for your car, giving you the power to control 
									your vehicle from anywhere, at any time. This feature is a perfect addition for those who want to have more 
									control over their vehicle and enhance their driving experience.`,

		"platform.subHeader1.info2": "The Amperino Mobile Application offers a number of advantages to its users. Some of these include:",
		"platform.subHeader1.info3": `Convenience: With the app, you can control your car remotely, which means you don't have to be physically 
									present in the vehicle to make adjustments. This is especially useful if you need to cool down or warm up your 
									car before getting in, or if you need to open the trunk to retrieve something.`,
		"platform.subHeader1.info4": `App Market: The app includes access to the Amperino App Market. This market allows you to easily browse and 
									download a wide range of apps and services that are specifically designed to enhance your driving experience and 
									make your time in the car more productive and enjoyable.`,
		"platform.subHeader1.info5": `Peace of mind: You can also use the app to check on the status of your car, such as its battery level, location, 
									and even track your car's movement. This can give you peace of mind and a sense of security, knowing that you can always know where your car is.`,
		"platform.subHeader1.info6": `Enhanced driving experience: With the ability to move the car forward and backward remotely, you can easily maneuver the car in tight parking spots or other tight spaces.`,

		"platform.subHeader2": "Vehicle Computer System",
		"platform.subHeader2.info1": `The Amperino Vehicle Computer (VC) is a state-of-the-art, in-house developed control unit that is designed to enhance the overall driving experience. 
									This safety-critical application is capable of controlling various aspects of the car, making it the central nervous system of the vehicle. 
									The VC prioritizes autonomous driving experience by controlling the vehicle from a single point, which makes it easier to implement advanced driver 
									assistance systems (ADAS) solutions.`,
		"platform.subHeader2.info2": `One of the key features of the VC is its ability to control 4 in-wheel motors independently with low latency, 
									which ensures smooth and safe motor control. With this technology, you will feel the digitalization of your vehicle 
									like you never have before. The VC allows for greater precision and control over your car's movements, providing an enhanced driving experience.`,
		"platform.subHeader2.info3": `Furthermore, the VC is designed and developed entirely in-house, from hardware design to software. This allows Amperino 
									Automotive to have full control over the functionality and quality of the VC, ensuring that it meets the highest standards of safety and reliability.`,
		"platform.subHeader2.info4": `Overall, the Amperino Vehicle Computer is an essential component of our electric vehicles, providing advanced features and 
									capabilities that enhance the driving experience. Its ability to control the car from a single point, with low latency and independent 
									in-wheel motor control, makes it easier to implement ADAS solutions, and make the driving experience safer, more efficient and enjoyable.`,

		"platform.subHeader3": "Software Platform",
		"platform.subHeader3.info1": `The Amperino software platform is a cutting-edge, in-house developed solution that provides a 
									wide range of features and capabilities to enhance the overall driving experience. This platform 
									is designed to be highly flexible and extendable, with excellent device integration capabilities.`,
		"platform.subHeader3.info2": `One of the key features of this platform is its API and connectivity capabilities. 
									The platform is designed to be easily integrated with a wide range of external devices, 
									such as mobile devices, sound devices, display devices, and even computers. This allows users 
									to easily extend their vehicle with any device that supports the Amperino API.`,
		"platform.subHeader3.info3": `This feature makes it possible for you to personalize your car to your liking, 
									and make it more productive and enjoyable. You can easily connect your mobile device, for example, 
									and stream music, podcasts, or audiobooks, directly from your phone or tablet, or access your own music library, 
									all through the car's sound system. You can also use your time in the car to catch up on work or plan your schedule, 
									with a range of productivity apps that allow you to read, write, and organize your tasks.`,
		"platform.subHeader3.info4": `Furthermore, the platform is designed to be highly customizable, allowing users to tailor the features and capabilities 
									of their vehicle to their specific needs. This can include things like adjusting the temperature and lighting, and even 
									controlling the car's body parts, such as the doors and windows.`,
		"platform.subHeader3.info5": `In summary, the Amperino software platform is a powerful, in-house developed solution that provides a wide range of features 
									and capabilities to enhance the overall driving experience. With its API and connectivity capabilities, it allows for easy device 
									integration and customization, making it possible for users to extend their vehicle with any device that supports the Amperino API. 
									This allows for a more personalized and enjoyable driving experience.`,

		//Chassis Page
		"chassis.header": "Skateboard Chassis",
		"chassis.header.info": `At Amperino, we have integrated the battery pack into the skateboard chassis to create a low center of gravity, 
								improving the stability and handling of the vehicle.`,

		"chassis.subHeader1": "Features of Skateboard Chassis",
		"chassis.subHeader1.info1":  "Class leading crash protection",
		"chassis.subHeader1.info2":  "Use of rivet & adhesive bonding",
		"chassis.subHeader1.info3":  "Made from lightweight aluminium",
		"chassis.subHeader1.info4":  "7.5 meter curb - curb turning radius",
		"chassis.subHeader1.info5":  "Scalable length and width of skateboard platform",
		"chassis.subHeader1.info6":  "Independent front MacPherson,",
		"chassis.subHeader1.info7":  "Semi-independent torsion beam rear suspension",

		"chassis.subHeader2": "Skateboard Chassis",
		"chassis.subHeader2.info": `Skateboard chassis integrates the battery pack as part of the floor structure; 
									this positioning results in giving the vehicle a low center of gravity which improves 
									the stability of the ca​r significantly. In addition, the batteries can be easily replaced 
									and resized without altering the vehicle structure.`,

		"chassis.subHeader3": "Low Center of Gravity",
		"chassis.subHeader3.info": `The battery pack is located at the lower portion of the chassis. 
									Due to the weight concentration and location  of the battery pack, 
									this configuration extremely lowers the center of gravity of the vehicle. 
									Both the chassis platform and battery pack form a symbiotic structure, adding to the 
									structural and torsional rigidity of the vehicle providing front, side and rear crash protection
									The 2075mm wheelbase is the longest in its category, and the longer wheelbase drastically 
									reduces the chances of rolling over. Aside from the advantages to the vehicle dynamics, 
									the 2075mm wheelbase opens more room for passengers and cargo.`,
		
		//Sketch Page
		"sketch.header": "SKETCH",
		"sketch.header.info": "The design of Amperino's first vehicle started with sketches.",

		"sketch.subHeader": "Sketch",
		"sketch.subHeader.info1": `The design journey for Amperino's inaugural electric vehicle was an extensive and 
								meticulous process that commenced with the production of numerous sketches on various model concepts.`,
		"sketch.subHeader.info2": `The objective during this sketch stage was to formulate a design that was not only visually captivating, 
								but also distinctive and innovative, specifically catered to meet the demands and preferences of the target market, and stand out among its peers. 
								The professional design team at Amperino put in a great deal of effort to guarantee that the final design was not only aesthetically pleasing, 
								but also efficient and practical for the intended purpose of the vehicle.`,
		
		//Clay Page
		"clay.header": "CLAY",
		"clay.header.info": "Amperino designs the most unique vehicle in its class.",

		"clay.subHeader": "Clay",
		"clay.subHeader.info1": `At Amperino, we believe that a great design is the key to a great car, and that's why we use the trusted method of clay modeling in the design process. 
								This method, widely used in the automotive industry for many years, 
								allows us to bring our ideas to life and make the necessary changes to create the perfect design for our electric vehicles.`,
		"clay.subHeader.info2": `The process starts with sketch studies, 
								followed by 1/4 dimensional clay model work, which gives us a better understanding of the selected model in its real dimensions and lines. 
								Based on this, our team of talented designers makes any necessary changes, before moving on to 1/1 clay model work. 
								This final stage allows us to refine the design even further, ensuring that the finished product is not only visually stunning, 
								but functional and practical as well.`,
								
		//Scan Page
		"scan.header": "Scan",
		"scan.header.info": "We use every aspect of technology to design the most unique vehicle in its class.",

		"scan.subHeader": "Scan",
		"scan.subHeader.info1": `The development process at Amperino is marked by a commitment to innovative technology and precision. 
								One of the key tools in this process is the use of scan technology. 
								This technology is used in multiple stages of the production process to ensure that the final product is of the highest quality. 
								First, the approved design from the clay model studies is transferred into a virtual environment using scan technology.`,
		"scan.subHeader.info2": `This allows for a detailed examination of the design and the identification of any areas that may need refinement. 
								Additionally, it is used in the approval of the molds for production. 
								This helps to ensure that the molds are precisely engineered and that they will produce parts that meet the highest standards of quality. 
								Finally, it is used in the quality control process, 
								providing a critical check on the manufactured parts to ensure they meet the high standards expected from Amperino's vehicles. 
								By using scan technology in every stage of the development process, Amperino can guarantee that their vehicles are designed, built, 
								and tested to the highest standards.`,
		
		//CAD Page
		"cad.header": "CAD",
		"cad.header.info": "Like all vehicle manufacturers, Amperino also makes engineering designs of its vehicles in CAD design programs.",

		"cad.subHeader": "Cad",
		"cad.subHeader.info1": `At Amperino, we take a comprehensive approach to our electric vehicle design and development. 
								Our team has worked tirelessly to ensure that our model is not only sleek and stylish, but also highly manufacturable. 
								The engineering work for smooth surfaces is created using advanced CAD technology, allowing us to precisely optimize the packaging of the vehicle.`,
		"cad.subHeader.info2": `Once the packaging is completed, we use the same CAD environment to prepare the production data, ensuring that every aspect of the manufacturing 
								process runs smoothly and efficiently. Our commitment to high-quality design and engineering has resulted in a vehicle that is both beautiful and functional, 
								and we are proud to bring it to the market.`,

		//VR Page
		"vr.header": "VR",
		"vr.header.info": "While validating our designs, we integrate the latest technologies into our process alongside traditional methods.",

		"vr.subHeader": "VR",
		"vr.subHeader.info1": `After the sketch work, the lines, dimensions and ergonomics of the design are checked by using Virtual Reality technology together with the clay model.
							Clay model work and Virtual Reality technology processes are carried out simultaneously.`,
		"vr.subHeader.info2": `Designs are maturing with Ergonomics data taken from virtual reality. After the designs are matured and completed, 
							the design approval process is again approved with clay model and virtual reality technology and engineering studies begin.`,

		//CAE Page
		"cae.header": "CAE",
		"cae.header.info": "We maximize vehicle performance and safety with our computer-aided engineering (CAE) technologies.",
		"cae.subHeader1": "Computer Aided Engineering",
		"cae.subHeader1.info": `We have set out to build a car which is superior in its class. As of 2023 we are not required by homologation to pass any passive safety standards. 
								However, all our vehicles are designed to meet real world standards and circumstances. We have also taken on the challenge of starting from a blank 
								sheet and building the vehicle platform according to our specification. Our 64 core, 128 GB Ram workstation works 24/7 on various simulations to improve 
								our vehicle comfort and safety features.`,
		
		"cae.subHeader2": "Front Crash Management System",
		"cae.subHeader2.info": `The process starts by benchmarking and researching academic papers, moves on to developing simple concepts, 
								virtual analysis simulation and finally after finding acceptable results, we move on to the refining and detail design stage.
								Our simulation engineers work closely with our materials and metallurgy engineers to find the most suitable alloy for the required task. 
								At Amperino, we design and develop our unique chassis profile with bespoke cross-sections that are specifically manufactured for us.`,

		"cae.subHeader3": "Structural Analyzes",
		"cae.subHeader3.info1": `One of the crucial parameters when designing an electric vehicle is weight. Building lighter vehicles without compromising on 
								structural safety is an enormous challenge. In order to meet this goal, Amperino engineers use ANSYS. This software provides the 
								closest correlation between real and virtual testing.`,
		"cae.subHeader3.info2": `The development of vehicle components starts with initial concept CAD Models, 
								and based on the simulation runs, the CAE team suggests some changes to the Chassis design team. 
								After the virtual analysis is complete, we will then begin component level testing and then move on to the system level, and finally vehicle level.`,
		
		"cae.subHeader4": "Aerodynamics",
		"cae.subHeader4.info": `Our most challenging target was to achieve the lowest possible drag coefficient in order to 
								improve the vehicle driving range and efficiency without sacrificing the aesthetics of the vehicle design. 
								Our Computational Fluid Dynamics Engineers have done many months of work to achieve the optimum vehicle aerodynamic characteristics and dynamics`,

		"cae.subHeader5": "Diffuser",
		"cae.subHeader5.info": `Amperino engineers developed a state of the art diffuser to improve downforce and reduce the vacuum effect at the rear of the vehicle.`,
		
		"cae.subHeader6": "HVAC",
		"cae.subHeader6.info": `Front windshield defroster and side mirror defroster analysis are performed in virtual simulations to find the most effective air grill exit.`,
		
		"cae.subHeader7": "Metrology",
		"cae.subHeader7.info": `The experimental prototype and validation prototype vehicles are checked by “3-Dimensional Scanning Technologies” 
								and Metrology software is used by our CAD engineers to find dimensional differences between CAD data and the real life product.`,

		//Prototype Page
		"prototype.header": "Prototype",
		"prototype.header.info": "Our prototype development process is a high-precision process where every detail is meticulously tested and optimized.",

		"prototype.subHeader": "Experimental Prototype",
		"prototype.subHeader.info": `At Amperino, our focus is on creating high-quality vehicles through a combination of cutting-edge technology and innovative design. 
									Our 3-stage experimental prototypes showcase this, each one more advanced in terms of system and technology than the last. 
									After the completion of these prototypes, we will build validation prototypes to ensure that our vehicles are fully homologated for the 
									European and Turkish markets.`,

		//Error Page
		"error.header": "PAGE NOT FOUND",
		"error.header.info": "It seems that you’re lost. That’s okay, we can take you to the right track.",
		"error.backButton": "Go To Amperino",
		
		////////////////////////////////////////////////////////// -------------- COMPONENTS ---------------- ////////////////////////////////////////////////////////
		
		//Footer
		"footer.follow": "Follow",
		"footer.center": "Headquarter", 

		"footer.header1": "HOME",
		"footer.header1.subHeader1": "Car",
		"footer.header1.subHeader2": "Technology",
		"footer.header1.subHeader3": "Development",
		"footer.header1.subHeader4": "Company",
		
		"footer.header2": "TECHNOLOGY",
		"footer.header2.subHeader1": "Battery Pack",
		"footer.header2.subHeader2": "Composite",
		"footer.header2.subHeader3": "Drive Technology",
		"footer.header2.subHeader4": "Digital Vehicle Platform",
		"footer.header2.subHeader5": "Skateboard Chassis",

		"footer.header3": "DEVELOPMENT",
		"footer.header3.subHeader1": "Sketch",
		"footer.header3.subHeader2": "Clay",
		"footer.header3.subHeader3": "Scan",
		"footer.header3.subHeader4": "CAD",
		"footer.header3.subHeader5": "VR",
		"footer.header3.subHeader6": "CAE",
		"footer.header3.subHeader7": "Prototype",
		
		"footer.header4": "COMPANY",
		"footer.header4.subHeader1": "About Us",
		"footer.header4.subHeader2": "Career",
		"footer.header4.subHeader3": "News",

		//MenuBar
		"menu.home": "Home",
		"menu.car": "Car",
		"menu.technology": "Technology",
		"menu.development": "Development",
		"menu.company": "Company",

		//BurgerMenu
		"burger.header1": "Home",

		"burger.header2": "Car",

		"burger.header3": "Technology",
		"burger.header3.subHeader1": "Battery Pack",
		"burger.header3.subHeader2": "Composite",
		"burger.header3.subHeader3": "Drive Technology",
		"burger.header3.subHeader4": "Digital Vehicle Platform",
		"burger.header3.subHeader5": "Skateboard Chassis",

		"burger.header4": "Development",
		"burger.header4.subHeader1": "Sketch",
		"burger.header4.subHeader2": "Clay",
		"burger.header4.subHeader3": "Scan",
		"burger.header4.subHeader4": "CAD",
		"burger.header4.subHeader5": "VR",
		"burger.header4.subHeader6": "CAE",
		"burger.header4.subHeader7": "Prototype",

		"burger.header5": "Company",
	}
};

export default messages;