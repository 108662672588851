import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import { getStyles } from '../GetStyles';
import DetailHeadCom from '../../components/DetailHeadCom';


function Scan() {

    const { height, width } = useWindowDimensions();
    const styles = getStyles({width});


    const imageInformationReverse = {
        width: '80%',
        marginLeft: '10%',
        textAlign: 'center',
        marginTop: 20,
        display: width > 1000 ? 'flex' : 'table',
        paddingBottom: 50,
        flexDirection: width > 1000 ? 'row' : null,
        justifyContent: 'space-around',
        alignItems: 'center'
    }
    const inlineDiv = {
        backgroundColor: 'white',
        padding: 50,
        textAlign: 'justify',
        marginTop: width < 1000 ? -5 : null
    }

    const imageInformationImg = {
        backgroundColor: 'white',
        textAlign: 'center',
        width: '80%',
        marginLeft: '10%',
        display: 'flex',
        position: 'relative',
        marginTop: width > 1000 ? -200 : -50
    }

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="scan.header" />}
                    headerInfo={<FormattedMessage id="scan.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/TaramaMain.webp'
                    subImg='https://amperino.com/imagesv2/Development/DevelopmentPageImages/TaramaMain2.webp'
                />
                <div style={{ width: width > 600 ? width - 17 : width, paddingTop: 10 }}>
                    <img src='https://amperino.com/imagesv2/Development/Scan/Scan2v2.webp' style={{ width: width }} />
                    <div style={imageInformationImg}>
                        <div style={{ minHeight: 200, width: width < 800 ? '98%' : '50%', marginLeft: width < 800 ? '1%' : '25%', marginTop: 50, paddingBottom: 50 }}>
                            <h2><FormattedMessage id="scan.subHeader" /></h2>
                            <p style={{ textAlign: 'justify' }}>
                                <FormattedMessage id="scan.subHeader.info1" />
                            </p>
                        </div>
                    </div>
                </div>
                <div style={imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Development/Scan/scangray.webp' style={{ backgroundColor: ' rgba(230,230,230,1)', width: width > 1000 ? width / 4 : 450 }} />
                    <div style={inlineDiv}>
                        <div style={width > 1000 ? null : { width: '130%', marginLeft: '-15%' }}>
                            <FormattedMessage id="scan.subHeader.info2" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Scan;

