import React from 'react'
import MenuBar from '../../components/MenuBar';
import useWindowDimensions from '../../components/window';
import Footer from '../../components/Footer';
import { FormattedMessage } from 'react-intl';
import DetailHeadCom from '../../components/DetailHeadCom';
import { getStyles } from '../GetStyles';


function Battery() {

    const { height, width } = useWindowDimensions();

    const styles = getStyles({width});

    return (
        <div>
            <div style={styles.menubarStyle}>
                <MenuBar />
            </div>
            <div style={styles.mainImg}>
                <DetailHeadCom
                    header={<FormattedMessage id="battery.header" />}
                    headerInfo={<FormattedMessage id="battery.header.info" />}
                    mainImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/BataryaMain.webp'
                    subImg='https://amperino.com/imagesv2/Technology/TechnologyPageImages/BataryaMain2.webp'
                />
                <div style={styles.explanationP}>
                    <p style={{ fontWeight: 'bold' }}><h3><FormattedMessage id="battery.subheader1" /></h3></p>
                    <FormattedMessage id="battery.subheader1.info1" /> <br /> <br />
                    <FormattedMessage id="battery.subheader1.info2" /><br />
                    <FormattedMessage id="battery.subheader1.info3" /><br />
                    <FormattedMessage id="battery.subheader1.info4" /><br />
                    <FormattedMessage id="battery.subheader1.info5" /><br />
                    <FormattedMessage id="battery.subheader1.info6" /><br />
                    <FormattedMessage id="battery.subheader1.info7" /><br />
                    <FormattedMessage id="battery.subheader1.info8" /><br />
                    <FormattedMessage id="battery.subheader1.info9" /><br />
                    <FormattedMessage id="battery.subheader1.info10" />
                </div>
                <div style={styles.imageInformation}>
                    <img src='https://amperino.com/imagesv2/Technology/battery/Module.webp' style={width > 1000 ? { width: width / 4 } : null} />
                    <div style={{ backgroundColor: 'rgba(121,121,121,1)', backgroundSize: 'cover', width: '100%', color: 'white' }}>
                        <div style={{ padding: 50 }}>
                            <h3><FormattedMessage id="battery.subheader2" /></h3>
                            <FormattedMessage id="battery.subheader2.info" />
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformationReverse}>
                    <img src='https://amperino.com/imagesv2/Technology/battery/pil.webp' style={width > 1000 ? { width: width / 4, background: "linear-gradient( rgba(244,244,244,1),#fff, #eee, #ddd)" } : null} />
                    <div style={{ backgroundColor: 'white', backgroundSize: 'cover', width: '100%', color: 'black' }}>
                        <div style={{ padding: 50 }}>
                            <h3><FormattedMessage id="battery.subheader3" /></h3>
                            <FormattedMessage id="battery.subheader3.info" />
                        </div>
                    </div>
                </div>
                <div style={styles.imageInformation}>
                    <img src='https://amperino.com/imagesv2/Technology/battery/Battery4.webp' style={width > 1200 ? { width: width / 4 } : null} />
                    <div style={{ backgroundColor: 'rgba(121,121,121,1)', backgroundSize: 'cover', width: '100%', color: 'white', paddingBottom: 50 }}>
                        <div style={{ padding: 50 }}>
                            <h3><FormattedMessage id="battery.subheader4" /></h3>
                            <FormattedMessage id="battery.subheader4.info" />
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default React.memo(Battery);

