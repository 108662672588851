import { useState } from "react";
import AccVideo from "./AccVideo";
import "./styles.css";
import useWindowDimensions from "../window";

export const Accordion = () => {
  const [active, setActive] = useState(0);
  const { height, width } = useWindowDimensions();

  const shorts = [
    {
      header: "Furkan",
      src: "https://amperino.com/videos/FurkanShorts.mp4",
    },
    {
      header: "Mucteba",
      src: "https://amperino.com/videos/MuctebaShorts.mp4",
    },
    {
      header: "Cuneyt",
      src: "https://amperino.com/videos/CuneytShorts.mp4",
    },
    {
      header: "Nick",
      src: "https://amperino.com/videos/NickShorts.mp4",
    },
  ];

  const determineClassName = (index) => {
    if (active === index && width < 800) {
      return "mobactive";
    } else if (active !== index && width < 800) {
      return "mob";
    } else if (active === index && width >= 800) {
      return "active";
    } else {
      return "";
    }
  };

  const handleToggle = (index) => {
    setActive(index);
  };

  return (
    <section className={width < 800 ? "mobile" : ""}>
      {shorts.map((shorts, index) => {
        const className = determineClassName(index);
        return (
          <article
            key={index}
            className={className}
            onClick={() => handleToggle(index)}
          >
            <AccVideo src={shorts.src} index={index} />
          </article>
        );
      })}
    </section>
  );
};
