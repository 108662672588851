import { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter as Router } from "react-router-dom";
import styled from 'styled-components';
import Header from './Header';
import messages from "../messages";
import AppRoutes from "../routes/AppRoutes";

const hasLocale = localStorage.getItem("lang");
const defaultLang = hasLocale ? hasLocale : navigator.language;

function Rooter() {

  const [lang, setLang] = useState(defaultLang);

	useEffect(() => {
    if (lang != "tr-TR" && lang != "en-US"){
      setLang("tr-TR")
      localStorage.setItem("lang", "tr-TR");
    }
    else{
      localStorage.setItem("lang", lang);
    }
	}, [lang]);

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <Router>
        <Header />
        <Main>
          <AppRoutes />
        </Main>
      </Router>
    </IntlProvider>
  );
}

export default Rooter

const Main = styled.main`
  padding: 1em;
  margin: auto;
  img {
    max-width: 100%;
  }
  h2 {
    margin: 0.5em 0;
  }
  p {
    line-height: 1.25em;
    margin-bottom: 0.75em;
  }
  figcaption {
    color: #828282;
    font-size: 0.8em;
    margin-bottom: 1em;
  }
`;
