import React from 'react'
import { FormattedMessage } from 'react-intl';
import Folder from '../images/NewsPage/folder.svg';
import Tilt from 'react-parallax-tilt';
import './css/NewsCard.css'
import useWindowDimensions from './window';

function NewsCard({ Img, Header, Information, Date, Link }) {
  const { height, width } = useWindowDimensions();

  return (
    <Tilt scale={width> 500 ? 1.2 : false} tiltEnable={width> 500 ? true : false} transitionSpeed={2500} style={{ width: 380, height: 520,  backgroundColor: 'white', boxShadow: '3px 2px 60px rgba(0, 0, 0, 0.102)'}} className='Tilt'>
    <a href={Link} style={{textDecoration: 'none',  color: 'black'}}  target='_blank' >
        <img src={Img} style={{width: '100%', height: 225}} />
        <div style={{width: '86%', marginLeft: '7%', height: 200}}>
            <h4 style={{fontSize: 21, fontFamily: 'Acumin Pro'}}>{Header}</h4>
            <p style={{ fontSize: 16, fontFamily: 'Acumin Pro', marginTop: -14}}>{Information}</p>
        </div>     
        <div style={{width: '86%', marginLeft: '7%', marginTop: 20, fontSize: 12, fontFamily: 'Proxima Nova', fontWeight: 'bold', color: 'rgba(114,119,123,1)', display: 'flex', justifyContent: 'space-between'}}>
                <p>{Date}</p>
                <div style={{display: 'flex'}}>
                    <img src={Folder} />
                    <p style={{marginLeft: 6}}><FormattedMessage id="company.subHeader3" /></p>
                </div>
            </div>               
    </a>  
    </Tilt>
  )
}

export default NewsCard