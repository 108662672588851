import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import useWindowDimensions from './window';

function CompositeCarousel() {
  const { height, width } = useWindowDimensions();
  
  return (
    <div style={{width: width-16.6, marginLeft: -16}}>
      <Fade autoplay={true} duration={2000} transitionDuration={500} >
        <div className="each-fade">
          <img src='https://amperino.com/imagesv2/Technology/composite/composite0.webp'  style={{width: width > 1200 ? '60%' : width, marginLeft: width > 1200 ? '20%' : null}}/>
        </div>           
        <div className="each-fade">
          <img src='https://amperino.com/imagesv2/Technology/composite/composite1.webp' style={width > 1200 ? {width: '60%', marginLeft:'20%'}: {width}} />
        </div>
        <div className="each-fade">
          <img src='https://amperino.com/imagesv2/Technology/composite/composite2.webp' style={width > 1200 ? {width: '60%', marginLeft:'20%'}: {width}} />
        </div>  
      </Fade>
    </div>
  );
}

export default CompositeCarousel;