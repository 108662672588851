import React from 'react'
import MenuBar from '../components/MenuBar';
import useWindowDimensions from '../components/window';
import styled from 'styled-components';
import Footer from '../components/Footer';
import LineLink from '../components/LineLink';
import { FormattedMessage } from 'react-intl';
import Video from '../components/Video';


function Technology() {
    
    const { height, width } = useWindowDimensions();
    
    const lineItem = [
        ['https://amperino.com/imagesv2/Technology/TechnologyPageImages/BataryaMain.webp', 'technology.subHeader1', '/technology/battery', 'https://amperino.com/imagesv2/Technology/TechnologyPageImages/BataryaMain2.webp'], 
        ['https://amperino.com/imagesv2/Technology/TechnologyPageImages/KompozitMain.webp', 'technology.subHeader2', '/technology/composite', 'https://amperino.com/imagesv2/Technology/TechnologyPageImages/KompozitMain2.webp'], 
        ['https://amperino.com/imagesv2/Technology/TechnologyPageImages/SurusTekMain.webp', 'technology.subHeader3', '/technology/drive-technology', 'https://amperino.com/imagesv2/Technology/TechnologyPageImages/SurusTekMain2.webp'], 
        ['https://amperino.com/imagesv2/Technology/TechnologyPageImages/DijitalAracMain.webp', 'technology.subHeader4', '/technology/digital-vehicle-platform', 'https://amperino.com/imagesv2/Technology/TechnologyPageImages/DijitalAracMain2.webp'], 
        ['https://amperino.com/imagesv2/Technology/TechnologyPageImages/KaykaysasiMain.webp', 'technology.subHeader5', '/technology/skateboard-chassis', 'https://amperino.com/imagesv2/Technology/TechnologyPageImages/KaykaysasiMain2.webp']
    ]

    const headHeight = width > 600 ? 550 : 400

    const HeadImage = styled.div`
    background-size: cover;
    background-image: url('https://amperino.com/GoGreen/render2.webp');
    background-position: 55% 55%;
    background-repeat: no-repeat;
    /* add tint */
    background-color: rgba(0,0,0,0.40);
    background-blend-mode: overlay;
    width: ${width}px;
    height: ${headHeight}px;
    overflow: hidden;
    @media (min-width: 1000px) {
        background-size: cover;
        background-image: url(https://amperino.com/GoGreen/render2.webp);
        background-position: 50% 55%;
        background-repeat: no-repeat;
        /* add tint */
        background-color: rgba(0,0,0,0.50);
        background-blend-mode: overlay;
        width: ${width}px;
        height: ${headHeight+200}px;
        overflow: hidden;
    }
    
    @media (min-width: 2000px) {
        background-size: cover;
        background-image: url(https://amperino.com/GoGreen/render1.webp);
        background-position: 50% 80%;
        background-repeat: no-repeat;
        /* add tint */
        background-color: rgba(0,0,0,0.50);
        background-blend-mode: overlay;
        width: ${width}px;
        height: ${width/2}px;
        overflow: hidden;
    }
    `;

    const MainContainer = styled.div`
    overflow: hidden;
    width: 100%;
    z-index: 20;
    display: flex;
    position: relative;
    margin-Top: 130px;
    `;    

    const technologyStyle={
        position: 'absolute',
        color: 'white',
        top: '50%',
        fontSize: 24,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textShadow: "-2px 1px 5px rgba(0, 0, 0, 1)",
        textAlign: 'center'
    }

    const explanationP = {
        padding:  width > 700 ? 150 : 50, 
        textAlign: 'justify',
        marginTop: width > 600 ? null : 100
    }

    const menubarStyle = {
        position: 'relative', 
        width: width > 499 ? width-18 : width, 
        marginLeft:  width > 499 ? -15 : -16
    }
    const mainImg = {
        position: 'relative', 
        width: width > 499 ? width-17 : width, 
        marginLeft: -16,
        paddingBottom: 50
    }
    const videosDiv = {
        display: width > 900 ? 'flex' : 'table', 
        width:  width > 900 ? '80%' : '99%', 
        marginLeft: width > 900 ? '10%' : null,
    }
    const videosDivReverse = {
        ...videosDiv,
        marginTop: width > 1750 ? -250  : null
    }
    
  return (
    <div style={{letterSpacing: 1}}>
        <div style={menubarStyle}>
            <MenuBar />
        </div>
        <div style={mainImg}>
            <MainContainer>
                <HeadImage style={{marginTop: 30}} />
                <p style={technologyStyle}><FormattedMessage id="technology.header" /></p>
            </MainContainer>

            <div style={explanationP}>
                <p style={{fontWeight: 'bold', fontSize: 18}}><FormattedMessage id="technology.exp.head" /></p>
                <FormattedMessage id="technology.exp.info" />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems:"center", width: '99%'}}>
                <div style={{marginBottom: 20}}>
                    <div style={videosDiv}>
                        <Video src={"https://amperino.com/videos/FurkanShorts.mp4"} />
                        <div style={{padding: 40, display: width < 900 ? 'none' : 'flex', marginRight: width > 1000 ? width/5 : null}}><FormattedMessage id="technology.FurkanShorts" /></div>
                    </div>
                    <div style={videosDivReverse}>
                        <div style={{padding: 40, display: width < 900 ? 'none' : 'flex', marginLeft: width > 1000 ? width/5 : null, textAlign: 'end'}}><FormattedMessage id="technology.MuctebaShorts" /></div>
                        <Video src={"https://amperino.com/videos/MuctebaShorts.mp4"} />
                    </div>
                </div>
            </div>
            {lineItem.map((index) => 
                <LineLink midImg={index[0]} title={<FormattedMessage id={index[1]} />} mainTitle={<FormattedMessage id="menu.technology" />} link={index[2]} endImg={index[3]} />
            )}
        </div>
        <Footer />
    </div>
  )
}

export default Technology;

